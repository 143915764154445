import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Pagination from "../../components/common/Pagination";
import { staffloginlist } from "../../feature/StaffLogin/StaffLoginListing-Slice";

let PageSize = 1;

const StaffLoginListing = () => {
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [staffLoginListData, setStaffLoginListData] = useState([])
    const [searchCount, setSearchCount] = useState(0)

    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            staffList();
        }
    }, [navigate, currentPage]);

    const staffList = () => {
        dispatch(staffloginlist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
        })).then((data: any) => {
            setStaffLoginListData(data?.payload?.data?.data?.staffLoginLogs); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count)
        }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.staffloginList)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Master Login List`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[165px] ">
                                                    Id
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[498px]">
                                                    email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[248px]">
                                                    Access date
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[298px]">
                                                    App version
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[177px]">
                                                    Device type
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[346px]">
                                                    Ip address
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {staffLoginListData?.length ? staffLoginListData.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.id ? data?.id : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.email ? data?.email : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.access_date ? data?.access_date : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.app_version ? data?.app_version : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.device_type ? data?.device_type : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.ip_address ? data?.ip_address : '-'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-12">
                        {
                            staffLoginListData && staffLoginListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffLoginListing;
