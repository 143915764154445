import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IDraftUserList {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userlist: [];
    page?: null;
    limit?: null;
    search_text?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    draftuserlist: []
};

export const draftuserlist: any = createAsyncThunk(
    "DraftUserListSlice",
    async ({ page, limit, search_text }: IDraftUserList) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.draftUser}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}`)
        return response
    }
);

export const DraftlistSlice = createSlice({
    name: "DraftUserListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(draftuserlist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(draftuserlist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(draftuserlist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const draftUserListSliceReducer = DraftlistSlice.reducer;
