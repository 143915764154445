import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IStaffLogin {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    estimate: [];
    page: null;
    limit: null;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    estimate: []
};

export const staffloginlist: any = createAsyncThunk(
    "StaffLoginListSlice",
    async ({ page, limit }: IStaffLogin) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.staffLoginList}${`?page=${page}`}&limit=${limit}`)
        return response
    }
);

export const StaffLoginListSlice = createSlice({
    name: "StaffLoginListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(staffloginlist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(staffloginlist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(staffloginlist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const staffloginListReducer = StaffLoginListSlice.reducer;
