import { useState, useEffect, useRef, useContext } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import { adminindustrylist } from "../../feature/Taxonomy/AdminIndustry-Slice";
import { sortTaxonomy } from "../../feature/Taxonomy/SortTaxonomy-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import Switch from "react-switch";
import { editTaxoStatus } from "../../feature/Taxonomy/EditTaxonomyStatus-Slice";
import { removeLocalStorage, showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";
import RegionContext from "../../utils/RegionContext";
import { exportTaxonomy } from "../../feature/Taxonomy/ExportTaxnomoy-Slice";
import { importTaxonomy } from "../../feature/Taxonomy/ImportTaxonomy-Slice";

let PageSize = 1;

const IndustryList = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState(1);
    const [count, setCount] = useState(0)
    const [adminIndustryListData, setAdminIndustryListData]: any = useState([])
    const [searchCount, setSearchCount] = useState(0)
    const [regionUtahId, setRegionUtahId] = useState()
    const { contextRegionId, setContextRegionId }: any = useContext(RegionContext)
    const [filterStatus, setFilterStatus]: any = useState(contextRegionId ? contextRegionId : regionUtahId)
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [isLoad, setIsLoad] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showImportPopup, setShowImportPopup] = useState(false)
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isFile, setIsFile] = useState(false)
    const [taxonomyCountPopup, setTaxonomyCountPopup] = useState(false)
    const [taxonomyCountData, setTaxonomyCountData] = useState({
        categoryUpdatedCount: '',
        incorrectTaxonomyCount: '',
        incorrectTaxonomyFile: '',
        industryUpdatedCount: '',
        subCategoryUpdatedCount: ''
    })
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const exportTaxoLoading = useSelector((state: any) => state?.exportTaxonomySlice)
    const importTaxoLoading = useSelector((state: any) => state?.importTaxonomySlice)
    console.log('importTaxoLoading', importTaxoLoading);


    const tdRefs: any = useRef([]);
    const { isSuccess } = useSelector((state: any) => state?.sortTaxonomy);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);


    useEffect(() => {
        if (filterStatus) {
            adminIndustry();
            setIsLoad(true)
        }

    }, [currentPage, searchResult, filterStatus]);

    useEffect(() => {
        if (isSuccess) {
            adminIndustry();
        }
    }, [isSuccess])

    useEffect(() => {
        if (location.pathname !== '/industries?page=1') {
            removeLocalStorage('region');
        }
    }, [location.pathname]);

    useEffect(() => {
        if (importTaxoLoading?.isSuccess) {
            adminIndustry()
        }
    }, [importTaxoLoading?.isSuccess])

    const adminIndustry = () => {
        dispatch(adminindustrylist({
            page: currentPage,
            limit: 100,
            search_text: searchResult,
            filterStatus: filterStatus,
        })).then((data: any) => { setAdminIndustryListData(data?.payload?.data?.data); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.adminindustryList)

    const handleDrop = async (event: any, newIndex: any) => {
        event.preventDefault();
        const sourceIndex = parseInt(event.dataTransfer.getData('text/plain'));
        const draggedItem = adminIndustryListData[sourceIndex];
        const newIndustryListData: any = adminIndustryListData.filter((_: any, index: any) => index !== sourceIndex);
        newIndustryListData.splice(newIndex, 0, draggedItem);
        setAdminIndustryListData(newIndustryListData);
        tdRefs.current = tdRefs.current.slice();

        const updatedSortOrder = newIndustryListData?.map((item: any, index: any) => ({
            id: item?.id,
            display_order: index + 1,
            region_id: parseInt(filterStatus)
        }));
        const sort_type = 'industry'
        dispatch(sortTaxonomy({ updatedSortOrder, sort_type }))

    };

    const handleDragStart = (event: any, index: any) => {
        event.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };


    const handleStateChange = (e: any) => {
        setFilterStatus(e.target.value)
        // setCurrentPage(1)
        setContextRegionId(e.target.value)
    };

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => {
            setHCPRegionList(val?.payload?.data?.data);
            if (!contextRegionId) {
                const temp = val?.payload?.data?.data.find((val: any) => val?.region == 'UT').id
                setRegionUtahId(temp)
                setFilterStatus(temp)
            }
        })
    }, [])

    const handleToggle = async (id: string, e: any) => {
        let taxnomy: any = adminIndustryListData?.findIndex((i: any) => i.id === id);
        if (taxnomy > -1) {
            adminIndustryListData[taxnomy].is_active = !adminIndustryListData[taxnomy].is_active
            setAdminIndustryListData([...adminIndustryListData]);
            dispatch(editTaxoStatus({ industryId: id, regionId: filterStatus, taxonomyType: 'Industry', status: e }))
                .then((val: any) => {
                    if (val?.meta?.requestStatus == 'rejected') {
                        adminIndustryListData[taxnomy].is_active = !adminIndustryListData[taxnomy].is_active
                        setAdminIndustryListData([...adminIndustryListData]);
                    }
                })
        }
    };

    const handleExportTaxonomy = (value: any) => {
        setLoading(value && true);
        dispatch(exportTaxonomy({ id: filterStatus, value })).then((val: any) => {
            if (val?.meta?.requestStatus === "fulfilled" && val?.payload?.data?.data) {
                if (value) {
                    showToastSuccessMessage('Sample file has been downloaded successfully.');
                } else {
                    showToastSuccessMessage('Taxonomy exported successfully.');
                }
                const downloadLink = document.createElement("a");
                downloadLink.href = val.payload.data.data;
                downloadLink.setAttribute("download", "filename.extension");
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            } else {
                showToastErrorMessage('Export link is blank.');
            }
        });
    };


    const handleImportTaxonomy = () => {
        setShowImportPopup(true)
        setTaxonomyCountPopup(false)

    }

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsFile(false)
    };

    const confirmImportTaxonomy = (e: any) => {
        if (!selectedFile) {
            setIsFile(true)
            return;
        }
        if (selectedFile) {
            if (selectedFile?.name?.endsWith(".xlsx")) {
                const formData = new FormData();
                formData.append("file", selectedFile);

                dispatch(importTaxonomy({ formData: formData, regionId: filterStatus })).then((val: any) => {
                    if (val?.meta?.requestStatus == "fulfilled") {
                        showToastSuccessMessage('Taxonomy imported successfully.')
                        setShowImportPopup(false)
                        setSelectedFile('')
                        setTaxonomyCountPopup(true)
                        setTaxonomyCountData(val?.payload.data.data)
                    }
                }
                )
            } else {
                showToastErrorMessage("Please select a valid file format to upload.");
            }
        }

    }

    useEffect(() => {
        if (showImportPopup || taxonomyCountPopup) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [showImportPopup, taxonomyCountPopup])

    return (
        <>
            <div className="flex">
                <Sidebar />
                <div className="content w-full">
                    <Header />
                    <div className="fluid p-4 custom-height-wrapper">
                        <div className="pb-5">
                            <Title title={`Industry Lists`} />
                        </div>
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-row items-center justify-between  mb-6 sm:flex-wrap sm:justify-center">
                                    <div className="flex items-center justify-start mr-auto w-full" >
                                        <Search
                                            setSearchResult={setSearchResult}
                                            searchResult={searchResult}
                                            currentPage={setCurrentPage}
                                        />
                                    </div>
                                    <div className="flex w-full justify-end  gap-[8px] items-center relative">
                                        <button className="px-2 hover:bg-logo border-[1px] transition-all duration-500 ease-in-out hover:text-white  text-logo border-logo py-2  items-center text-xs font-semibold max-w-[100px] w-full h-[32px] inline-block" onClick={handleImportTaxonomy} disabled={importTaxoLoading?.isLoading}>
                                            Import
                                        </button>
                                        <button className={`px-2 ${exportTaxoLoading?.isLoading ? '' : 'hover:bg-logo'}  border-[1px] transition-all duration-500 ease-in-out hover:text-white  text-logo border-logo py-2 items-center text-xs font-semibold max-w-[100px] w-full h-[32px] inline-block`} onClick={() => handleExportTaxonomy(false)} disabled={exportTaxoLoading?.isLoading}>
                                            {exportTaxoLoading?.isLoading && !loading ? (

                                                <ClipLoader color="#f27366" size={20} className='relative bottom-0.5' />
                                            ) :
                                                <>
                                                    Export
                                                </>
                                            }
                                        </button>
                                        <div className="relative w-full max-w-[180px] items-center ">
                                            <select
                                                className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                                id="grid-state"
                                                value={filterStatus}
                                                onChange={handleStateChange}
                                            >
                                                {hcpRegionList && hcpRegionList.length > 0 && (
                                                    hcpRegionList.map((state: any, index) => (
                                                        <option key={index} value={state.id} >
                                                            {state.name}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                        <table className="w-full text-left table-auto">
                                            <thead className="bg-logo text-white">
                                                <tr>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[225px]">
                                                        Display Order
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[319px]">
                                                        Industry Name
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[212px]">
                                                        Status
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[125px]">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(isLoading) || (!contextRegionId && !regionUtahId) ? (
                                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                        <ClipLoader color="#f27366" size={50} />
                                                    </div>
                                                ) : <>
                                                    {adminIndustryListData && adminIndustryListData?.length ? adminIndustryListData.map((data: any, index: any) => {
                                                        const isGrayRow = index % 2 === 0;
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className={isGrayRow ? 'bg-gray-table cursor-grab' : 'cursor-grab'}
                                                                draggable
                                                                onDragStart={(event) => handleDragStart(event, index)}
                                                                onDragOver={handleDragOver}
                                                                onDrop={(event) => handleDrop(event, index)}
                                                            >
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.name ? data?.name : '-'} ({data?.category_count ? data?.category_count : '-'})
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                    <Switch
                                                                        checked={data.is_active}
                                                                        onChange={(e: any) => handleToggle(data?.id, e)}
                                                                        onColor="#f27367"
                                                                        onHandleColor="#fff"
                                                                        handleDiameter={22}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={15}
                                                                        width={48}
                                                                        className="react-switch"
                                                                        id="material-switch"
                                                                    />
                                                                </td>
                                                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                    <span className="flex items-center">
                                                                        <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                            onClick={() => { navigate(`/industry-list/category/${data.id}`, { state: { data, filterStatus: filterStatus } }) }}
                                                                        >
                                                                            <button className="text-xs font-bold text-black uppercase" >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                    <title>View</title>
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                <div>{isLoad ? 'No Data Found' : ''}</div>
                                                            </td>
                                                        </tr>}
                                                </>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center my-12">
                            {
                                adminIndustryListData && adminIndustryListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={count}
                                    pageSize={PageSize}
                                    onPageChange={(page: React.SetStateAction<number>) =>
                                        setCurrentPage(page)
                                    }
                                />
                            }
                        </div>
                    </div>
                </div >
            </div >
            {
                showImportPopup &&
                <>
                    <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex'   >
                        <div className="h-[400px] max-w-[500px] w-full bg-white  p-2 relative flex justify-center items-center flex-col gap-[5px]">
                            <p className="text-center font-[600] ">Import Taxonomy</p>
                            <div className="border-2 border-dashed  border-logo  h-[50%] w-[80%] flex justify-center items-center relative cursor-pointer">
                                <input type="file" name="taxonomy-import" id="taxonomy-import" accept=".xlsx" className="opacity-0" onChange={(e) => handleFileUpload(e)} />
                                <p className=" cursor-pointer max-w-[300px] w-full text-center">
                                    {selectedFile
                                        ? selectedFile?.name
                                        : "Choose a file or drag it here."}
                                </p>
                            </div>
                            <div className=" flex flex-col gap-[5px] items-center">
                                {
                                    isFile &&
                                    <p className="text-red text-sm">
                                        File is required.
                                    </p>
                                }
                                <p className="text-[#919193] text-sm">Note: Uploaded file must be xlsx format.</p>
                                <p className=" text-left text-gray-500"><button disabled={exportTaxoLoading?.isLoading} onClick={() => handleExportTaxonomy(true)} className="text-red">{`${exportTaxoLoading?.isLoading ? 'Downloading...' : 'Click here'}`}</button>{`${exportTaxoLoading?.isLoading ? '' : ' to download a sample file.'}`}</p>
                                <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => { setShowImportPopup(false); setSelectedFile(''); setIsFile(false) }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                                <div className=" flex mt-[10px] gap-[10px]">
                                    <button onClick={() => { setShowImportPopup(false); setSelectedFile(''); setIsFile(false) }} className="px-4 py-2 bg-red text-white rounded-md text-sm ">Cancel</button>
                                    <button onClick={(e: any) => confirmImportTaxonomy(e?.target?.value)} className="px-4 py-2 bg-green text-white rounded-md text-sm w-[81px] h-[36px] inline-block">
                                        {importTaxoLoading?.isLoading &&
                                            <ClipLoader color="#FFFFFF" size={20} />
                                        }
                                        {
                                            !importTaxoLoading?.isLoading &&
                                            (
                                                <>
                                                    Submit
                                                </>
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                taxonomyCountPopup &&
                <>
                    <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex'>
                        <div className={`${taxonomyCountData?.incorrectTaxonomyFile ? 'h-[300px]' : 'h-[250px]'} max-w-[500px] w-full bg-white  p-2 relative flex items-center flex-col  gap-[20px]`}>
                            <p className="text-center font-[600] text-[22px] mt-[15px]">Taxonomy Imported Successfully</p>
                            <div className="flex flex-col gap-[20px] items-center  w-full">
                                <p className="text-left font-[400] text-[16px]">The imported taxonomy counts are shown here.</p>
                                <div className="flex justify-evenly  w-full" >
                                    <p className="font-[500] text-[18px]">Industry : <span className="font-[400] text-logo">{taxonomyCountData?.industryUpdatedCount}</span></p>
                                    <p className="font-[500] text-[18px]">Category : <span className="font-[400] text-logo">{taxonomyCountData?.categoryUpdatedCount}</span></p>
                                    <p className="font-[500] text-[18px]">Sub-Category : <span className="font-[400] text-logo">{taxonomyCountData?.subCategoryUpdatedCount}</span></p>
                                </div>
                                {taxonomyCountData?.incorrectTaxonomyFile && (
                                    <div className="text-center">
                                        <p>The imported file contains <span className="font-[400] text-logo">{taxonomyCountData?.incorrectTaxonomyCount}</span> invalid records. To download those records, kindly click this  <span className="font-[600] text-logo underline"><Link to={`${taxonomyCountData?.incorrectTaxonomyFile}`} download >Link.</Link></span></p>
                                    </div>
                                )}
                                <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => { setTaxonomyCountPopup(false); setShowImportPopup(false); setSelectedFile(''); setIsFile(false) }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                            <div className={`flex  gap-[10px] ${!taxonomyCountData?.incorrectTaxonomyFile ? "absolute bottom-9" : ""}`}>
                                <button onClick={() => { setTaxonomyCountPopup(false); setSelectedFile(''); setIsFile(false) }} className="px-4 py-2 bg-red text-white rounded-md text-sm ">Close</button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default IndustryList;
