import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import {
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";

interface IForgotPassword {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  data: {
    email: string;
  };
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  email: "",
};

export const forgotpassword: any = createAsyncThunk(
  "forgotpasswordSlice",
  async ({ data }: IForgotPassword) => {
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiconfig.authentication.forgotPassword}`,
        {
          email: data?.email,
        }
      );
      if (response.status === 201) {
        showToastSuccessMessage(response.data.message);
        return response.data;
      } else {
        throw new Error("Invalid response status");
      }
    } catch (error: any) {
      showToastErrorMessage(error?.response?.data?.message);
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const ForgotpasswordSlice = createSlice({
  name: "forgotpasswordSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(forgotpassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(forgotpassword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.email = payload.email;
    });
    builder.addCase(forgotpassword.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const forgotpasswordSliceReducer = ForgotpasswordSlice.reducer;
