
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const syncCustomerAddress: any = createAsyncThunk(
    "SyncCutomerAddressSlice",
    async () => {
        try {
            const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.addressRequest.syncAddress}`).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response

        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const SyncCustomerAddressSlice = createSlice({
    name: "SyncCutomerAddressSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(syncCustomerAddress.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(syncCustomerAddress.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(syncCustomerAddress.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const syncCustomerAddressSliceReducer = SyncCustomerAddressSlice.reducer;
