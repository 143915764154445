
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
  banner_title?: string;
  request_title?: string
  membership_plan?: string;
  banner_link?: string;
  advertising_type?: string;
  is_active?: boolean;
}

interface INewAdvertisement {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  advertisementId: number;
  advertiseDetail: IData;
  image?: any;
  formData?: any
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  advertiseDetail: []
};

export const newAdvertisement: any = createAsyncThunk(
  "NewAdvertiseSlice",
  async ({ formData }: INewAdvertisement) => {
    try {
      const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.advertisement.newAdvertisement}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((data: any) => {
        showToastSuccessMessage(data?.data?.message)
      })
      return response
    } catch (error: any) {
      showToastErrorMessage(error?.response?.data?.message)
      throw error
    }
  }
);

export const NewAdvertiseSlice = createSlice({
  name: "NewAdvertiseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newAdvertisement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(newAdvertisement.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(newAdvertisement.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const newAdvertiseSliceReducer = NewAdvertiseSlice.reducer;
