import { Routes, Route } from "react-router-dom";

// Import Routes
import Layout from "./layout/Layout";
import Outer from "./outer/Outer";
import ProtectedRoutes from "./ProtectedRoutes";

// Import Auth Pages
import LoginPage from "../pages/authpages/LoginPage";
import ForgotPasswordPage from "../pages/authpages/ForgotPasswordPage";

// Import Inner Pages
import DashboardPage from "../pages/DashboardPage";
import ResetPasswordPage from "../pages/authpages/ResetPasswordPage";
import UserListingPage from "../pages/Users/UserListingPage";
import EmployeeListingPage from "../pages/Employees/EmployeeListingPage";
import EstimateRequestList from "../pages/EstimationRequest/EstimationListingPage";
import EditUserPage from "../pages/Users/EditUserPage";
import JobListingPage from "../pages/Jobs/JobListingPage";
import NotFoundPage from "../pages/NotFoundPage";
import ViewJob from "../pages/Jobs/ViewJobPage";
import ViewEstimateRequest from "../pages/EstimationRequest/ViewEstimateRequestPage";
import AdvertiseListing from "../pages/Advertisement/AdvertisementListingPage";
import EditAdvertisement from "../pages/Advertisement/EditAdvertisementPage";
import NewAdvertisement from "../pages/Advertisement/NewAdvertisementPage";
import ServiceListing from "../pages/Services/ServiceListingPage";
import EditService from "../pages/Services/EditServicePage";
import NewService from "../pages/Services/AddServicePage";
import HCPRegionListing from "../pages/HCP Region/HCPRegionListingPage";
import EditHCPRegion from "../pages/HCP Region/EditHCPRegion";
import UserJobsListing from "../pages/Users/UsersJobsListing";
import ZipcodeListing from "../pages/Zipcode/ZipcodeListingPage";
import EditZipCode from "../pages/Zipcode/EditZipcodePage";
import AddZipcode from "../pages/Zipcode/AddZipcodePage";
import EstimateList from "../pages/Estimate/EstimateListingPage";
import AppConfigListing from "../pages/AppConfig/AppConfigListing";
import AppConfigInfoListing from "../pages/AppConfig/ContactOneHomePage";
import DraftUserListing from "../pages/DraftUser/DraftUserListingPage";
import MembershipInfoListing from "../pages/AppConfig/MembershipInfoPage";
import UserLogsPage from "../pages/Users/UsersLogsPage";
import AddUser from "../pages/Users/AddUsersPage";
import AddressRequestListing from "../pages/Address Requests/AddressRequestListing";
import UserAddressListing from "../pages/Users/UserAddressList";
import UserPropertiesList from "../pages/Properties/UserPropertiesPage";
import EstimateAddress from "../pages/Estimate/EstimateByAddressPage";
import ViewEstimate from "../pages/Estimate/EstimateAddressView";
import IndustryList from "../pages/Industry/IndustryListingPage";
import IndustryCategoryList from "../pages/Industry/IndustryCategoryPage";
import IndustrySubCategoryList from "../pages/Industry/IndustrySubCategoryPage";
import HolidayList from "../pages/HolidayList/HolidayList"
import BillingAddressRequestListing from "../pages/Billing Requests/BillingAddressRequest";
import WebAnalytics from "../pages/WebAnalytics/WebAnalytics";
import StaffLoginListing from "../pages/StaffLogin/StaffLoginListing";
import MasterPassword from '../pages/MasterPassword/masterPaassword';
import EditEmployee from "../pages/Employees/EditEmployee";

const Templete = () => {

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<Layout />}>
          <Route path="/users" index element={<UserListingPage />} />
          <Route path="/draft-users" index element={<DraftUserListing />} />
          <Route path="/dashboard" index element={<DashboardPage />} />
          <Route path="/hcp-employee" element={<EmployeeListingPage />} />
          <Route path="/hcp-employee/:id" element={<EditEmployee />} />
          <Route path="/users/:id" element={<EditUserPage />} />
          <Route path="/users/add-user" element={<AddUser />} />
          <Route path="/users/address-list/:id" element={<UserAddressListing />} />
          <Route path="/users/jobs/:id" element={<UserJobsListing />} />
          <Route path="/users/logs/:id" element={<UserLogsPage />} />
          <Route path="/jobs/:id" element={<ViewJob />} />
          <Route path="/users/job_details/:id" element={<ViewJob />} />
          <Route path="/jobs" element={<JobListingPage />} />
          <Route path="/services" element={<ServiceListing />} />
          <Route path="/services/:id" element={<EditService />} />
          <Route path="/services/new" element={<NewService />} />
          <Route path="/hcp-region" element={<HCPRegionListing />} />
          <Route path="/hcp-region/:id" element={<EditHCPRegion />} />
          <Route path="/estimate-requests" element={<EstimateRequestList />} />
          <Route path="/estimate-requests/:id" element={<ViewEstimateRequest />} />
          <Route path="/estimate" element={<EstimateList />} />
          <Route path="/advertisement" element={<AdvertiseListing />} />
          <Route path="/advertisement/:id" element={<EditAdvertisement />} />
          <Route path="/advertisement/new" element={<NewAdvertisement />} />
          <Route path="/zipcode" element={<ZipcodeListing />} />
          <Route path="/zipcode/:id" element={<EditZipCode />} />
          <Route path="/zipcode/new" element={<AddZipcode />} />
          <Route path="/app-config" index element={<AppConfigListing />} />
          <Route path="/app-info/ContactOneHome/:id" index element={<AppConfigInfoListing />} />
          <Route path="/app-info/MembershipInfo/:id" index element={<MembershipInfoListing />} />
          <Route path="/address-requests" index element={<AddressRequestListing />} />
          <Route path="/billing-address-requests" index element={<BillingAddressRequestListing />} />
          <Route path="/users-properties" index element={<UserPropertiesList />} />
          <Route path="/estimate-address/:id" index element={<EstimateAddress />} />
          <Route path="/estimate-address/view/:id" index element={<ViewEstimate />} />
          <Route path="/industries" index element={<IndustryList />} />
          <Route path="/holiday-list" index element={<HolidayList />} />
          <Route path="/industry-list/category/:id" index element={<IndustryCategoryList />} />
          <Route path="/industry-list/sub-category/:id" index element={<IndustrySubCategoryList />} />
          <Route path="/web-analytics" index element={<WebAnalytics />} />
          <Route path="/staff-login-logs" index element={<StaffLoginListing />} />
          <Route path="/master-password" index element={<MasterPassword />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
      <Route path="/" element={<Outer />}>
        <Route path="/" index element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/reset-password-app/:token" element={<ResetPasswordPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Templete;
