import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const dashboardCount: any = createAsyncThunk(
    "DashboardCountSlice",
    async () => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.dashboard?.dashboardCount}`)
        return response
    }
);

export const DashboardCountSlice = createSlice({
    name: "DashboardCountSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(dashboardCount.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(dashboardCount.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(dashboardCount.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const dashboardCountSliceReducer = DashboardCountSlice.reducer;
