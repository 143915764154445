import logo from "../../assets/images/core/OH-Logo.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetpassword } from "../../feature/Authentications/ResetPassword-Slice";
import { ClipLoader } from "react-spinners";
import { showToastErrorMessage } from "../../utils/utils";

interface IFormInputs {
  password: string;
  confirmPassword: string;
}

const Reset = () => {
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useParams()
  const location = window.location.pathname
  const app_user_path = location?.split('/')[1]

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm<IFormInputs>();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    dispatch(resetpassword({ data, token })).then((data: any) => {
      if (data?.payload?.statusCode == 200 && data?.payload?.role == 'PROPERTY_OWNERS') {
        navigate(`/reset-password-app/${token}`)
      } else if (data?.payload?.statusCode == 200 && data?.payload?.role == 'SUPER_ADMIN') {
        navigate('/')
      }
      if (data?.meta?.requestStatus == "rejected") {
        showToastErrorMessage('Link has expired.')
        navigate(`/reset-password-app/${token}`)
      }
      reset();
    }
    )
  };

  const cancelHandler = () => {
    navigate('/forgot-password')
  }

  const { isLoading } = useSelector((state: any) => state.resetpassword)


  return (
    <div className="flex flex-col bg-white border-darkGray border-solid border p-8 w-full max-w-lg">
      <div className="logo flex items-center justify-center pb-5">
        <img src={logo} alt="Logo" width={125} height={40} />
      </div>
      <div className="flex flex-col w-full mb-4">
        <div className="text-lg leading-6 pt-2 font-bold">
          Please enter your new password to reset your account
        </div>
      </div>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-y-1 gap-x-2 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <div className="space-y-1 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium whitespace-nowrap"
                >
                  Password
                </label>
                <input
                  {...register("password", {
                    required: "Password is required.",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
                      message:
                        "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                    },
                  })}
                  name="password"
                  placeholder=""
                  type={showPassword}
                  id="password"
                  className="form-input block w-full border-darkGray border border-solid bg-white outline-0 flex-grow-1 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                />
                <div className="absolute right-[11px] top-[25px] cursor-pointer">
                  {showPassword === "password" && (
                    <button
                      onClick={() => {
                        setShowPassword("text");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    </button>
                  )}

                  {showPassword === "text" && (
                    <button
                      onClick={() => {
                        setShowPassword("password");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>

                    </button>
                  )}
                </div>
                <p className="text-red text-sm">
                  {errors.password && `${errors.password?.message}`}
                </p>
              </div>
            </div>
            <div className="sm:col-span-12 relative">
              <div className="space-y-1">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium whitespace-nowrap"
                >
                  Confirm Password
                </label>
                <input
                  {...register("confirmPassword", {
                    required: 'Confirm password is required.',
                    validate: (val: string) => {
                      if (watch("password") !== val) {
                        return "Password does not match.";
                      }
                    },
                  })}
                  name="confirmPassword"
                  placeholder=""
                  type={showConfirmPassword}
                  id="confirmPassword"
                  className="form-input block w-full border-darkGray border border-solid bg-white outline-0 flex-grow-1 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                />

                <div className="absolute right-[11px] top-[25px] cursor-pointer">
                  {showConfirmPassword === "password" && (
                    <button
                      onClick={() => {
                        setShowConfirmPassword("text");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    </button>
                  )}

                  {showConfirmPassword === "text" && (
                    <button
                      onClick={() => {
                        setShowConfirmPassword("password");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>

                    </button>
                  )}
                </div>

                <p className="text-red text-sm">
                  {errors.confirmPassword?.message &&
                    `${errors.confirmPassword?.message}`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-start space-x-2">
          {app_user_path == 'reset-password' ?
            <button
              onClick={() => cancelHandler()}
              type="button"
              className="px-3 py-2 text-sm font-medium bg-white border border-darkGray rounded-md shadow-sm focus:outline-none"
            >
              Cancel
            </button>
            :
            ''
          }
          <button
            disabled={isLoading}
            type="submit"
            className="inline-flex justify-center px-3 py-2 ml-0 text-sm font-medium text-white bg-logo border border-transparent shadow-sm rounded-md focus:outline-none w-[77px]"
          >
            {isLoading &&
              <ClipLoader color="#FFFFFF" size={20} />
            }
            {
              !isLoading &&
              (
                <>
                  Submit
                </>
              )
            }
          </button>
        </div>
      </form>
      {app_user_path == 'reset-password' ?
        <div className="w-full mt-4 text-sm">
          <span>
            Already have an account? &nbsp;
            <Link className="text-blue" to="/">
              Login here
            </Link>
          </span>
        </div>
        : ''
      }
    </div>
  );
};

export default Reset;
