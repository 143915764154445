
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const syncJob: any = createAsyncThunk(
    "SyncJobSlice",
    async () => {
        try {
            const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.jobs.syncJob}`).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response

        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const SyncjobSlice = createSlice({
    name: "SyncJobSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(syncJob.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(syncJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(syncJob.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const syncJobSliceReducer = SyncjobSlice.reducer;
