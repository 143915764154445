import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { draftuserlist } from "../../feature/DraftUser/DraftUser-Slice";
import Sidebar from '../../components/sidebar/Sidebar';
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import moment from "moment";

let PageSize = 1;

const DraftUserListing = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [searchCount, setSearchCount] = useState(0)
    const [draftUserListData, setDraftUserListData] = useState([])

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            DraftUserList();
        }

    }, [searchResult, navigate, currentPage]);


    const DraftUserList = () => {
        dispatch(draftuserlist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult
        })).then((data: any) => {
            setDraftUserListData(data?.payload?.data?.data?.users); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count);
        }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.draftUserList)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Draft Users List`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex items-center justify-start mr-auto w-full" >
                                    <Search
                                        setSearchResult={setSearchResult}
                                        searchResult={searchResult}
                                        currentPage={setCurrentPage}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[66px]">
                                                    Id
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[155px]">
                                                    First Name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[149px]">
                                                    Last Name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[491px]">
                                                    Email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[170px]">
                                                    Phone
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[166px]">
                                                    City
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[99px]">
                                                    State
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[124px]">
                                                    Zipcode
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[124px]">
                                                    Created at
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {draftUserListData?.length ? draftUserListData.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.id}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.first_name?.charAt(0)?.toUpperCase() + data?.first_name?.slice(1)}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.last_name?.charAt(0)?.toUpperCase() + data?.last_name?.slice(1)}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.email}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.mobile}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.city ? data?.city?.charAt(0,).toUpperCase() + data?.city?.slice(1) : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.state}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.zipcode}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-12">
                        {
                            draftUserListData && draftUserListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DraftUserListing;
