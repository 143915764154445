import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import { newAdvertisement } from "../../feature/Advertisement/NewAdvertisement-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { industrylist } from "../../feature/Taxonomy/IndustryList-Slice";
import { adminindustryCategorylist } from "../../feature/Taxonomy/AdminIndustryCategory-Slice";
import { adminindustrySubCategorylist } from "../../feature/Taxonomy/AdminIndustrySub-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

interface IFormInputs {
    banner_title?: string;
    request_title?: string;
    banner_image?: any;
    banner_link?: string;
    advertising_type?: string;
    service_id?: any
    categoryData?: any;
    subCategoryData?: any
}

const NewAdvertisement = () => {
    const [image, setImage]: any = useState();
    const [advertiseDetail, setAdvertiseDetail] = useState<IFormInputs>({
        banner_title: '',
        request_title: '',
        banner_image: '',
        banner_link: '',
        advertising_type: 'InApp',
        service_id: ''
    })
    const [errorMessage, setErrorMessage] = useState(false)
    const [serviceData, setServiceData] = useState([])
    const [categoryNameList, setCategoryNameList] = useState([])
    const [subCategoryNameList, setSubCategoryNameList] = useState<any>([])
    const [categoryData, setCategoryData]: any = useState()
    const [subCategoryData, setSubCategoryData]: any = useState()
    const [displayName, setDisplayName] = useState()
    const [regionUtahId, setRegionUtahId] = useState()

    const dispatch = useDispatch()
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const navigate = useNavigate()
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<IFormInputs>();

    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        if (errorMessage) {
            return false;
        }
        if (advertiseDetail?.banner_image && !['image/jpg', 'image/png', 'image/jpeg'].includes(advertiseDetail?.banner_image?.type)) {
            return false;
        }
        const formData = new FormData();
        formData.append("banner_title", advertiseDetail?.banner_title as string);
        formData.append("request_title", advertiseDetail?.request_title as string);
        formData.append("banner_link", advertiseDetail?.banner_link as string);
        formData.append("advertising_type", advertiseDetail?.advertising_type as string)
        formData.append("service_industry_id", advertiseDetail?.service_id as string)
        formData.append("service_category_id", categoryData as string)
        formData.append("service_sub_category_id", subCategoryData as string)

        // if (advertiseDetail?.service_id != '') {
        //     formData.append("service_id", advertiseDetail?.service_id)
        // }

        formData.append("file", advertiseDetail?.banner_image);

        dispatch(newAdvertisement({ formData })).then(
            (data: any) => {
                if (data?.meta?.requestStatus === "fulfilled") {
                    setTimeout(() => {
                        navigate("/advertisement");
                    }, 1000);
                }
            }
        );
    };

    const { isLoading } = useSelector((state: any) => state?.advertiseDetail)
    const isAdvertiseAdded = useSelector((state: any) => state?.newAdvertise)

    const handleImageChange = (e: any) => {
        debugger
        const file = e.target.files[0];
        const fileSizeKb = file.size / 1024
        if (file && fileSizeKb > 1024) {
            setErrorMessage(true)
            return;
        } else {
            setAdvertiseDetail({ ...advertiseDetail, banner_image: file })
            // setImage(file);
            setValue("banner_image", e.target.value, { shouldValidate: true })
            setErrorMessage(false)
        }
    };

    // To list down all the service drop-down
    useEffect(() => {
        dispatch(industrylist()).then((data: any) => setServiceData(data?.payload?.data?.data)
        )

    }, [])

    useEffect(() => {
        if (advertiseDetail?.service_id !== '') {
            dispatch(adminindustryCategorylist({ id: advertiseDetail?.service_id, page: 1, limit: 500, filterStatus: regionUtahId })).then((data: any) => {
                setCategoryNameList(data?.payload?.data?.categories)
            })
        }

    }, [advertiseDetail?.service_id])

    useEffect(() => {
        if (advertiseDetail?.service_id !== '' && categoryData !== '') {
            dispatch(adminindustrySubCategorylist({ id: categoryData, page: 1, limit: 500, filterStatus: regionUtahId })).then((data: any) => {
                setSubCategoryNameList(data?.payload?.data?.data);
            })
        }
    }, [categoryData])

    useEffect(() => {
        if (subCategoryData) {
            const displayName = subCategoryNameList?.find((val: any) => val?.sub_category_id == subCategoryData)?.display_name
            setDisplayName(displayName)
        }
    }, [subCategoryNameList, subCategoryData])

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => {
            const temp = val?.payload?.data?.data.find((val: any) => val?.region == 'UT').id
            setRegionUtahId(temp)
        })
    }, [])

    const temp = advertiseDetail?.banner_image && !['image/jpg', 'image/png', 'image/jpeg'].includes(advertiseDetail?.banner_image?.type)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={"New Advertisement"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('advertisement')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/advertisement']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Banner title
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("banner_title", { required: true })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="text"
                                                    placeholder="Banner title"
                                                    value={advertiseDetail?.banner_title}
                                                    onChange={(e) => {
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            banner_title: e.target.value,
                                                        }))
                                                        setValue("banner_title", e.target.value, { shouldValidate: true })
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.banner_title && "Banner title is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-last-name"
                                                >
                                                    Request title
                                                </label>
                                                <input
                                                    {...register("request_title", {
                                                    })}
                                                    className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-last-name"
                                                    type="text"
                                                    placeholder="Request title"
                                                    value={advertiseDetail?.request_title}
                                                    onChange={(e) =>
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            request_title: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Banner link
                                                </label>
                                                <input
                                                    {...register("banner_link")}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="grid-phone"
                                                    type="text"
                                                    placeholder="Banner Link"
                                                    value={advertiseDetail?.banner_link}
                                                    onChange={(e) =>
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            banner_link: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Industry
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("service_id", { required: true })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-state"
                                                        onChange={(e) => {
                                                            const abbreviation = e.target.value;
                                                            setAdvertiseDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                service_id: abbreviation,
                                                            }))
                                                            setValue("service_id", e.target.value, { shouldValidate: true });
                                                        }}
                                                        value={advertiseDetail?.service_id || ''}
                                                    // placeholder="Select industry" // Set the placeholder
                                                    >
                                                        <option value="" disabled selected hidden>
                                                            Select industry
                                                        </option>
                                                        {serviceData.map((service: any, index) => {
                                                            const name: any = Object.values(service);
                                                            return (
                                                                <option key={index} value={name[0]}>
                                                                    {name[1]}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.service_id && (
                                                    <p className="text-red text-sm">
                                                        {errors.service_id.type === "required" && "Industry is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2 ">
                                            <div className="w-1/2 px-3 relative">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Banner image
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                {image &&

                                                    <img src={URL.createObjectURL(image)} alt="Banner" className="mb-2" style={{ width: '100px', maxHeight: '100px' }} />
                                                }
                                                <div className="!border-darkGray block w-full  rounded py-3 px-4 mb-1 leading-tight focus:outline-none border border-solid  focus:bg-white cursor-no-drop">
                                                    <input
                                                        {...register("banner_image", { required: advertiseDetail?.banner_image == '' ? true : false })}
                                                        className="appearance-none hidden"
                                                        id="banner_image"
                                                        type="file"
                                                        accept="image/jpg, image/png, image/jpeg"
                                                        onChange={(e: any) => {
                                                            setIsSubmitClicked(false);
                                                            handleImageChange(e)
                                                            setValue("banner_image", e.target.value, { shouldValidate: true })
                                                        }}
                                                    />
                                                    <label htmlFor="banner_image" className="button bg-darkGray border border-solid border-black px-2 mr-2">Choose file</label>

                                                    {advertiseDetail?.banner_image?.name}
                                                </div>

                                                <p className="text-red text-sm">
                                                    {isSubmitClicked && advertiseDetail?.banner_image === '' && 'Banner image is required'}
                                                    {!temp && errorMessage && "Image must be less than 1 MB."}
                                                    {temp && 'Please select a JPG, JPEG or PNG image.'}
                                                </p>
                                                <pre className={`text-lightGray text-[#A9A9A9] text-sm absolute`}>
                                                    {`[Note : 1. The image format must be JPG, JPEG, or PNG.
             2. It's size must not exceed 1 MB.
             3. The required dimension is 350 x 400.]`}
                                                </pre >
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Category
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("categoryData", { required: true })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-state"
                                                        onChange={(e) => {
                                                            const abbreviation = e.target.value;
                                                            setCategoryData(abbreviation)
                                                            setValue("categoryData", e.target.value, { shouldValidate: true });
                                                        }}
                                                        value={categoryData || ''}
                                                    >
                                                        <option value="" selected hidden>
                                                            Select category
                                                        </option>
                                                        {advertiseDetail.service_id == '' && (
                                                            <option disabled>Please select Industry</option>
                                                        )}
                                                        {categoryNameList?.map((service: any, index) => {
                                                            const name: any = Object.values(service);
                                                            return (
                                                                <>
                                                                    <option key={index} value={name[0]}>
                                                                        {name[1]}
                                                                    </option>
                                                                </>

                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.categoryData && (
                                                    <p className="text-red text-sm">
                                                        {errors.categoryData.type === "required" && "Category is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-end items-end -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Sub-Category
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("subCategoryData", { required: true })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-state"
                                                        onChange={(e) => {
                                                            const abbreviation = e.target.value;
                                                            setSubCategoryData(abbreviation)
                                                            setValue("subCategoryData", e.target.value, { shouldValidate: true });
                                                        }}
                                                        value={subCategoryData || ''}
                                                    // placeholder="Select sub-category" // Set the placeholder
                                                    >
                                                        <option value="" selected hidden>
                                                            Select sub-category
                                                        </option>
                                                        {categoryData == '' || categoryData == undefined && (
                                                            <option disabled>Please select Category</option>
                                                        )}
                                                        {subCategoryNameList?.map((service: any, index: number) => {
                                                            const name: any = Object.values(service);
                                                            return (
                                                                <option key={index} value={name[0]}>
                                                                    {name[1]}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.subCategoryData && (
                                                    <p className="text-red text-sm">
                                                        {errors.subCategoryData.type === "required" && "Sub-Category is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex justify-end items-end -mx-3 mb-2 mt-[17px]">
                                            <div className="w-1/2 px-3">
                                                <span>Taxonomy Display Name : {displayName ? displayName : '-'}</span>
                                            </div>
                                        </div>

                                        <div className="flex -mx-3 px-3 mt-10 justify-end">

                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm"
                                                onClick={() => {
                                                    navigate('/advertisement')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2  w-[81px] h-[36px] inline-block"
                                                onClick={() => setIsSubmitClicked(true)}
                                                disabled={isAdvertiseAdded?.isLoading}
                                            >
                                                {isAdvertiseAdded?.isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isAdvertiseAdded?.isLoading &&
                                                    (
                                                        <>
                                                            Submit
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewAdvertisement;

