import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IAvaCal {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    regionId: number;
    todayDate: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const availabilityCalendar: any = createAsyncThunk(
    "AvailabilityCalendarSlice",
    async ({ regionId, todayDate }: IAvaCal) => {
        const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.holidayList.availabilityCal}?region=${regionId}&selected_date=${todayDate}`)
        return response
    }
);

export const AvailabilityCalendarSlice = createSlice({
    name: "AvailabilityCalendarSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(availabilityCalendar.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(availabilityCalendar.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(availabilityCalendar.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const avaCalendartReducer = AvailabilityCalendarSlice.reducer;
