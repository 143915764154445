
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    id?: string;
    display_order?: number;
}

interface ISortAdv {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    serviceId: number;
    updatedSortOrder: IData;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const sortAdv: any = createAsyncThunk(
    "SortAdvSlice",
    async ({ updatedSortOrder }: ISortAdv) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.advertisement.sortAdv}`, {
                sort_data: updatedSortOrder,
            }).then((data: any) => {
                showToastSuccessMessage('Display order changed successfully.')
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const SortAdvSlice = createSlice({
    name: "SortAdvSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(sortAdv.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(sortAdv.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(sortAdv.rejected, (state) => {
            state.isError = true;
            state.isSuccess = false;
        });
    },
});

export const sortAdvSliceReducer = SortAdvSlice.reducer;
