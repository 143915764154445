import { Link, useLocation } from "react-router-dom";

type Props = {
  paths: (any | string | { title: string; href: string })[];
  className?: string;
  url?: any;
  url2?: any
};

export function Breadcrumb({ paths, className, url, url2 }: Props) {
  const location = useLocation()
  const capitalizeFirstLetter = (word: string) => {
    const words = word.split("-");
    const capitalizedWords = words.map((w) =>
      w.charAt(0).toUpperCase() + w.slice(1)
    );
    return capitalizedWords.join(" ");
  };

  return (
    <nav aria-label="breadcrumb" className={className}>
      <ol className="flex">
        {paths.map((path: any, i: any) => {
          return (
            <li key={i} className="font-normal">
              {i !== 0 && paths[i] !== null && paths[i] !== '' && <span className="text-gray-500 px-3">{" > "}</span>}
              {typeof path === "string" && (
                <Link
                  to={[0, 1].includes(i) ? `/${path}` : (i == 2 ? `/${url2 ?? url}` : `/${url}`)}
                  // to={path.includes('of') && url2 ? `/${url2}` : path.includes('of') ? `/${url}` : `/${path}`}
                  className={`${(
                    i < paths.length - 1 || i === 0
                      ? ["text-gray-500"]
                      : ["text-primary-600 dark:text-primary-400"]
                  )}`}
                  style={{ color: "#f27366" }}
                  state={location?.state}
                >
                  {capitalizeFirstLetter(path)}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
