import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { zipCodeDetails } from "../../feature/Zipcode/DetailsZipCode-Slice";
import { editZipcode } from "../../feature/Zipcode/EditZipCode-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import { TimeZoneData } from '../../constants/TimeZoneData'

interface IFormInputs {
    zipcode?: string;
    timezone?: string;
    state_code?: any;
    state?: any

}

const EditZipCode = () => {
    const [zipcodeId, setZipCodeId] = useState('')
    const [zipCodeDetail, setSetZipCodeDetail] = useState<any>({
        zipcode: '',
        timezone: '',
        state_code: '',
        state: ''
    })
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params: any = useParams()
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<IFormInputs>();

    // To get the data of the user from the api.
    useEffect(() => {
        setZipCodeId(params?.id);
        dispatch(zipCodeDetails({ id: params?.id })).then((data: any) => {
            const details = data?.payload?.data?.data;
            setSetZipCodeDetail(details);
            // Update form values
            setValue("zipcode", details.zipcode);
            setValue("timezone", details.timezone);
            setValue("state", details.state);

            // Add more fields as needed
        });
    }, [window.location.pathname, setValue]); // Include setValue in the dependency array




    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        dispatch(editZipcode({ zipcodeId, zipCodeDetail })).then(
            (data: any) => {
                if (data?.meta?.requestStatus === "fulfilled") {
                    setTimeout(() => {
                        navigate(-1);
                    }, 1000);
                }
            }
        );
    };

    const handleStateChange = (event: any) => {

        const stateName: any = hcpRegionList.find((state: any) => state?.name === event.target.value);

        setSetZipCodeDetail((prevUserDetail: any) => ({
            ...prevUserDetail,
            state: stateName?.name,
            state_code: stateName?.region

        }));
        setValue("state", event.target.value, { shouldValidate: true });
    };

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
    }, [])

    const { isLoading } = useSelector((state: any) => state?.zipcodeDetail)
    const isZipcodeEdited = useSelector((state: any) => state?.editZipcode)


    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={"ZipCode Details"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('zipcode')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/zipcode']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Zipcode
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("zipcode", {
                                                        required: zipCodeDetail?.zipcode == '' ? true : false,
                                                        pattern: {
                                                            value: /^\d{5}$/,
                                                            message: "Zipcode should be exactly 5 digits",
                                                        },
                                                    })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="number"
                                                    placeholder="Zipcode"
                                                    value={zipCodeDetail?.zipcode}
                                                    onChange={(e) => {
                                                        setSetZipCodeDetail((prevUserDetail: any) => ({
                                                            ...prevUserDetail,
                                                            zipcode: e.target.value,

                                                        }))
                                                        setValue("zipcode", e.target.value, { shouldValidate: true })
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {zipCodeDetail?.zipcode == '' && 'Zipcode is required' || errors?.zipcode?.message}
                                                </p>
                                            </div>
                                            <div className="relative w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-phone"
                                                >
                                                    Timezone
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <select
                                                    {...register("timezone", { required: zipCodeDetail?.timezone == '' ? true : false })}
                                                    className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                                    id="grid-first-name"
                                                    value={zipCodeDetail?.timezone}
                                                    onChange={(e) => {
                                                        setSetZipCodeDetail((prevUserDetail: any) => ({
                                                            ...prevUserDetail,
                                                            timezone: e.target.value,
                                                        }))
                                                        setValue("timezone", e.target.value, { shouldValidate: true })
                                                    }}
                                                >
                                                    <option value="" disabled hidden>
                                                        Select timezone
                                                    </option>
                                                    {/* {zipCodeDetail?.timezone && !TimeZoneData.includes(zipCodeDetail.timezone) && (
                                                        <option value={zipCodeDetail.timezone}>{zipCodeDetail.timezone}</option>
                                                    )} */}
                                                    <option value="us/mountain">us/mountain</option>
                                                    <option value="america/phoenix">america/phoenix</option>
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700 mt-[22px]">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>
                                                <p className="text-red text-sm">
                                                    {zipCodeDetail?.timezone == '' && "Timezone is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="relative w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-phone"
                                                >
                                                    State
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <select
                                                    {...register("state", { required: true })}
                                                    className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                                    id="grid-state"
                                                    value={zipCodeDetail?.state || '-'}
                                                    onChange={handleStateChange}
                                                // defaultValue={zipCodeDetail?.state}

                                                >
                                                    {zipCodeDetail?.state == '' ? (
                                                        <option value="" disabled>
                                                            -
                                                        </option>
                                                    ) :
                                                        hcpRegionList && hcpRegionList.length > 0 ? (
                                                            hcpRegionList.map((state: any, index) => (
                                                                <option key={index} defaultValue={state.region} >
                                                                    {state.name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option value="" disabled>
                                                                -
                                                            </option>
                                                        )
                                                    }
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700 mt-[22px]">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </div>

                                                {/* <p className="text-red text-sm">
                                                    {zipCodeDetail?.state == '' && "State is required."}
                                                </p> */}
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 px-3 mt-5 justify-end">

                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm "
                                                onClick={() => {
                                                    navigate(-1)
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm ml-2 w-[81px] h-[36px] inline-block"
                                                disabled={isZipcodeEdited?.isLoading}
                                            >
                                                {isZipcodeEdited?.isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isZipcodeEdited?.isLoading &&
                                                    (
                                                        <>
                                                            Submit
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditZipCode;



