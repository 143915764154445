import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IUserList {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userlist: [];
    page?: null;
    limit?: null;
    search_text?: string
    userId?: number
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    userlist: []
};

export const userAddresslist: any = createAsyncThunk(
    "UserAddressListSlice",
    async ({ page, limit, search_text, userId }: IUserList) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.userAddress}/${userId}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}`)
        return response
    }
);

export const UserAddresslistSlice = createSlice({
    name: "UserAddressListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userAddresslist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(userAddresslist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(userAddresslist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const userAddressListSliceReducer = UserAddresslistSlice.reducer;
