import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IUseDetails {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  id:string;
  userDetails:[];
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  userDetails:[]
};

export const userdetails: any = createAsyncThunk(
  "UserDetailSlice",
  async ({id  }: IUseDetails) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.users?.userdetails}/${id}`)
    return response
  }
);

export const UserdetailSlice = createSlice({
  name: "UserDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userdetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userdetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(userdetails.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const userDetailSliceReducer = UserdetailSlice.reducer;
