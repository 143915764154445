import { configureStore } from "@reduxjs/toolkit";
import { viewJobSliceReducer } from "../feature/Job's/ViewJob-Slice";
import { editUserSliceReducer } from "../feature/Users/EditUser-Slice";
import { jobListReducer } from "../feature/Job's/JobList-Slice";
import { loginSliceReducer } from "../feature/Authentications/Login-Slice";
import { userListSliceReducer } from "../feature/Users/UserList-Slice";
import { userDetailSliceReducer } from "../feature/Users/UserDetails-Slice";
import { employeeListSliceReducer } from "../feature/Employees/EmployeesList-Slice";
import { estimaterequestListReducer } from "../feature/EstimateRequest/EstimateRequestList-Slice";
import { viewEstimateRequestReducer } from "../feature/EstimateRequest/ViewEstimateRequest-Slice";
import { advertisementSliceReducer } from "../feature/Advertisement/AdvertisementList-Slice";
import { advertiseDetailSliceReducer } from "../feature/Advertisement/AdvertisementDetails-Slice";
import { editAdvertiseSliceReducer } from "../feature/Advertisement/EditAdvertisement-Slice";
import { deleteAdvertiseSliceReducer } from "../feature/Advertisement/DeleteAdvertisement-Slice";
import { newAdvertiseSliceReducer } from "../feature/Advertisement/NewAdvertisement-Slice";
import { forgotpasswordSliceReducer } from '../feature/Authentications/ForgotPassword-Slice'
import { resetpasswordSliceReducer } from "../feature/Authentications/ResetPassword-Slice";
import { serviceListSliceReducer } from "../feature/Services/ServiceList-Slice";
import { deleteServiceSliceReducer } from "../feature/Services/DeleteService-Slice";
import { editServiceSliceReducer } from "../feature/Services/EditService-Slice";
import { serviceDetailSliceReducer } from "../feature/Services/ServiceDetails-Slice";
import { newServiceSliceReducer } from "../feature/Services/NewService-Slice";
import { hcpregionSliceReducer } from "../feature/HCP Region/HCPRegionListing-Slice";
import { hcpRegionDetailSliceReducer } from "../feature/HCP Region/HCPRegionDetails-Slice";
import { editRegionSliceReducer } from "../feature/HCP Region/EditHCPRegion-Slice";
import { userjobDetailSliceReducer } from "../feature/Users/UserJobs-Slice";
import { syncCustomerJobSliceReducer } from "../feature/Job's/CustomerJobSync-Slice";
import { syncJobSliceReducer } from "../feature/Job's/SyncJob-Slice";
import { syncCustomerSliceReducer } from "../feature/Users/CustomerSync-Slice";
import { syncEmployeeSliceReducer } from "../feature/Employees/SyncEmployee-Slice";
import { zipcodeListReducer } from "../feature/Zipcode/ZipcodeList-Slice";
import { zipcodeDetailSliceReducer } from "../feature/Zipcode/DetailsZipCode-Slice";
import { editZipcodeSliceReducer } from "../feature/Zipcode/EditZipCode-Slice";
import { addZipcodeSliceReducer } from "../feature/Zipcode/AddZipcode-Slice";
import { employeeStatusSliceReducer } from "../feature/Employees/EmployeeStatus-Slice";
import { dashboardCountSliceReducer } from "../feature/Dashboard/DashboardCount-Slice";
import { estimateListReducer } from "../feature/Estimate/EstimateList-Slice";
import { appConfigListSliceReducer } from "../feature/AppConfig/AppConfigList-Slice";
import { deleteUserSliceReducer } from "../feature/Users/DeleteUser-Slice";
import { draftUserListSliceReducer } from "../feature/DraftUser/DraftUser-Slice";
import { editConfigSliceReducer } from "../feature/AppConfig/EditConfig-Slice";
import { invoiceJobSliceReducer } from "../feature/Job's/GenerateInvoice-Slice";
import { addUserSliceReducer } from "../feature/Users/AddUser-Slice";
import { sidebarColReducer, siderbarSlice } from "../feature/Sidebar/SidebarCollapse-Slice";
import { changepasswordSliceReducer } from "../feature/Authentications/ChangePassword-Slice";
import { sortServiceSliceReducer } from "../feature/Services/SortService-Slice";
import { sortAdvSliceReducer } from "../feature/Advertisement/AdvertisementSort-Slice";
import { changeUserPasswordSliceReducer } from "../feature/Users/ChangePasswordUser-Slice";
import { syncServiceSliceReducer } from "../feature/Services/SyncService-Slice";
import { addressRequestListSliceReducer } from "../feature/AddressRequest/AddressRequest-Slice";
import { changeAddressStatusSliceReducer } from "../feature/AddressRequest/ChangeStatus-Slice";
import { userAddressListSliceReducer } from "../feature/Users/UserAddressList-Slice";
import { userPropertyReducer } from "../feature/Property/UserPropeties-Slice";
import { syncCustomerAddressSliceReducer } from "../feature/AddressRequest/CustomerAddressSync-Slice";
import { allzipcodeListReducer } from "../feature/Zipcode/AllZipcode-Slice";
import { estimateAddressListReducer } from "../feature/Estimate/EstimateListAddress-Slice";
import { industryListSliceReducer } from "../feature/Taxonomy/IndustryList-Slice";
import { adminindustryListReducer } from "../feature/Taxonomy/AdminIndustry-Slice";
import { adminindustrycategoryListReducer } from "../feature/Taxonomy/AdminIndustryCategory-Slice";
import { adminindustrysubcategoryListReducer } from "../feature/Taxonomy/AdminIndustrySub-Slice";
import { sortTaxonomySliceReducer } from "../feature/Taxonomy/SortTaxonomy-Slice";
import { updateAdminProfileSliceReducer } from "../feature/UpdateProfile/UpdateProfile-Slice";
import { editVendorSliceReducer } from "../feature/Taxonomy/EditSubCategory-Slice";
import { avaCalendartReducer } from "../feature/HolidayList/AvailabilityCalender-Slice";
import { bookSlotsSliceReducer } from "../feature/HolidayList/BookedSlots-Slice";
import { removeBookSlotsSliceReducer } from "../feature/HolidayList/RemoveBookedSlots-Slice";
import { webAnalyticsListReducer } from "../feature/WebAnalytics/WebAnalyticsListing-Slice";
import { employeeSkillSliceReducer } from "../feature/Employees/EmployeeSkills-Slice";
import { deleteZipcodeSliceReducer } from "../feature/Zipcode/DeleteZipcode-Slice";
import { staffloginListReducer } from "../feature/StaffLogin/StaffLoginListing-Slice";
import { masterPasswordReducer } from "../feature/MasterPassword/MasterPasswordList";
import { autoPasswordReducer } from "../feature/MasterPassword/NewMasterPassword";
import { editEmployeeSliceReducer } from "../feature/Employees/EditEmployees-Slice";
import { addEmployeeSliceReducer } from "../feature/Employees/EmployeeBindAdd-Slice";
import { editTaxonomyStatusReducer } from "../feature/Taxonomy/EditTaxonomyStatus-Slice";
import { exportTaxonomySliceReducer } from "../feature/Taxonomy/ExportTaxnomoy-Slice";
import { importTaxonomySliceReducer } from "../feature/Taxonomy/ImportTaxonomy-Slice";

export const store = configureStore({
        reducer: {
                login: loginSliceReducer,
                userList: userListSliceReducer,
                deleteUser: deleteUserSliceReducer,
                editUser: editUserSliceReducer,
                jobList: jobListReducer,
                viewJob: viewJobSliceReducer,
                userDetail: userDetailSliceReducer,
                employeeList: employeeListSliceReducer,
                estimaterequestList: estimaterequestListReducer,
                viewEstimateRequest: viewEstimateRequestReducer,
                advertisementList: advertisementSliceReducer,
                advertiseDetail: advertiseDetailSliceReducer,
                editAdvertise: editAdvertiseSliceReducer,
                deleteAdvertise: deleteAdvertiseSliceReducer,
                newAdvertise: newAdvertiseSliceReducer,
                forgotpassword: forgotpasswordSliceReducer,
                resetpassword: resetpasswordSliceReducer,
                serviceList: serviceListSliceReducer,
                deleteService: deleteServiceSliceReducer,
                editService: editServiceSliceReducer,
                serviceDetail: serviceDetailSliceReducer,
                newService: newServiceSliceReducer,
                hcpregion: hcpregionSliceReducer,
                hcpRegionDetail: hcpRegionDetailSliceReducer,
                editRegion: editRegionSliceReducer,
                userjobDetail: userjobDetailSliceReducer,
                syncCustomerJob: syncCustomerJobSliceReducer,
                syncJob: syncJobSliceReducer,
                syncCustomer: syncCustomerSliceReducer,
                syncEmployee: syncEmployeeSliceReducer,
                zipcode: zipcodeListReducer,
                zipcodeDetail: zipcodeDetailSliceReducer,
                editZipcode: editZipcodeSliceReducer,
                addZipcode: addZipcodeSliceReducer,
                employeeStatus: employeeStatusSliceReducer,
                dashboardCount: dashboardCountSliceReducer,
                estimateList: estimateListReducer,
                appConfigList: appConfigListSliceReducer,
                draftUserList: draftUserListSliceReducer,
                editConfig: editConfigSliceReducer,
                invoiceJob: invoiceJobSliceReducer,
                addUser: addUserSliceReducer,
                siderbar: sidebarColReducer,
                changepassword: changepasswordSliceReducer,
                sortService: sortServiceSliceReducer,
                sortAdv: sortAdvSliceReducer,
                changeUserPassword: changeUserPasswordSliceReducer,
                syncService: syncServiceSliceReducer,
                addressRequestList: addressRequestListSliceReducer,
                changeAddressStatus: changeAddressStatusSliceReducer,
                userAddressList: userAddressListSliceReducer,
                userProperty: userPropertyReducer,
                syncCustomerAddress: syncCustomerAddressSliceReducer,
                allzipcodeList: allzipcodeListReducer,
                estimateAddressList: estimateAddressListReducer,
                industryList: industryListSliceReducer,
                adminindustryList: adminindustryListReducer,
                adminindustrycategoryList: adminindustrycategoryListReducer,
                adminindustrysubcategoryList: adminindustrysubcategoryListReducer,
                sortTaxonomy: sortTaxonomySliceReducer,
                editVendor: editVendorSliceReducer,
                avaCalendar: avaCalendartReducer,
                bookSlots: bookSlotsSliceReducer,
                removeBookSlots: removeBookSlotsSliceReducer,
                updateAdminprofile: updateAdminProfileSliceReducer,
                webAnalyticsList: webAnalyticsListReducer,
                employeeSkill: employeeSkillSliceReducer,
                deleteZipcode: deleteZipcodeSliceReducer,
                staffloginList: staffloginListReducer,
                masterPassword: masterPasswordReducer,
                autoPasswordSlice: autoPasswordReducer,
                editEmployeeSlice: editEmployeeSliceReducer,
                addEmployeeSlice: addEmployeeSliceReducer,
                editTaxonomyStatus: editTaxonomyStatusReducer,
                exportTaxonomySlice: exportTaxonomySliceReducer,
                importTaxonomySlice: importTaxonomySliceReducer
        }
})