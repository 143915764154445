import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IHCPRegionDetails {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    id: string;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const hcpregiondetails: any = createAsyncThunk(
    "HCPRegionDetailSlice",
    async ({ id }: IHCPRegionDetails) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.hcpRegion?.hcpRegionDetails}/${id}`)
        return response
    }
);

export const HCPRegionDetailSlice = createSlice({
    name: "HCPRegionDetailSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(hcpregiondetails.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(hcpregiondetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(hcpregiondetails.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const hcpRegionDetailSliceReducer = HCPRegionDetailSlice.reducer;
