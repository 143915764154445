
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastSuccessMessage } from "../../utils/utils";

interface IData {
    email?: string;
    password?: string
}

interface IChangeUserPass {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    userDetail: IData;
    email: string;
    password: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    userDetail: []
};

export const changeUserPassword: any = createAsyncThunk(
    "ChangeUserPasswordSlice",
    async ({ password, email }: IChangeUserPass) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.changePassword}`, {
                email: email,
                password: password,


            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.messages)
            })
            return response
        } catch (error: any) {
            throw error
        }
    }
);

export const ChangeUserPasswordSlice = createSlice({
    name: "ChangeUserPasswordSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(changeUserPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(changeUserPassword.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(changeUserPassword.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const changeUserPasswordSliceReducer = ChangeUserPasswordSlice.reducer;
