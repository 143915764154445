import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { availabilityCalendar } from "../../feature/HolidayList/AvailabilityCalender-Slice";
import moment from "moment";
import { bookSlots } from "../../feature/HolidayList/BookedSlots-Slice";
import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { removeBookedSlots } from "../../feature/HolidayList/RemoveBookedSlots-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

interface IFormInputs {
    reason?: string;
}

const HolidayList = () => {
    const [regionId, setRegionId]: any = useState(1);
    const [shiftData, setShiftData]: any = useState([]);
    const [bookedSlots, setBookedSlots]: any = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [isALlSLotsClicked, setIsAllSlotsClicked] = useState(false);
    const [isSlotBookedByAdmin, setIsSlotBookedByAdmin] = useState(false)
    const [isSelected, setIsSelected] = useState<number[]>([]);
    const [isPopupClose, setIsPopupClose] = useState(false)
    const [isSlotsBooked, setIsSlotsBooked] = useState(false)
    const [reason, setReason]: any = useState("");
    const [perDatereason, setperDateReason]: any = useState("");
    const [currentCalendarView, setCurrentCalenderView] = useState('month')
    const [slotsBookedByAdmin, setSlotsBookedByAdmin]: any = useState()
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [isDatePast, setIsDatePast] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const todayDate = selectedDate
            ? selectedDate
            : moment(new Date()).format("YYYY-MM-DD");

        const date = moment(new Date()).format("YYYY-MM-DD");
        setIsDatePast(moment(selectedDate).isBefore(date, 'day'));

        setTimeout(() => {
            dispatch(availabilityCalendar({ regionId, todayDate })).then(
                (data: any) => {
                    setShiftData(data?.payload?.data?.data);
                    setSlotsBookedByAdmin(data?.payload?.data?.data.find((temp: any) => temp?.monthDate == todayDate))
                    setReason(data?.payload?.data?.data?.find((temp: any) => temp?.monthDate == todayDate).booking_reason)
                }
            );
        }, 300)

    }, [regionId, selectedDate, isPopupClose, isSlotsBooked]);

    useEffect(() => {
        setperDateReason(shiftData?.find((temp: any) => temp?.monthDate == selectedDate)?.booking_reason)
        clearErrors('reason')
    }, [selectedDate, shiftData])

    const calenederClicked = (date: any) => {
        setBookedSlots([]);
        setIsSelected([]);
        // setReason('')
        setIsAllSlotsClicked(false);
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setSelectedDate(formattedDate);
    };

    const tileDisabled = ({ date }: any) => {
        if (currentCalendarView !== 'month') {
            return false;
        }
        return date.getDay() === 0 || date.getDay() === 6;
    }

    const bookSlotsHandler = () => {
        if (reason == '') {
            return false
        }

        const todayDate = moment(new Date()).format("YYY-MM-DD");
        dispatch(
            bookSlots({
                booking_date: selectedDate ? selectedDate : todayDate,
                booking_reason: reason,
                hcp_region_id: regionId
            })
        );
        setReason('')
        setIsSlotsBooked(!isSlotsBooked)
        // setIsAllSlotsClicked(!isALlSLotsClicked)
    };

    const reasonHandler = (e: string) => {
        setReason(e);
        setBookedSlots((prevBookedSlots: any) => {
            return prevBookedSlots.map((slot: any) => ({
                ...slot,
                booking_reason: e,
            }));
        });
    };

    const removeSlots = useSelector((state: any) => state.removeBookSlots)
    const avalCalendar = useSelector((state: any) => state?.avaCalendar)
    const bookSLots = useSelector((state: any) => state?.bookSlots)

    const {
        register,
        formState: { errors },
        setValue,
        resetField,
        clearErrors,
        setFocus,
        handleSubmit
    } = useForm<IFormInputs>();

    const confirmDeleteSlots = () => {
        dispatch(removeBookedSlots({ selectedDate, regionId })).then((val: any) => {
            setIsPopupClose(!isPopupClose)
            if (val?.meta?.requestStatus == 'fulfilled') {
                setIsSelected([]);
            }
        })
    }
    const temp: any = slotsBookedByAdmin?.shiftData?.every((val: any) => val?.booked_by_user == false)

    const isWeekend = (date: any) => {
        const day = date.getDay();
        return day === 0 || day === 6;
    };

    const tileContent = ({ date, view }: any) => {
        const formattedDate: any = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        const isActiveStartDate: boolean = moment(date).isSame(selectedDate, 'day');
        const isFirstDayOfNextMonth: boolean = moment(date).startOf('month').isSame(moment(selectedDate).add(1, 'month').startOf('month'));
        const hasEvent: any = shiftData?.some((shift: any) => shift.monthDate === formattedDate && shift.booked_by_admin === true);

        return (
            <div className={`tile-content ${isActiveStartDate || isFirstDayOfNextMonth ? 'active-start-date' : ''}`}>
                {hasEvent && view === 'month' && !isWeekend(date) ? <div className="event-marker"></div> : null}
            </div>
        );
    };

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
    }, [])

    isSlotBookedByAdmin
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto')

    return (
        <>
            <div className="flex holidayListWrapper">
                <Sidebar />
                <div className="content w-full max-w-[calc(100%_-_220px)] max-width-custom">
                    <Header />
                    <div className="fluid p-4 custom-height-wrapper">
                        <div className="pb-5">
                            <Title title={`Holiday List`} />
                        </div>
                        <div className="region relative w-full flex gap-3 items-center justify-start">
                            <span>Select Region:</span>
                            <div className="w-[250px] flex items-center">
                                <select
                                    className="selector-bg block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white text-lg"
                                    id="grid-state"
                                    value={regionId}
                                    onChange={(e) => {
                                        setBookedSlots([]);
                                        setIsSelected([]);
                                        setRegionId(e?.target?.value);
                                        // setSelectedDate("");
                                    }}
                                >
                                    {hcpRegionList && hcpRegionList.length > 0 ? (
                                        hcpRegionList.map((state: any, index) => (
                                            <option key={index} value={state.id} >
                                                {state.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            -
                                        </option>
                                    )}

                                </select>
                                <div className="pointer-events-none relative right-5  text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[50px]">
                            <div className="flex gap-x-[20px]  calender-main-top ">
                                <div className="basis-[50%] justify-center calender-main">
                                    <div className="px-5 py-3 border-solid border-red-500 w-1/6 h-auto bg-gray calender-check rounded-xl">
                                        <ul className="items flex !justify-between !items-center">
                                            <li className="flex items-center gap-x-3">
                                                <span className="bg-logo rounded-full w-3 h-3"></span>
                                                <span>Holiday</span>
                                            </li>
                                            <li className="flex items-center gap-x-3">
                                                <span className="bg-[#f1c40f] rounded-full w-3 h-3"></span>
                                                <span>Today's Date</span>
                                            </li>
                                            <li className="flex items-center gap-x-3">
                                                <span className="bg-[#ababab] rounded-full w-3 h-3"></span>
                                                <span>Weekend</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <Calendar
                                        onChange={calenederClicked}
                                        tileDisabled={tileDisabled}
                                        onViewChange={(data) => setCurrentCalenderView(data.view)}
                                        tileContent={tileContent}
                                        onActiveStartDateChange={({ activeStartDate }) => {
                                            setCurrentCalenderView('month');
                                            const formattedDate = moment(activeStartDate).format("YYYY-MM-DD");
                                            setSelectedDate(formattedDate);
                                        }}
                                    />
                                </div>
                                <div className="justify-center flex flex-col justify-between relative w-[690px]">
                                    <div className="title-wrapper">
                                        {avalCalendar?.isSuccess && slotsBookedByAdmin?.booked_by_admin == true && (
                                            <>
                                                <label className="flex justify-center items-center text-xl">{`Admin has booked holiday for ${moment(selectedDate || new Date()).format('D MMMM YYYY')}. `}</label>
                                            </>
                                        )}
                                        {avalCalendar?.isSuccess && slotsBookedByAdmin?.booked_by_admin == false && temp == false && (
                                            <>
                                                <label className="flex text-xl">{` Slots are booked by the Customers so you cannot set holiday for ${moment(selectedDate || new Date()).format('D MMMM YYYY')}.`}</label>
                                            </>
                                        )}
                                        {avalCalendar?.isSuccess && !slotsBookedByAdmin?.booked_by_admin && temp && (
                                            <>
                                                <label className="flex justify-center items-center text-xl">{isWeekend(new Date(selectedDate)) ? `You cannot book holiday for ${moment(selectedDate || new Date()).format('D MMMM YYYY')}.` : `You can book holiday for ${moment(selectedDate || new Date()).format('D MMMM YYYY')}. `}</label>
                                            </>
                                        )}
                                    </div>
                                    {avalCalendar?.isLoading ? (
                                        <div className="justify-center items-center absolute left-[50%] top-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                                            <ClipLoader color="#f27366" size={50} />
                                        </div>

                                    ) : null
                                    }
                                    <form
                                        onSubmit={handleSubmit(bookSlotsHandler)}
                                        className="w-full max-w-full"
                                    >
                                        {!avalCalendar?.isLoading && (
                                            <div className="flex flex-col gap-2 items-start py-7 mb-[74px]">
                                                <div>Reason:</div>
                                                <div className="w-full">
                                                    <textarea
                                                        disabled={isWeekend(new Date(selectedDate)) || slotsBookedByAdmin?.booked_by_admin || !temp ? true : false}
                                                        {...register("reason", { required: !perDatereason })}
                                                        className="w-full h-40 border-darkGray appearance-none border py-3 px-4 rounded leading-tight focus:outline-none bg-white"
                                                        value={reason ? reason : ''}
                                                        onChange={(e) => {
                                                            reasonHandler(e?.target?.value);
                                                            setValue("reason", e.target.value, { shouldValidate: true })
                                                        }}
                                                        style={{ resize: 'none' }}
                                                    />
                                                    <p className="text-red text-sm">
                                                        {errors?.reason && !reason && "Reason is required."}
                                                    </p>
                                                </div>
                                            </div>

                                        )}
                                        <div>
                                            {!slotsBookedByAdmin?.booked_by_admin ? (
                                                <div className="flex justify-end ">
                                                    <button
                                                        disabled={isWeekend(new Date(selectedDate)) || !temp || slotsBookedByAdmin?.booked_by_admin || isDatePast ? true : false}
                                                        type="submit"
                                                        className={`${isWeekend(new Date(selectedDate)) ? 'bg-darkGray' : isDatePast ? 'bg-darkGray' : temp ? 'bg-red' : 'bg-darkGray'} px-8 py-3 text-white w-[200px]`}
                                                    // onClick={bookSlotsHandler}
                                                    >
                                                        {bookSLots?.isLoading &&
                                                            <ClipLoader color="#FFFFFF" size={20} />
                                                        }
                                                        {
                                                            !bookSLots?.isLoading &&
                                                            (
                                                                <>
                                                                    Book Holiday
                                                                </>
                                                            )
                                                        }
                                                    </button>
                                                </div>

                                            ) :
                                                <div className="flex justify-end ">
                                                    <button
                                                        disabled={!temp || !slotsBookedByAdmin?.booked_by_admin || isDatePast ? true : false}
                                                        type="button"
                                                        className={`${isDatePast ? 'bg-darkGray' : slotsBookedByAdmin?.booked_by_admin ? 'bg-red' : 'bg-darkGray'} px-8 py-3 text-white w-[200px]`}
                                                        onClick={() => setIsSlotBookedByAdmin(true)}
                                                    >
                                                        {bookSLots?.isLoading &&
                                                            <ClipLoader color="#FFFFFF" size={20} />
                                                        }
                                                        {
                                                            !bookSLots?.isLoading &&
                                                            (
                                                                <>
                                                                    Remove Holiday
                                                                </>
                                                            )
                                                        }

                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {isSlotBookedByAdmin &&
                <>
                    <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setIsSlotBookedByAdmin(false)}>
                        <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded'>
                            <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setIsSlotBookedByAdmin(false)} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg></button>
                            <div className="content p-10 ">
                                <p className="text-center">{`Are you sure want to remove booked holiday for ${moment(selectedDate).format('DD MMMM')} ?`}</p>
                                <div className="flex justify-center gap-x-5 mt-3">
                                    <button onClick={() => setIsSlotBookedByAdmin(false)} className="text-center bg-red text-white px-5 py-2  w-[68px] h-[40px] inline-block">No</button>
                                    <button onClick={() => confirmDeleteSlots()} className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block">

                                        {removeSlots?.isLoading &&
                                            <ClipLoader color="#FFFFFF" size={20} />
                                        }
                                        {
                                            !removeSlots?.isLoading &&
                                            (
                                                <>
                                                    Yes
                                                </>
                                            )
                                        }

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
};

export default HolidayList;
