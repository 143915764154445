import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Loader from "../../components/loader/Loader";
import Pagination from "../../components/common/Pagination";
import { estimatelistAddress } from "../../feature/Estimate/EstimateListAddress-Slice";
import { Breadcrumb } from "../../components/breadcrumb";

let PageSize = 1;

const EstimateAddress = () => {
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState(1);
    const [count, setCount] = useState(0)
    const [estimateListData, setEstimateListData] = useState([])
    const [searchCount, setSearchCount] = useState(0)
    const [address, setAddress]: any = useState({})

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const query = useParams()
    const location = useLocation();
    const [fullName, setFullName] = useState('')

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);


    useEffect(() => {
        // if (typeof currentPage !== 'undefined') {
        //     const queryParams = new URLSearchParams(location.search);
        //     queryParams.set("page", String(currentPage));
        //     window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
        //     navigate(`?${queryParams.toString()}`);

        JobList();
        // }
    }, [currentPage, navigate, searchResult]);


    const JobList = () => {
        dispatch(estimatelistAddress({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            id: query?.id
        })).then((data: any) => {
            setEstimateListData(data?.payload?.data?.data?.estimationList);
            setCount(data?.payload?.data?.data?.totalRecords);
            setSearchCount(data?.payload?.data?.data?.count);
            setFullName(data?.payload?.data?.data?.fullName);
            setAddress(data?.payload?.data?.data?.address)
        }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.estimateAddressList)
    const newTemp = address?.street
    const name = fullName?.split(' ')
    // const newName = name[0]?.charAt(0)?.toUpperCase() + name[0]?.slice(1) + ' ' + name[1]?.charAt(0)?.toUpperCase() + name[1]?.slice(1)
    const tem = 'users'
    const prop = 'all-prop'
    const id = location?.state?.id

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="flex justify-between items-center w-100" >
                        <div>
                            <Title title={`Estimate List of ${address?.street ? address?.street : ''} (${fullName})`} />
                            <Breadcrumb
                                paths={[('dashboard'), location?.state?.propUser == 'users' || location?.state?.id !== undefined ? ('users') : ('users-properties'), `${location?.state?.id ? `${`Property list of ${fullName}`}` : ''}`]}
                                className="text-sm mb-3"
                                url={`${location?.state?.id ? (`users/address-list/${location?.state?.id}`) : ''}`}
                            />
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                    <div className="flex items-center justify-between w-full" >
                                        <Search
                                            setSearchResult={setSearchResult}
                                            searchResult={searchResult}
                                            currentPage={setCurrentPage}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden" >
                                        <table className="w-full text-left table-auto">
                                            <thead className="bg-logo text-white">
                                                <tr>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[67px]">
                                                        Id
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[203px]">
                                                        Title
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[244px]">
                                                        Description
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[183px]">
                                                        department
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[183px]">
                                                        created at
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[173px]">
                                                        status
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[100px]">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {isLoading ? (
                                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                        <ClipLoader color="#f27366" size={50} />
                                                    </div>
                                                ) : <>

                                                    {estimateListData?.length ? estimateListData.map((data: any, index: any) => {
                                                        const isGrayRow = index % 2 === 0;
                                                        return (
                                                            <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.id}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data.task_title.length >= 50
                                                                        ? data?.task_title?.charAt(0)?.toUpperCase() + data.task_title.slice(1, 50) + '...'
                                                                        : data?.task_title?.charAt(0)?.toUpperCase() + data.task_title.slice(1, 50) || '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data.task_description.length >= 50
                                                                        ? data?.task_description?.charAt(0)?.toUpperCase() + data?.task_description?.slice(1, 50) + '...'
                                                                        : data?.task_description?.charAt(0)?.toUpperCase() + data?.task_description?.slice(1, 50) || '-'}

                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.department ? data?.department?.charAt(0)?.toUpperCase() + data?.department?.slice(1) : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.status == true ? 'Active' : 'In Active'}
                                                                </td>
                                                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                    <span className="flex items-center">
                                                                        <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                            onClick={() => { navigate(`/estimate-address/view/${query.id}`, { state: { data, newTemp, tem, id, prop } }) }}
                                                                        >
                                                                            <button className="text-xs font-bold text-black uppercase" >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                    <title>View</title>
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                <div>No Data Found</div>
                                                            </td>
                                                        </tr>}
                                                </>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col items-center my-12">
                        {
                            estimateListData && estimateListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default EstimateAddress;
