import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    hcpRegionList: []
};

export const hcpRegionlist: any = createAsyncThunk(
    "HCPRegionListSlice",
    async () => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.hcpRegion?.hcpRegionList}`)
        return response
    }
);

export const HCPRegionSlice = createSlice({
    name: "HCPRegionListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(hcpRegionlist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(hcpRegionlist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(hcpRegionlist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const hcpregionSliceReducer = HCPRegionSlice.reducer;
