import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IUserList {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  userlist: [];
  page?: null;
  limit?: null;
  search_text?: string;
  region?: string
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  userlist: []
};

export const userlist: any = createAsyncThunk(
  "UserListSlice",
  async ({ page, limit, search_text, region }: IUserList) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.userList}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}${region ? `&region=${region}` : ''}`)
    return response
  }
);

export const UserlistSlice = createSlice({
  name: "UserListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userlist.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(userlist.rejected, (state) => {
      state.isError = true;
      state.isLoading = false
    });
  },
});

export const userListSliceReducer = UserlistSlice.reducer;
