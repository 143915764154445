import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IWebList {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    joblist: [];
    page: null;
    limit: null;
    search_text?: string;
    start_date?: string;
    end_date?: string;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    weblist: []
};

export const weblist: any = createAsyncThunk(
    "WebAnalyticsListSlice",
    async ({ page, limit, search_text, start_date, end_date }: IWebList) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.webAnalytics}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}${start_date !== 'Invalid date' && start_date !== undefined && end_date !== 'Invalid date' && end_date !== undefined ? `&start_date=${start_date}` : ''}${end_date !== 'Invalid date' && end_date !== undefined && start_date !== 'Invalid date' && start_date !== undefined ? `&end_date=${end_date}` : ''}`)
        return response
    }
);

export const WebAnalyticsListSlice = createSlice({
    name: "WebAnalyticsListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(weblist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(weblist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(weblist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const webAnalyticsListReducer = WebAnalyticsListSlice.reducer;