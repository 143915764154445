import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IEmployeeList {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  id: string;
  employeeList: [];
  page?: null;
  limit?: null;
  search_text?: string;
  start_date?: string;
  end_date?: string;
  region?: string
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  employeeList: []
};

export const employeelist: any = createAsyncThunk(
  "EmployeeListSlice",
  async ({ page, limit, search_text, start_date, end_date, region }: IEmployeeList) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.employees?.employeeList}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}${start_date !== 'Invalid date' && start_date !== undefined && end_date !== 'Invalid date' && end_date !== undefined ? `&start_date=${start_date}` : ''}${end_date !== 'Invalid date' && end_date !== undefined && start_date !== 'Invalid date' && start_date !== undefined ? `&end_date=${end_date}` : ''}${region ? `&region=${region}` : ''}`)
    return response
  }
);

export const EmployeeListSlice = createSlice({
  name: "EmployeeListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(employeelist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(employeelist.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(employeelist.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const employeeListSliceReducer = EmployeeListSlice.reducer;
