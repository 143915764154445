import React, { useState, useRef, useCallback, useEffect } from "react";
import {
    GoogleMap,
    LoadScriptNext,
    Marker,
    Circle,
    InfoWindow,
} from "@react-google-maps/api";
import { ClipLoader } from "react-spinners";
import currentLocation from "../../assets/images/core/current-location.png";

const containerStyle = {
    width: "100%",
    height: "100%",
};

interface MyComponentProps {
    UserLatitude: string;
    UserLongitude: string;
    UserRadius: number;
    otherCircleData: CircleData[];
    userID: number;
}

interface CircleData {
    id?: number;
    lat?: any;
    long?: any;
    first_name?: string;
    last_name?: string;
    radius?: number;
}

const EmployeeInfoMap = ({ data, UserRadius }: any) => {

    const otherCenter = {
        lat: parseFloat(data.lat),
        lng: parseFloat(data.long),
    };
    const otherRadiusInMeters: number = data.selectedRegion ? UserRadius * 1609.34 : 10 * 1609.34;
    const [isShowInfo, setIsShowInfo] = useState<boolean>(true);

    const handleMarkerClick = (id: number, position: { lat: number; lng: number }) => {
        setIsShowInfo(true);
    };

    return (
        <React.Fragment key={data.id}>
            <Marker
                position={otherCenter}
                onClick={() => handleMarkerClick(data.id, otherCenter)}
            />
            <Circle
                center={otherCenter}
                radius={otherRadiusInMeters}
                options={{
                    strokeColor: data.selectedRegion ? "blue" : "gray",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: data.selectedRegion ? "blue" : "gray",
                    fillOpacity: 0.35,
                }}
            />
            {isShowInfo && data.first_name && data.last_name && (
                <InfoWindow
                    position={otherCenter}
                    onCloseClick={() => setIsShowInfo(false)}
                    options={{
                        disableAutoPan: true
                    }}
                >
                    <div
                        style={{
                            color: "black",
                            backgroundColor: "white",
                            padding: "1px",
                            borderRadius: "5px",
                        }}
                        tabIndex={-1}
                    >
                        {data.first_name && data.first_name} {data.last_name && data.last_name}
                    </div>
                </InfoWindow>
            )}
        </React.Fragment>
    );
};

const MyComponent: React.FC<MyComponentProps> = ({ UserLatitude, UserLongitude, UserRadius, otherCircleData, userID }) => {
    const [loading, setLoading] = useState(true);
    const [mapData, setMapData] = useState<CircleData[]>([]);
    const mapRef = useRef<google.maps.Map | null>(null);
    const [totalUserCount, setTotalUserCount]: any = useState()

    useEffect(() => {
        let tempMapData: any = [...mapData];
        tempMapData = tempMapData.map((item: any) => ({
            ...item,
            selectedRegion: false
        }))

        if (totalUserCount?.length == mapData?.length) {
            tempMapData.push({ lat: UserLatitude, long: UserLongitude, selectedRegion: true })
        } else {
            tempMapData = tempMapData.filter((item: any) => item.id !== userID)
            tempMapData.splice(totalUserCount, 1, { lat: UserLatitude, long: UserLongitude, selectedRegion: true })
        }

        setMapData(tempMapData)
    }, [UserLatitude, UserLongitude])


    const center = {
        lat: parseFloat(UserLatitude),
        lng: parseFloat(UserLongitude),
    };

    const onMapLoad = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
        setLoading(false);
    }, []);

    const recenterMap = () => {
        if (mapRef.current) {
            const newCenter = { lat: parseFloat(UserLatitude), lng: parseFloat(UserLongitude) };
            mapRef.current.panTo(newCenter);
            mapRef.current.setZoom(10);
        }
    };

    useEffect(() => {
        const result: any = otherCircleData
            .filter(item => item.lat && item.long) // Ensure lat and long are valid
            .map(({ id, lat, long, first_name, last_name, radius }: CircleData) => ({
                id,
                lat: parseFloat(lat),
                long: parseFloat(long),
                first_name,
                last_name,
                radius,
                selectedRegion: id == userID
            }));
        setTotalUserCount(result?.length)
        setMapData(result);
    }, [otherCircleData]);


    return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <button onClick={recenterMap} className="absolute right-[10px] bottom-[200px] z-[2]">
                <img src={currentLocation} className="w-[40px] h-[40px]" alt="Get_Current_Location" />
            </button>
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <ClipLoader color="#f27366" size={50} />
                </div>
            )}
            <LoadScriptNext
                libraries={["places"]}
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY as string}
                loadingElement={<div style={{ height: "100%" }}></div>}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={onMapLoad}
                >
                    {mapData?.map((data) => (
                        <EmployeeInfoMap key={data.id} data={data} userID={userID} UserRadius={UserRadius ?? 10} UserLatitude={UserLatitude} UserLongitude={UserLongitude} />
                    ))}
                </GoogleMap>
            </LoadScriptNext>
        </div>
    );
};

export default React.memo(MyComponent);
