import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import avatar from '../../assets/images/core/one-home-solution-logo.png'
import { clearLocalStorage, getLocalStorage, removeLocalStorage, setLocalStorage } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import logo from '../../assets/images/core/OHSAdmin.png'
import { increment } from "../../feature/Sidebar/SidebarCollapse-Slice";
import { useForm, SubmitHandler } from "react-hook-form";
import { changepassword } from "../../feature/Authentications/ChangePassword-Slice";
import { ClipLoader } from "react-spinners";
import { userdetails } from "../../feature/Users/UserDetails-Slice";
import { updateAdminProfile } from "../../feature/UpdateProfile/UpdateProfile-Slice";
import Cookies from 'js-cookie';

interface IFormInputs {
  old_password?: string;
  new_password?: string;
  first_name?: string;
  last_name?: string;
  email?: string
}


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Header = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const signOut = () => {
    Cookies.remove('loggedIn');
    clearLocalStorage()
    navigate("/")
  }

  const loginUser = useSelector((state: any) => state.login)

  const sidebarCollap = () => {
    dispatch(increment())
  }
  const [showDeleteConfirmation, setShowDeleteConfirmation]: any = useState(false);
  const [showUpdateProfile, setShowUpdateProfile]: any = useState(false);


  const changePassword = () => {
    setShowDeleteConfirmation(true);
  }

  const updateProfile = () => {
    setShowUpdateProfile(true)
  }

  const [userDetail, setUserDetail] = useState<IFormInputs>({
    old_password: '',
    new_password: '',
  })

  const [adminDetails, setAdminDetails] = useState<IFormInputs>({
    first_name: '',
    last_name: '',
    email: ''
  })

  const userId = getLocalStorage('user_id')

  const [showPassword, setShowPassword] = useState("password");
  const [showNewPassword, setNewShowPassword] = useState("password");
  const [passwordMatchError, setPasswordMatchError] = useState<string | null>(null);
  const [adminEmail, setAdminEmail] = useState('')
  const [isEmailChanged, setIsEmailChanged] = useState(false)


  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,

  } = useForm<IFormInputs>();

  //Submit event for edit user.
  const onSubmit: SubmitHandler<IFormInputs> = async () => {
    const isValid = await trigger();
    if (isValid) {
      dispatch(changepassword({ data: userDetail, userId })).then((data: any) => {
        if (data?.meta?.requestStatus == 'fulfilled') {
          setTimeout(() => {
            navigate('/dashboard')
          }, 1000)
          setShowDeleteConfirmation(false)
        }
      }
      )
    }
  };

  // Submit event for update profile

  const onUpdateProfile: SubmitHandler<IFormInputs> = async () => {
    const isValid = await trigger();
    if (isValid) {
      if (adminEmail !== adminDetails?.email) {
        setIsEmailChanged(true)
      }
      if (adminEmail == adminDetails?.email) {
        dispatch(updateAdminProfile({ data: adminDetails, userId })).then((data: any) => {
          if (data?.meta?.requestStatus == 'fulfilled') {
            setLocalStorage('first_name', data?.payload?.fisrt_name);
            setLocalStorage('last_name', data?.payload?.last_name);

            setTimeout(() => {
              navigate('/dashboard')
            }, 1000)
            setShowUpdateProfile(false)
          }
        }
        )
      }
    }
  };


  // Submit event for changing the email

  const updateEmail: SubmitHandler<IFormInputs> = async () => {
    const isValid = await trigger();
    if (isValid) {
      dispatch(updateAdminProfile({ data: adminDetails, userId })).then((data: any) => {
        if (data?.meta?.requestStatus == 'fulfilled') {
          setTimeout(() => {
            clearLocalStorage()
            navigate('/')
          }, 1000)
          setShowUpdateProfile(false)
          setIsEmailChanged(false)
        }
      }
      )
    }
  }


  useEffect(() => {
    // Reset the values of old_password and new_password whenever the modal is shown
    if (showDeleteConfirmation) {
      setUserDetail({
        old_password: '',
        new_password: '',
      });
      setPasswordMatchError('')
      reset();
    }
  }, [showDeleteConfirmation]);

  useEffect(() => {
    if (showDeleteConfirmation) {
      document.body.style.overflow = 'hidden';
    } else if (!showDeleteConfirmation) {
      document.body.style.overflow = 'auto';

    }
  }, [showDeleteConfirmation])

  const id = getLocalStorage('user_id')


  useEffect(() => {

    if (showUpdateProfile) {
      setAdminDetails({
        first_name: '',
        last_name: '',
        email: ''
      });

      dispatch(userdetails({ id })).then((data: any) => {
        setAdminDetails({ ...adminDetails, first_name: data?.payload?.data?.data?.user?.first_name, last_name: data?.payload?.data?.data?.user?.last_name, email: data?.payload?.data?.data?.user?.email }); setAdminEmail(data?.payload?.data?.data?.user?.email)

      })
      setPasswordMatchError('')
      reset();
    }
  }, [showUpdateProfile])

  const { isLoading } = useSelector((state: any) => state.changepassword)
  const updateProf = useSelector((state: any) => state.updateAdminprofile)


  showUpdateProfile
    ? (document.body.style.overflow = 'hidden')
    : (document.body.style.overflow = 'auto')
  return (

    <div className="flex items-center justify-end fixed header-wrapper right-0 top-0  lg:justify-between bg-white px-4 py-2 h-[65px] w-full">
      <div className='menu hidden lg:inline-block'>
        <svg onClick={() => sidebarCollap()} stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
      </div>
      <div className="logo truncate h-16 flex items-center mr-auto">
        <img className='cursor-pointer' src={logo} alt="Logo" width='136px' onClick={() => { navigate("/dashboard") }} />
      </div>


      <div className="flex items-center">
        <h5 className="mr-[15px]">{getLocalStorage('first_name')}&nbsp;{getLocalStorage('last_name')}</h5>
        <div className="relative inline-block mx-4">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex justify-center items-center w-full text-sm font-medium bg-white rounded-md focus:outline-none">
              <img className="rounded-[50%]" src={avatar} alt="Avatar" width={35} />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 ml-1 -mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 right-0 w-56 origin-top-right bg-white rounded-md border border-solid border-gray shadow-lg focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="submit"
                        onClick={() => changePassword()}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm cursor-pointer transition ease-in-out duration-300 hover:text-logo"
                        )}
                      >
                        Change Password
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="submit"
                        onClick={() => updateProfile()}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm cursor-pointer transition ease-in-out duration-300 hover:text-logo"
                        )}
                      >
                        Update Profile
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="submit"
                        onClick={signOut}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm cursor-pointer transition ease-in-out duration-300 hover:text-logo"
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {showDeleteConfirmation &&
        <>
          <div className='modal-wrapper absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center h-[948px]' onClick={() => setShowDeleteConfirmation(false)}>
            <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
              <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></button>
              <div className="content p-10 ">
                <h2 className="text-center font-semibold text-xl mb-6">Change Password</h2>
                <div className="flex flex-col w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full max-w-full"
                  >
                    <div className="">
                      <div className="px-3 relative">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Old Password
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("old_password", { required: 'Old password is required.' })}
                          className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white pr-[38px]"
                          id="grid-first-name"
                          type={showPassword}
                          placeholder="Old Password"
                          value={userDetail?.old_password}
                          onChange={(e) => {

                            setUserDetail((prevUserDetail) => ({
                              ...prevUserDetail,
                              old_password: e.target.value,
                            }))
                            setValue("old_password", e.target.value, { shouldValidate: true })
                          }
                          }
                        />
                        <p className="text-red text-sm absolute">
                          {errors?.old_password?.message}
                        </p>

                        <div className="right-[19px] bottom-[4px] cursor-pointer absolute inline">
                          {showPassword === "password" && (
                            <button
                              onClick={() => {
                                setShowPassword("text");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                            </button>
                          )}

                          {showPassword === "text" && (
                            <button
                              onClick={() => {
                                setShowPassword("password");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                        {passwordMatchError && (
                          <p className="text-red text-sm absolute mt-2">{passwordMatchError}</p>
                        )}
                      </div>
                      <div className="px-3 relative mt-10" >
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          New Password
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("new_password", {
                            required: 'New password is required.',
                            validate: (value) => value !== watch("old_password") || "Current password and New password cannot be the same.",
                            minLength: {
                              value: 8,
                              message: 'New password must be at least 8 characters long',
                            },
                            pattern: {
                              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                              message: 'Password must contain at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character',
                            }
                          })}
                          className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white pr-[38px]"
                          id="grid-last-name"
                          type={showNewPassword}
                          placeholder="New Password"
                          value={userDetail?.new_password}
                          onChange={(e) => {

                            setUserDetail((prevUserDetail) => ({
                              ...prevUserDetail,
                              new_password: e.target.value,
                            }))
                            setValue("new_password", e.target.value, { shouldValidate: true })

                          }
                          }
                        />
                        <p className="text-red text-sm absolute">
                          {errors?.new_password?.message}
                        </p>
                        <div className="right-[19px] bottom-[4px] cursor-pointer absolute inline">
                          {showNewPassword === "password" && (
                            <button
                              onClick={() => {
                                setNewShowPassword("text");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                            </button>
                          )}

                          {showNewPassword === "text" && (
                            <button
                              onClick={() => {
                                setNewShowPassword("password");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex mx-3 px-3 mt-[60px] justify-center">
                      <button
                        className="px-4 py-2 bg-red text-white rounded-md text-sm"
                        onClick={() => {
                          setShowDeleteConfirmation(false)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                        disabled={isLoading}
                      >
                        {isLoading &&

                          <ClipLoader color="#FFFFFF" size={20} />
                        }
                        {
                          !isLoading &&
                          (
                            <>
                              Submit
                            </>
                          )
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>}

      {/**
       Update Profile Popup
     */}

      {showUpdateProfile &&
        <>
          <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setShowUpdateProfile(false)}>
            <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
              <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowUpdateProfile(false)} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></button>
              <div className="content p-10">
                <h2 className="text-center font-semibold text-xl mb-6">Update Profile</h2>
                <div className="flex flex-col w-full">
                  <form
                    onSubmit={handleSubmit(onUpdateProfile)}
                    className="w-full max-w-full"
                  >
                    <div className="w-[380px]">
                      <div className="px-3 relative">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          First Name
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("first_name", { required: adminDetails?.first_name == '' ? 'First Name is required.' : '' })}
                          className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white pr-[38px]"
                          id="grid-first-name"
                          placeholder="First Name"
                          value={adminDetails?.first_name}
                          onChange={(e) => {

                            setAdminDetails((prevUserDetail) => ({
                              ...prevUserDetail,
                              first_name: e.target.value,
                            }))
                            setValue("first_name", e.target.value, { shouldValidate: true })
                          }
                          }
                        />
                        <p className="text-red text-sm absolute">
                          {errors?.first_name?.message}
                        </p>
                      </div>
                      <div className="px-3 relative mt-10" >
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Last Name
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("last_name", { required: adminDetails?.last_name == '' ? 'Last Name is required.' : '' })}
                          className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white pr-[38px]"
                          id="grid-first-name"
                          placeholder="Last Name"
                          value={adminDetails?.last_name}
                          onChange={(e) => {

                            setAdminDetails((prevUserDetail) => ({
                              ...prevUserDetail,
                              last_name: e.target.value,
                            }))
                            setValue("last_name", e.target.value, { shouldValidate: true })
                          }
                          }
                        />
                        <p className="text-red text-sm absolute">
                          {errors?.last_name?.message}
                        </p>
                      </div>
                      <div className="flex items-center  px-3 relative mt-10" >
                        <label
                          className="block uppercase tracking-wide text-xs font-bold "
                          htmlFor="grid-first-name"
                        >
                          Email :
                        </label>
                        <p className="font-normal pl-2" > {adminDetails?.email}</p>
                      </div>
                    </div>
                    <div className="flex mx-3 px-3 mt-[60px] justify-center">
                      <button
                        className="px-4 py-2 bg-red text-white rounded-md text-sm"
                        onClick={() => {
                          setShowUpdateProfile(false)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                        disabled={updateProf?.isLoading}
                      >
                        {updateProf?.isLoading && isEmailChanged == false &&

                          <ClipLoader color="#FFFFFF" size={20} />
                        }
                        {
                          !updateProf?.isLoading || isEmailChanged ?
                            (
                              <>
                                Submit
                              </>
                            ) : ''
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>}


      {isEmailChanged &&
        <>
          <div className='modal-wrapper absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center h-[948px]' onClick={() => setIsEmailChanged(false)}>
            <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
              <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setIsEmailChanged(false)} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></button>
              <div className="content p-10 ">
                <h2 className="text-center font-semibold text-xl mb-6">Update Profile</h2>
                <div className="flex flex-col w-full">
                  <form
                    onSubmit={handleSubmit(updateEmail)}
                    className="w-full max-w-full"
                  >
                    <div className="">
                      <div className="px-3 relative">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          By changing Email you will be loged out.
                        </label>
                      </div>
                    </div>
                    <div className="flex mx-3 px-3 mt-[60px] justify-center">
                      <button
                        className="px-4 py-2 bg-red text-white rounded-md text-sm"
                        onClick={() => {
                          setIsEmailChanged(false)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                        disabled={updateProf?.isLoading}
                      >
                        {updateProf?.isLoading &&

                          <ClipLoader color="#FFFFFF" size={20} />
                        }
                        {
                          !updateProf?.isLoading &&
                          (
                            <>
                              Submit
                            </>
                          )
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>}
    </div >
  )
}

export default Header