
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IViewJob {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  id: number;
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: []
};

export const viewJob: any = createAsyncThunk(
  "ViewJobSlice",
  async ({ id }: IViewJob) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.jobs.viewJob}/${id}`)
    return response
  }
);

export const ViewJobSlice = createSlice({
  name: "ViewJobSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewJob.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewJob.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(viewJob.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const viewJobSliceReducer = ViewJobSlice.reducer;
