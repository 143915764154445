import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../feature/Authentications/Login-Slice";
import { clearLocalStorage, getLocalStorage } from "../../utils/utils";
import logo from "../../assets/images/core/OH-Logo.png";
import { ClipLoader } from "react-spinners";

interface IFormInputs {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tokenData = getLocalStorage('accessToken');
    if (tokenData && tokenData !== '') {
      navigate('/dashboard')
    } else {
      clearLocalStorage()
    }
  }, [])

  const [showPassword, setShowPassword] = useState("password");
  const [email, setEmail]: any = useState('');

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<IFormInputs>();

  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    dispatch(login({ data })).then((data: any) => {
      if (data?.payload?.user?.roles == 'SUPER_ADMIN' || data?.payload?.user?.roles == 'ADMIN_USER') {
        navigate("/dashboard");
      } else {
        navigate('/')
      }
    });
  };

  // Disable form submission on enter key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const { isLoading } = useSelector((state: any) => state.login)

  return (
    <div
      className="flex flex-col bg-white border-darkGray border-solid border p-8 w-full max-w-lg"
      style={{ borderRadius: "30px" }}
    >
      <div className="logo flex items-center justify-center pb-5">
        <img src={logo} alt="Logo" width={125} height={40} />
      </div>
      <div className="flex flex-col w-full mb-4">
        <div className="text-lg leading-6 pt-2 font-bold">Login</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-y-1 gap-x-2 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <div className="space-y-1">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium whitespace-nowrap"
                >
                  Email
                </label>
                <input
                  {...register("email", {
                    required: 'Email is required',
                    // eslint-disable-next-line no-useless-escape
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  })}
                  name="email"
                  placeholder="Email"
                  type="email"
                  id="email"
                  className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                  onChange={(e: any) => { setEmail(e?.target?.value); setValue("email", e.target.value, { shouldValidate: true }) }}
                />
                <p className="text-red text-sm">
                  {errors?.email?.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-12">
              <div className="space-y-1 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium whitespace-nowrap"
                >
                  Password
                </label>
                <input
                  {...register("password", { required: true })}
                  name="password"
                  placeholder="Password"
                  type={showPassword}
                  id="password"
                  className="form-input block w-full border-darkGray border border-solid bg-white outline-0 flex-grow-1 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                  onKeyDownCapture={handleKeyPress}
                />

                <div className="absolute right-[11px] top-[25px] cursor-pointer">
                  {showPassword === "password" && (
                    <button
                      onClick={() => {
                        setShowPassword("text");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    </button>
                  )}

                  {showPassword === "text" && (
                    <button
                      onClick={() => {
                        setShowPassword("password");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <p className="text-red text-sm">
                  {errors.password && "Password is required"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center space-x-2">
          <button
            disabled={isLoading}
            type="submit"
            className=" inline-flex justify-center px-3 py-2 text-sm font-medium text-white bg-logo border border-transparent shadow-sm rounded-md focus:outline-none w-[64px]"
          >
            {isLoading &&

              <ClipLoader color="#FFFFFF" size={20} />
            }
            {
              !isLoading &&
              (
                <>
                  Login
                </>
              )
            }
          </button>
          <div className="">
            <Link className="text-blue text-sm" to="/forgot-password">
              Forgot password?
            </Link>
          </div>
        </div>

      </form>

    </div>
  );
};

export default Login;
