import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import { advertisedetails } from "../../feature/Advertisement/AdvertisementDetails-Slice";
import { editAdvertisement } from "../../feature/Advertisement/EditAdvertisement-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { industrylist } from "../../feature/Taxonomy/IndustryList-Slice";
import { adminindustryCategorylist } from "../../feature/Taxonomy/AdminIndustryCategory-Slice";
import { adminindustrySubCategorylist } from "../../feature/Taxonomy/AdminIndustrySub-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

interface IFormInputs {
    banner_title?: string;
    request_title?: string;
    banner_image?: any;
    banner_link?: string;
    display_order?: any;
    is_active?: any;
    service_id?: any;
    service_industry_data?: any;
    service_category_id?: any;
    categoryData?: any;
    catName?: any;
    subCategoryData?: any;
    service_industry_id?: any;
    service_sub_category_id?: any;
    subCatData?: any

}

const EditAdvertisement = () => {
    const [advertisementId, setAdvertisementId] = useState('')
    const [image, setImage]: any = useState();
    const [industryName, setIndustryName] = useState('')
    const [advertiseDetail, setAdvertiseDetail] = useState<IFormInputs>({
        banner_title: '',
        request_title: '',
        banner_image: '',
        banner_link: '',
        display_order: 0,
        is_active: false,
        service_id: '',
        service_category_id: '',
        service_sub_category_id: '',
        service_industry_data: {
            industry_name: '',
            industry_id: ''
        },
        service_industry_id: ''
    })
    const [errorMessage, setErrorMessage] = useState(false)
    const [serviceData, setServiceData]: any = useState([])
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [categoryNameList, setCategoryNameList] = useState<any>([])
    const [subCategoryNameList, setSubCategoryNameList] = useState<any>([])
    const [subCategoryData, setSubCategoryData]: any = useState()
    const [subCatData, setSubCatData]: any = useState('')
    const [displayName, setDisplayName] = useState('')
    const [CatDislayName, setCatDisplayName] = useState('')
    const [subCatdisplayName, setSubCatDisplayName] = useState('')
    const [fileExtensionMessage, setFileExtensionMessage] = useState(false)
    const [regionUtahId, setRegionUtahId] = useState()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue } = useForm<IFormInputs>();

    // To get the data of the user from the api.
    useEffect(() => {
        const path = window.location.pathname
        const id = path.split('/')[2]
        setAdvertisementId(id)
        dispatch(advertisedetails({ id })).then((data: any) => { setAdvertiseDetail(data?.payload?.data?.data); setIndustryName(data?.payload?.data?.data?.service_industry_data?.industry_name) })

    }, [window.location.pathname]);



    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        if (errorMessage || fileExtensionMessage) {
            return false;
        }
        const formData = new FormData();
        formData.append("banner_title", advertiseDetail?.banner_title as string);
        formData.append("request_title", advertiseDetail?.request_title as string);
        formData.append("banner_link", advertiseDetail?.banner_link as string);
        formData.append("display_order", advertiseDetail?.display_order);
        formData.append("service_industry_id", advertiseDetail?.service_industry_id)
        formData.append("service_category_id", advertiseDetail?.service_category_id as string)
        formData.append("service_sub_category_id", advertiseDetail?.service_sub_category_id as string)


        if (image) {
            formData.append("file", image);
        }
        formData.append("is_active", advertiseDetail?.is_active)
        // if (advertiseDetail?.service_id) {
        //     formData.append("service_id", advertiseDetail?.service_id)

        // }
        dispatch(editAdvertisement({ formData, advertisementId })).then(
            (data: any) => {
                if (data?.meta?.requestStatus === "fulfilled") {
                    setTimeout(() => {
                        navigate("/advertisement");
                    }, 1000);
                }
            }
        );
    };

    const { isLoading } = useSelector((state: any) => state?.advertiseDetail)
    const isAdvertiseEdit = useSelector((state: any) => state?.editAdvertise)


    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        const fileSizeKb = file.size / 1024
        if (file && fileSizeKb > 1024) {
            setErrorMessage(true)
            return;
        }
        const fileType = file.type;
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (allowedTypes.includes(fileType)) {
            setImage(file);
            setValue("banner_image", e.target.value)
            setErrorMessage(false)
            setFileExtensionMessage(false)

        } else {
            setFileExtensionMessage(true)
            return

        }


    };

    // To list down all the service drop-down
    useEffect(() => {
        dispatch(industrylist()).then((data: any) => setServiceData(data?.payload?.data?.data)
        )
    }, [])


    useEffect(() => {
        // if (SubCateId) {
        const CateName = advertiseDetail?.service_industry_data?.categories?.find((val: any) => val?.category_id == advertiseDetail?.service_category_id)?.category_id
        setCatDisplayName(CateName)

        const SubCateId = advertiseDetail?.service_industry_data?.categories?.find((val: any) => val?.category_id == CateName)?.sub_categories
        const SubCateName: any = SubCateId?.find((val: any) => val?.sub_category_id == advertiseDetail?.service_sub_category_id)?.sub_category_id
        setSubCatDisplayName(SubCateName)

        const DisplayName = subCategoryNameList?.find((val: any) => val?.sub_category_id == (SubCateName ?? subCatData))?.display_name
        setDisplayName(DisplayName)
        // }
    }, [subCategoryData, subCatData, subCategoryNameList])

    useEffect(() => {
        if (advertiseDetail?.service_id !== '' && regionUtahId) {
            dispatch(adminindustryCategorylist({ id: advertiseDetail?.service_industry_id, page: 1, limit: 500, filterStatus: regionUtahId })).then((data: any) => {
                setCategoryNameList(data?.payload?.data?.categories)
            })
        }

    }, [advertiseDetail?.service_industry_id, regionUtahId])

    useEffect(() => {
        if (advertiseDetail?.service_id !== '' && advertiseDetail?.service_category_id !== '' && regionUtahId) {
            dispatch(adminindustrySubCategorylist({ id: advertiseDetail?.service_category_id, page: 1, limit: 500, filterStatus: regionUtahId })).then((data: any) => {
                setSubCategoryNameList(data?.payload?.data?.data)
            })
        }
    }, [advertiseDetail?.service_category_id, regionUtahId])

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => {
            const temp = val?.payload?.data?.data.find((val: any) => val?.region == 'UT').id
            setRegionUtahId(temp)
        })
    }, [])

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={"Advertisement Details"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('advertisement')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/advertisement']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Banner title
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("banner_title", { required: advertiseDetail?.banner_title == '' ? true : false })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="text"
                                                    placeholder="Banner title"
                                                    value={advertiseDetail?.banner_title}
                                                    onChange={(e) => {
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            banner_title: e.target.value,
                                                        }))
                                                        setValue("banner_title", e.target.value)
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {advertiseDetail?.banner_title == '' && "Banner title is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-last-name"
                                                >
                                                    Request title
                                                </label>
                                                <input
                                                    {...register("request_title", {
                                                    })}
                                                    className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-last-name"
                                                    type="text"
                                                    placeholder="Request title"
                                                    value={advertiseDetail?.request_title}
                                                    onChange={(e) =>
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            request_title: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">

                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Banner link
                                                </label>
                                                <input
                                                    {...register("banner_link")}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="grid-phone"
                                                    type="text"
                                                    placeholder="Banner Link"
                                                    value={advertiseDetail?.banner_link}
                                                    onChange={(e) =>
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            banner_link: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Industry
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("service_id", {
                                                        })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        value={industryName}
                                                        id="grid-state"
                                                        onChange={(e: any) => {
                                                            const setValur: any = serviceData?.find((val: any) => val?.industry_name == e?.target?.value)?.industry_id
                                                            setAdvertiseDetail({ ...advertiseDetail, service_id: setValur, service_industry_id: setValur, service_category_id: '', service_sub_category_id: '' })
                                                            setDisplayName('')
                                                            setCatDisplayName('')
                                                            setSubCatData('')
                                                            setSubCatDisplayName('')
                                                            setIndustryName(e?.target?.value)
                                                            setValue("service_id", e.target.value, { shouldValidate: true });
                                                        }}
                                                    >
                                                        {serviceData.map((service: any) => {
                                                            return (
                                                                <option key={service?.industry_id} defaultValue={service?.industry_id} >
                                                                    {service?.industry_name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.service_id && (
                                                    <p className="text-red text-sm">
                                                        {errors.service_id.type === "required" && "Industry is required."}
                                                    </p>
                                                )}
                                            </div>
                                            {/* <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-phone"
                                                >
                                                    Display order
                                                </label>
                                                <input
                                                    {...register("display_order", {
                                                        maxLength: 10,
                                                    })}
                                                    onKeyDown={(evt) =>
                                                        evt.key === "e" && evt.preventDefault()
                                                    }
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="grid-phone"
                                                    type="number"
                                                    placeholder="Enter display order"
                                                    value={advertiseDetail?.display_order}
                                                    onChange={(e) =>
                                                        setAdvertiseDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            display_order: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div> */}
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3 relative">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Banner image
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <img src={`${process.env.REACT_APP_IMAGE_PATH}${advertiseDetail?.banner_image}`} alt="Banner" className="mb-2" style={{ width: '100px', maxHeight: '100px' }} />
                                                <div className="!border-darkGray block w-full  rounded py-3 px-4 mb-1 leading-tight focus:outline-none border border-solid  focus:bg-white cursor-no-drop">
                                                    <input
                                                        className="appearance-none hidden"
                                                        id="banner_image"
                                                        type="file"
                                                        name="file"
                                                        accept="image/jpg, image/png, image/jpeg"
                                                        onChange={(e: any) => {
                                                            setIsSubmitClicked(false);
                                                            handleImageChange(e)
                                                        }}
                                                    />
                                                    <label htmlFor="banner_image" className="button bg-darkGray border border-solid border-black px-2 mr-2">Choose file</label>

                                                    {image?.name}
                                                </div>
                                                <p className="text-red text-sm">
                                                    {isSubmitClicked && advertiseDetail?.banner_image === '' && 'Banner image is required'}
                                                    {fileExtensionMessage == true && 'Please select a JPG, JPEG or PNG image.'}
                                                    {errorMessage && "Image must be less than 1 MB."}
                                                </p>
                                                <pre className="text-lightGray text-[#A9A9A9] text-sm">
                                                    {`[Note : 1. The image format must be JPG, JPEG, or PNG.
             2. It's size must not exceed 1 MB.
             3. The required dimension is 350 x 400.]`}
                                                </pre>

                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Category
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("subCategoryData", { required: CatDislayName == '' || CatDislayName == null ? true : false })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-state"
                                                        value={subCategoryData ? subCategoryData : CatDislayName}
                                                        onChange={(e) => {
                                                            setSubCategoryData(e.target.value)
                                                            setAdvertiseDetail({ ...advertiseDetail, service_category_id: e?.target?.value })
                                                            setSubCatData('')
                                                            setSubCatDisplayName('')
                                                            setValue("subCategoryData", e.target.value, { shouldValidate: true });
                                                        }}
                                                    // placeholder="Select category" // Set the placeholder
                                                    >
                                                        <option value="" hidden>Select category</option>
                                                        <option value="" selected hidden>
                                                            Select category
                                                        </option>
                                                        {categoryNameList?.map((service: any, index: any) => {
                                                            const name: any = Object.values(service);
                                                            return (
                                                                <option key={index} defaultValue={CatDislayName} value={name[0]}>
                                                                    {name[1]}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.subCategoryData && (
                                                    <p className="text-red text-sm">
                                                        {errors.subCategoryData.type === "required" && "Category is required."}
                                                    </p>
                                                )}
                                            </div>

                                        </div>
                                        <div className="flex -mx-3 mb-2 relative">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-is-active"
                                                >
                                                    Status
                                                </label>
                                                <div className="flex items-center">
                                                    <label className="mr-2">
                                                        <input
                                                            type="radio"
                                                            checked={advertiseDetail?.is_active}
                                                            className="mr-1"
                                                            name="is_active"
                                                            onChange={(e) =>
                                                                setAdvertiseDetail((prevUserDetail) => ({
                                                                    ...prevUserDetail,
                                                                    is_active: true,
                                                                }))
                                                            }
                                                        />
                                                        Active
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            checked={!advertiseDetail?.is_active}
                                                            className="mr-1"
                                                            name="is_active"
                                                            onChange={(e) =>
                                                                setAdvertiseDetail((prevUserDetail) => ({
                                                                    ...prevUserDetail,
                                                                    is_active: false,
                                                                }))
                                                            }
                                                        />
                                                        In Active
                                                    </label>
                                                </div>
                                            </div>

                                            <div className={`w-1/2 px-3 mt-[-80px] absolute  right-0  top-[-61px]
                                             ${((isSubmitClicked && advertiseDetail?.banner_image === '') || (errorMessage)) ? 'top-[-10px]' : `[-60px]`}`}>
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Sub-Category
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("subCatData", { required: subCatdisplayName == "" || subCatdisplayName == null ? true : false })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        value={subCatData ? subCatData : subCatdisplayName}
                                                        id="grid-state"
                                                        onChange={(e: any) => {
                                                            setSubCatData(e?.target?.value)
                                                            setAdvertiseDetail({ ...advertiseDetail, service_sub_category_id: e?.target?.value })
                                                            setValue("subCatData", e.target.value, { shouldValidate: true });
                                                        }}
                                                    >
                                                        <option value="" selected hidden>
                                                            Select sub-category
                                                        </option>
                                                        {subCategoryNameList?.map((service: any, index: number) => {
                                                            const name: any = Object.values(service);
                                                            return (
                                                                <option key={index} defaultValue={subCatdisplayName} value={name[0]}>
                                                                    {name[1]}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.catName && (
                                                    <p className="text-red text-sm">
                                                        {errors.catName.type === "required" && "Sub-Category is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-end items-end -mx-3 mb-7 mt-[-38px] relative">
                                            <div className="w-1/2 px-3 absolute">
                                                <span>Taxonomy Display Name : {displayName ? displayName : '-'}</span>

                                            </div>
                                        </div>
                                        <div className="flex -mx-3 px-3 mt-5 justify-end">

                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm"
                                                onClick={() => {
                                                    navigate('/advertisement')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm ml-2 w-[81px] h-[36px] inline-block"
                                                onClick={() => setIsSubmitClicked(true)}
                                                disabled={isAdvertiseEdit?.isLoading}
                                            >
                                                {isAdvertiseEdit?.isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isAdvertiseEdit?.isLoading &&
                                                    (
                                                        <>
                                                            Submit
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditAdvertisement;

