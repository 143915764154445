
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    id?: string;
    display_order?: number;
}

interface ISortTaxonomy {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    serviceId: number;
    updatedSortOrder: IData;
    sort_type: string;
    filterStatus?: number
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const sortTaxonomy: any = createAsyncThunk(
    "SortTaxonomySlice",
    async ({ updatedSortOrder, sort_type }: ISortTaxonomy) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.taxonomy.sortTax}`, {
                sort_type,
                sort_data: updatedSortOrder,
            }).then((data: any) => {
                showToastSuccessMessage('Display order changed successfully.')
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const SortAdvSlice = createSlice({
    name: "SortTaxonomySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(sortTaxonomy.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(sortTaxonomy.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(sortTaxonomy.rejected, (state) => {
            state.isError = true;
            state.isSuccess = false;
        });
    },
});

export const sortTaxonomySliceReducer = SortAdvSlice.reducer;
