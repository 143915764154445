import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import {
    showToastErrorMessage,
    showToastSuccessMessage,
} from "../../utils/utils";

interface IResetPassword {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    data: {
        password: string;
    };
    token: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    password: "",
    token: ''
};

export const resetpassword: any = createAsyncThunk(
    "resetpasswordSlice",
    async ({ data, token }: IResetPassword) => {
        try {
            const response = await http.post(
                `${process.env.REACT_APP_BASE_URL}${apiconfig.authentication.resetPassword}`,
                {
                    password: data?.password,
                    resetToken: token
                }
            );
            if (response?.data?.statusCode === 200) {
                showToastSuccessMessage(response.data.message);
                return response.data;
            } else {
                throw new Error("Invalid response status");
            }
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message);
            throw error; // Re-throw the error to be caught by rejected case
        }
    }
);

export const ResetpasswordSlice = createSlice({
    name: "resetpasswordSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetpassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(resetpassword.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.password = payload.email;
        });
        builder.addCase(resetpassword.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const resetpasswordSliceReducer = ResetpasswordSlice.reducer;
