import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IUseDetails {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userId: string;
    data: {
        first_name?: string,
        last_name?: string,
        email?: string
    }
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const updateAdminProfile: any = createAsyncThunk(
    "UserAdminProfile",
    async ({ data, userId }: IUseDetails) => {
        try {

            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.updateProfile}`, {
                user_id: userId,
                first_name: data?.first_name,
                last_name: data?.last_name,
                email: data?.email

            }
            )
            if (response?.data?.statusCode === 200) {
                showToastSuccessMessage(response.data.message);
                return response.data
            } else {
                throw new Error("Invalid response status");
            }
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message);
            throw error; // Re-throw the error to be caught by rejected case
        }
    }
);

export const UpdateProfile = createSlice({
    name: "UserAdminProfile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateAdminProfile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateAdminProfile.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(updateAdminProfile.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const updateAdminProfileSliceReducer = UpdateProfile.reducer;
