import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeelist } from "../../feature/Employees/EmployeesList-Slice";
import Switch from "react-switch";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import { ClipLoader } from "react-spinners";
import { syncEmployeelist } from "../../feature/Employees/SyncEmployee-Slice";
import { employeeStatus } from "../../feature/Employees/EmployeeStatus-Slice";
import Pagination from "../../components/common/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "../../components/search/Search";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

let PageSize = 1;

const EmployeeListing = () => {

  const [searchResult, setSearchResult] = useState("");
  const [employeeList, setEmployeeList]: any = useState([]);
  const [currentPage, setCurrentPage]: any = useState();
  const [count, setCount] = useState(0)
  const [searchCount, setSearchCount] = useState(0)
  const [filterStatus, setFilterStatus]: any = useState('')
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate]: any = dateRange;
  const startFormatDate = moment(startDate).format('YYYY-MM-DD')
  const endFormatDate = moment(endDate).format('YYYY-MM-DD')
  const [hcpRegionList, setHCPRegionList] = useState([]);

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageParam: any = queryParams.get("page");
    const page = parseInt(pageParam, 10) || 1;
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    if (typeof currentPage !== 'undefined') {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("page", String(currentPage));
      window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
      navigate(`?${queryParams.toString()}`);
      employeeData();
    }

  }, [currentPage, navigate, searchResult, filterStatus]);

  const employeeData = () => {
    dispatch(employeelist({
      page: currentPage,
      limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
      search_text: searchResult,
      region: filterStatus,
      start_date: startFormatDate,
      end_date: endFormatDate
    })).then((val: any) => {
      setEmployeeList(val?.payload?.data?.data?.list);
      setCount(val?.payload?.data?.data?.totalRecords);
      setSearchCount(val?.payload?.data?.data?.count);
    })
  }
  const { isLoading } = useSelector((state: any) => state?.employeeList)

  const syncEmployeehandler = () => {
    dispatch(syncEmployeelist()).then((val: any) => {
      if (val?.meta?.requestStatus == "fulfilled") {
        setTimeout(() => {
          employeeData()
        }, 5000)
      }
    })
  }

  const handleToggle = (id: string) => {
    const updatedEmployeeList = employeeList.map((employee: any) => {
      if (employee.id === id) {
        const updatedEmployee = { ...employee, is_active: !employee.is_active };
        dispatch(employeeStatus({ id, updatedEmployee: updatedEmployee?.is_active }));
        return updatedEmployee;
      }
      return employee;
    });

    setEmployeeList(updatedEmployeeList);
  };

  useEffect(() => {
    dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
  }, [])

  const handleStateChange = (e: any) => {
    setFilterStatus(e.target.value)
    setCurrentPage(1)
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="content w-full">
          <Header />
          <div className="fluid p-4 custom-height-wrapper">
            <div className="pb-5">
              <Title title={`HCP Employee List`} />
            </div>
            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
              <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                  <div className="flex items-center justify-between w-full" >
                    <Search
                      setSearchResult={setSearchResult}
                      searchResult={searchResult}
                      currentPage={setCurrentPage}
                    />
                    <div className="relative items-center justify-end ml-auto">
                      <select
                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                        id="grid-state"
                        value={filterStatus}
                        onChange={handleStateChange}
                      >
                        <option value="" hidden>
                          Select Region
                        </option>
                        {hcpRegionList && hcpRegionList.length > 0 && (
                          hcpRegionList.map((state: any, index) => (
                            <option key={index} value={state.id} >
                              {state.name}
                            </option>
                          ))
                        )}
                        <option value="" hidden={filterStatus == ''} disabled={filterStatus !== filterStatus}>All</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                  </div>
                  <div
                    className="flex items-center sm:mt-4 sm:w-full sm:flex-row-reverse sm:justify-center">
                    <span className="icon inline-block mx-2 sm:ml-0" onClick={() => syncEmployeehandler()} title='Click here to sync'>
                      <svg
                        className="cursor-pointer hover:animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20px"
                        viewBox="0 0 24 24"
                        id="reload"
                      >
                        <path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className="overflow-x-auto overflow-custom-scroll  overflow-hidden">
                    <table className="w-full text-left table-auto">
                      <thead className="bg-logo text-white">
                        <tr>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[90px]">
                            Id
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[257px]">
                            First Name
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[230px]">
                            Last Name
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[582px]">
                            Email
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[582px]">
                            Skills
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[228px]">
                            Role
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[176px]">
                            Region
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[227px]">
                            Created at
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[582px]">
                            Status
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[250px]">
                            Manage Address
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                          </div>
                        ) : <>
                          {employeeList?.length ? employeeList.map((data: any, index: any) => {
                            const isGrayRow = index % 2 === 0;
                            return (
                              <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data?.id}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data?.first_name?.charAt(0)?.toUpperCase() + data?.first_name?.slice(1)}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data?.last_name?.charAt(0)?.toUpperCase() + data?.last_name?.slice(1)}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data?.email}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data && data?.tags?.length ? data?.tags.map((val: any, index: number) => (
                                    <span key={index}>
                                      {val
                                        .split(' ')
                                        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}
                                      {index < data?.tags?.length - 1 && ','}{' '}
                                      {index % 3 === 2 && <br />}
                                    </span>
                                  )) : '-'}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap capitalize">
                                  {data?.role}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data?.hrc_region}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  <Switch
                                    checked={data.is_active}
                                    onChange={() => handleToggle(data?.id)}
                                    onColor="#f27367"
                                    onHandleColor="#fff"
                                    handleDiameter={22}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={15}
                                    width={48}
                                    className="react-switch"
                                    id="material-switch"
                                  />
                                </td>
                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                  <div className="flex gap-x-2">
                                    <span className="flex items-center">
                                      <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                        onClick={() => { navigate(`/hcp-employee/${data.hcp_employee_id}`, { state: data }) }}
                                      >
                                        <button className="text-xs font-bold text-black uppercase" >
                                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='h-6 w-6'>
                                            <path d="M6 7H7M6 10H7M11 10H12M11 13H12M6 13H7M11 7H12M7 21V18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18V21H7ZM7 21H3V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H13.4C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V9M19.7 13.5C19.7 14.3284 19.0284 15 18.2 15C17.3716 15 16.7 14.3284 16.7 13.5C16.7 12.6716 17.3716 12 18.2 12C19.0284 12 19.7 12.6716 19.7 13.5ZM21.5 21V20.5C21.5 19.1193 20.3807 18 19 18H17.5C16.1193 18 15 19.1193 15 20.5V21H21.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <title>Manage Address</title>
                                          </svg>
                                        </button>
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                            :
                            <tr>
                              <td colSpan={9} className="justify-center px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                <div>No Data Found</div>
                              </td>
                            </tr>}
                        </>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center my-14">
              {
                employeeList && employeeList.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={count}
                  pageSize={PageSize}
                  onPageChange={setCurrentPage}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeListing;
