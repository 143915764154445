
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    service_title?: string;
    service_category?: string;
    display_order?: number;
    is_active?: boolean;
}

interface IEditService {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    serviceId: number;
    serviceDetail: IData;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const editService: any = createAsyncThunk(
    "EditServiceSlice",
    async ({ serviceId, serviceDetail }: IEditService) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.services.editService}/${serviceId}`, {
                service_title: serviceDetail?.service_title,
                service_category: serviceDetail?.service_category,
                display_order: serviceDetail?.display_order,
                is_active: serviceDetail?.is_active
            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const EditServiceSlice = createSlice({
    name: "EditServiceSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editService.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editService.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(editService.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const editServiceSliceReducer = EditServiceSlice.reducer;
