import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IEditEmployee {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    id: string;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const editEmployee: any = createAsyncThunk(
    "EditEmployeeSlice",
    async ({ id }: IEditEmployee) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.employees?.editEmployee}/${id}`)
        return response
    }
);

export const EditEmployeeSlice = createSlice({
    name: "EditEmployeeSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editEmployee.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editEmployee.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(editEmployee.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const editEmployeeSliceReducer = EditEmployeeSlice.reducer;
