import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IEmployeeStatus {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    id: string;
    updatedEmployee?: boolean
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const employeeStatus: any = createAsyncThunk(
    "EmployeeStatusSlice",
    async ({ id, updatedEmployee }: IEmployeeStatus) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig?.employees?.statusEmployee}/${id}`, {
                is_active: updatedEmployee
            }).then((data: any) => {

                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw new Error
        }

    }
);

export const EmployeeStatusSlice = createSlice({
    name: "EmployeeStatusSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(employeeStatus.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(employeeStatus.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(employeeStatus.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const employeeStatusSliceReducer = EmployeeStatusSlice.reducer;
