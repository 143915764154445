
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    region?: string;
    api_key?: string;
    status?: boolean;
    sg_email?: string;
    request_email?: string;
    signup_email?: string;
    estimation_email?: string;
}

interface IEditRegion {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    regionId: number;
    hcpRegionDetail: IData;
    newSignupEmail?: string;
    newRequestEmail?: string;
    newEstimateEmail?: string;
    newAddressEmail?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const editRegion: any = createAsyncThunk(
    "EditHCPRegionSlice",
    async ({ regionId, hcpRegionDetail, newRequestEmail, newSignupEmail, newEstimateEmail, newAddressEmail }: IEditRegion) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.hcpRegion.editHCPRegion}`, {
                region_id: regionId,
                region: hcpRegionDetail?.region,
                api_key: hcpRegionDetail?.api_key,
                status: hcpRegionDetail?.status,
                sg_email: hcpRegionDetail?.sg_email,
                request_email: newRequestEmail,
                signup_email: newSignupEmail,
                estimation_email: newEstimateEmail,
                address_request_mail: newAddressEmail

            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const EditRegionSlice = createSlice({
    name: "EditHCPRegionSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editRegion.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editRegion.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(editRegion.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const editRegionSliceReducer = EditRegionSlice.reducer;
