import logo from '../../assets/images/core/OH-Logo.png'

const Loader = () => {
  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center z-10 bg-white bg-opacity-90'>
      <img className="animate-bounce" src={logo} alt="Logo" width={125}/>
    </div >
  )
}

export default Loader