
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";


interface IBookCal {
    selectedDate?: any;
    regionId?: number
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const removeBookedSlots: any = createAsyncThunk(
    "RemoveBookSlotsSlice",
    async ({ selectedDate, regionId }: IBookCal) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.holidayList.removeSlots}?date=${selectedDate}&hcp_region_id=${regionId}`).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const RemoveBookSlotsSlice = createSlice({
    name: "RemoveBookSlotsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(removeBookedSlots.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(removeBookedSlots.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(removeBookedSlots.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const removeBookSlotsSliceReducer = RemoveBookSlotsSlice.reducer;
