import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { stateData } from "../../constants/StateData";
import { editUser } from "../../feature/Users/EditUser-Slice";
import { userdetails } from "../../feature/Users/UserDetails-Slice";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { changeUserPassword } from "../../feature/Users/ChangePasswordUser-Slice";
import { allZipcodelist } from "../../feature/Zipcode/AllZipcode-Slice";
import { appConfiglist } from "../../feature/AppConfig/AppConfigList-Slice";
import moment from "moment";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { showToastErrorMessage } from "../../utils/utils";

interface IFormInputs {
  first_name?: string;
  last_name?: string;
  membership_plan?: string;
  mobile?: any;
  zipcode?: string;
  city?: string;
  address?: any;
  address_line_1?: string;
  state?: string;
  email?: string;
  is_active?: boolean;
  is_ohs_onboard?: boolean;
  created_by?: string;
  updated_by?: string;
  sign_up_from?: string;
  user_email?: string;
  user_password?: string;
  user_confirm__password?: string;
  referral_code?: string;
  is_mailchimp_id?: boolean
}

const EditUser = () => {
  const [userId, setUserId] = useState('')
  const [userDetail, setUserDetail] = useState<IFormInputs>({
    first_name: '',
    last_name: '',
    membership_plan: '',
    mobile: 0,
    zipcode: '',
    city: '',
    address: '',
    address_line_1: '',
    state: '',
    email: '',
    is_active: false,
    is_ohs_onboard: false,
    created_by: '',
    updated_by: '',
    sign_up_from: '',
    user_email: '',
    user_password: '',
    user_confirm__password: '',
    referral_code: '',
    is_mailchimp_id: false
  })

  const [showDeleteConfirmation, setShowDeleteConfirmation]: any = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [showNewPassword, setNewShowPassword] = useState("password");
  const [passwordMatchError, setPasswordMatchError] = useState<string | null>(null);
  const [appConfigListData, setAppConfigListData]: any = useState([])
  const [membPrice, setMembPrice] = useState('')


  const [userCred, setUserCred] = useState<IFormInputs>({
    user_email: '',
    user_password: '',
    user_confirm__password: '',
  })

  const [isClicked, setIsClicked] = useState(false)
  const confirmPassRef = useRef<HTMLInputElement>()
  const [stateValue, setStateValue] = useState('')
  const [memPlan, setMemPlan] = useState('')
  const [zipcodeList, setZipcodeList]: any = useState([])
  const [selectedCoordLat, setSelectedCoordLat]: any = useState();
  const [selectedCoordLong, setSelectedCoordLong]: any = useState();
  const latStrng = selectedCoordLat?.toString()
  const longStrng = selectedCoordLong?.toString()
  const [defaultAddress, setDefaultAddress] = useState()
  const [defaultState, setDefaultState] = useState()
  const [temp, setTemp] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
  } = useForm<IFormInputs>();

  // To get the data of the user from the api.
  useEffect(() => {
    const path = window.location.pathname
    const id = path?.split('/')[2]
    setUserId(id)
    dispatch(userdetails({ id })).then((data: any) => { setUserDetail(data?.payload?.data?.data?.user); setStateValue(data?.payload?.data?.data?.user?.state); setMemPlan(data?.payload?.data?.data?.user?.membership_plan); setDefaultAddress(data?.payload?.data?.data?.user?.address); setDefaultState(data?.payload?.data?.data?.user?.state) })

  }, [window.location.pathname]);

  //Submit event for edit user.
  const onSubmit: SubmitHandler<IFormInputs> = async () => {

    if (userDetail.mobile.length !== 10) {
      return false
    }

    if (!googleAuto.current) {
      showToastErrorMessage('Address Line 1 is not exist for selected state.')
      return false
    }

    const isValid = await trigger();
    if (isValid) {
      dispatch(editUser({ userDetail, userId, membership_data, memPlan: memPlan ? memPlan : userDetail?.membership_plan, selectedCoordLat: latStrng, selectedCoordLong: longStrng })).then((data: any) => {
        if (data?.meta?.requestStatus == 'fulfilled') {
          setTimeout(() => {
            navigate(-1)
          }, 1000)
        }
      }
      )
    }
  };

  const { isLoading } = useSelector((state: any) => state?.userDetail)
  const isUserEdited = useSelector((state: any) => state?.editUser)
  const isPasswordChanged = useSelector((state: any) => state.changeUserPassword)

  useEffect(() => {
    if (confirmPassRef?.current?.focus()) {
      setIsClicked(true)
    }
  }, [confirmPassRef])

  useEffect(() => {
    // Reset the values of old_password and new_password whenever the modal is shown
    if (showDeleteConfirmation) {
      setUserCred({
        user_email: '',
        user_password: '',
        user_confirm__password: '',
      });
      setPasswordMatchError('')
      reset();
    }
  }, [showDeleteConfirmation]);

  const changePassword = () => {
    setShowDeleteConfirmation(true);
  }

  const changeUserPass = async () => {
    await trigger();
    if (errors?.user_password?.message || errors?.user_confirm__password?.message) {
      return false
    }
    if (userCred?.user_password && userCred?.user_confirm__password) {

      dispatch(changeUserPassword({ email: userDetail?.email, password: userCred?.user_password })).then((val: any) => {
        if (val?.meta?.requestStatus == 'fulfilled') {
          setShowDeleteConfirmation(false)
        }
      })
    }
  }

  const autoGeneratePassword = () => {
    reset();
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digitChars = '0123456789';
    const specialChars = '!@#$%^&*()_-+=<>?';

    const allChars = lowercaseChars + uppercaseChars + digitChars + specialChars;

    let password = '';
    password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
    password += digitChars[Math.floor(Math.random() * digitChars.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    for (let i = 4; i < 8; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the password to ensure randomness
    password = password.split('').sort(() => Math.random() - 0.5).join('');
    setUserCred((prevDetail) => {
      return {
        ...prevDetail,
        user_password: password,

      }
    })

    return password;
  }

  useEffect(() => {
    if (showDeleteConfirmation) {
      document.body.style.overflow = 'hidden';
    } else if (!showDeleteConfirmation) {
      document.body.style.overflow = 'auto';

    }
  }, [showDeleteConfirmation])


  useEffect(() => {
    const membershipInfo = appConfigListData?.find((el: any) => el.title === 'MembershipInfo');
    if (membershipInfo && membershipInfo?.info?.length) {
      const desiredMembership = membershipInfo?.info?.find((item: any) => item?.key?.charAt(0)?.toLowerCase() + item?.key?.slice(1) === memPlan);
      if (desiredMembership && desiredMembership.price) {
        setMembPrice(desiredMembership.price)
        const price = desiredMembership?.price;
      }
    }

  }, [memPlan])


  const getExpirationDate = (key: any) => {
    return key !== 'yearly'
      ? moment()
        .month(moment().get('month') + 1)
        .format('YYYY/MM/DD')
      : moment()
        .year(moment().get('year') + 1)
        .format('YYYY/MM/DD');
  };


  if (userDetail?.membership_plan !== memPlan) {
    var membership_data = {
      type: memPlan,
      name: memPlan,
      price: membPrice,
      purchase_date: moment().format('YYYY/MM/DD'),
      expiration_date: getExpirationDate(memPlan),
    };

  }

  useEffect(() => {
    AppConfigList();
  }, []);

  const AppConfigList = () => {
    dispatch(appConfiglist()).then((data: any) => { setAppConfigListData(data?.payload?.data?.data) }
    )
  }
  useEffect(() => {
    if (stateValue) {
      ZipcodeList();
    }
  }, [userDetail?.state]);

  const ZipcodeList = () => {
    dispatch(allZipcodelist({ stateCode: userDetail?.state })).then((data: any) => {
      setZipcodeList(data?.payload?.data?.data)

    })
  }


  const googleAuto = useRef<boolean>(true)
  const handlePlaceSelect = async (place?: any) => {
    setUserDetail((prevUserDetail) => ({
      ...prevUserDetail,
      address: place?.label,
    }))

    const terms = place?.value?.terms
    for (let i = 0; i < terms?.length; i++) {
      if (terms[i]?.value == userDetail.state) { googleAuto.current = true; break };
    }
    const temp = await geocodeByPlaceId(place?.value?.place_id);
    setSelectedCoordLat(temp?.[0]?.geometry?.location?.lat())
    setSelectedCoordLong(temp?.[0]?.geometry?.location?.lng())

  };

  showDeleteConfirmation
    ? (document.body.style.overflow = 'hidden')
    : (document.body.style.overflow = 'auto')

  return (
    <div className="flex">
      <Sidebar />
      <div className="content w-full">
        <Header />
        <div className="fluid p-4 custom-height-wrapper">
          <div className="flex justify-between items-center">
            <div>
              <Title title={"User Details"} />
              <Breadcrumb
                paths={[('dashboard'), ('users')]}
                className="text-sm mb-3"
                url={['/dashboard', '/users']}
              />
            </div>
            <div>
              <div className="flex justify-end mb-4">
                <button
                  type="button"
                  onClick={() => changePassword()}
                  className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue text-right"
                >
                  Change Password
                </button>
              </div>
            </div>

          </div>
          {isLoading ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <ClipLoader color="#f27366" size={50} />
            </div>
          ) : (
            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4 ">
              <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                <div className="flex flex-col w-full">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full max-w-full"
                  >
                    <div className="flex -mx-3 mb-2">
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          First Name
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("first_name", { required: userDetail?.first_name == '' ? true : false })}
                          className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                          placeholder="First Name"
                          value={userDetail?.first_name}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const alphaOnly = inputValue.replace(/[^a-zA-Z\s]/g, '');
                            setUserDetail((prevUserDetail) => ({
                              ...prevUserDetail,
                              first_name: alphaOnly,
                            }))
                            setValue("first_name", alphaOnly)
                          }
                          }
                        />
                        <p className="text-red text-sm">
                          {userDetail?.first_name == '' && "First name is required."}
                        </p>
                      </div>
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Last Name
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("last_name", { required: userDetail?.last_name == '' ? true : false })}
                          className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-last-name"
                          type="text"
                          placeholder="Last Name"
                          value={userDetail?.last_name}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const alphaOnly = inputValue.replace(/[^a-zA-Z\s]/g, '');
                            setUserDetail((prevUserDetail) => ({
                              ...prevUserDetail,
                              last_name: alphaOnly,
                            }))
                            setValue("last_name", alphaOnly)

                          }
                          }
                        />
                        <p className="text-red text-sm">
                          {userDetail?.last_name == '' && "Last name is required."}
                        </p>
                      </div>
                    </div>
                    <div className="flex -mx-3 mb-2">
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Email
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white cursor-no-drop custom-select"
                          id="grid-email"
                          type="email"
                          value={userDetail?.email}
                          disabled
                        />
                      </div>

                      <div className="w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="grid-state">
                          Membership Plan
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <div className="relative">
                          <select
                            {...register("membership_plan", { required: userDetail?.membership_plan == '' || userDetail?.membership_plan == null ? true : false })}
                            className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                            id="grid-state"
                            value={memPlan}
                            onChange={(e) => {
                              setMemPlan(e?.target?.value)
                              setValue("membership_plan", e.target.value, { shouldValidate: true });
                            }}
                          >
                            <option value="" hidden>
                              Select plan
                            </option>
                            {userDetail?.membership_plan == 'gold' &&
                              <option value="gold">Gold</option>
                            }
                            {userDetail?.membership_plan == 'platinum' &&
                              <option value="platinum">Platinum</option>
                            }
                            {userDetail?.membership_plan == 'diamond' &&
                              <option value="diamond">Diamond</option>
                            }
                            <option value="yearly">Yearly</option>
                            <option value="monthly">Monthly</option>
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                        {errors.membership_plan && (
                          <p className="text-red text-sm">
                            {errors.membership_plan.type === "required" && "Membership plan is required."}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex -mx-3 mb-2">
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-state"
                        >
                          State
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <div className="relative">
                          <select
                            {...register("state", { required: userDetail?.state == '' || userDetail?.state == null ? true : false })}
                            className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white cursor-no-drop custom-select"
                            id="grid-state"
                            value={userDetail?.state}
                            onChange={(e) => {
                              googleAuto.current = false
                              const abbreviation = e.target.value;
                              setUserDetail((prevUserDetail) => ({
                                ...prevUserDetail,
                                state: abbreviation,
                                zipcode: ''
                              }))
                              setValue("state", abbreviation, { shouldValidate: true })
                              setStateValue(e?.target?.value);
                              handlePlaceSelect(abbreviation)
                            }}
                            disabled
                            style={{ color: 'black' }}
                          >
                            <option value="" selected hidden>
                              Select state
                            </option>
                            {stateData?.map((state, index) => {
                              const abbreviation = Object.keys(state)[0];
                              const name = Object.values(state)[0];
                              return (
                                <option key={index} value={abbreviation}>
                                  {name}
                                </option>
                              );
                            })}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                        {errors.state && (
                          <p className="text-red text-sm">
                            {errors.state.type === "required" && "State is required."}
                          </p>
                        )}
                      </div>
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-zipcode"
                        >
                          Zipcode
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <div className="relative">
                          <select
                            {...register("zipcode", { required: userDetail.zipcode == '' ? true : false })}
                            className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white cursor-no-drop custom-select"
                            value={userDetail?.zipcode}
                            onChange={(e) => {
                              setUserDetail((prevUserDetail) => ({
                                ...prevUserDetail,
                                zipcode: e.target.value,
                              }))
                              setValue("zipcode", e.target.value, { shouldValidate: true })
                            }
                            }
                            disabled
                          >
                            {
                              zipcodeList == '' && stateValue !== '' &&
                              <option value="" selected hidden>
                                Select zipcode
                              </option>
                            }
                            {
                              userDetail.zipcode == '' &&
                              <option value="" selected hidden>
                                Select zipcode
                              </option>
                            }

                            {zipcodeList == '' && stateValue !== '' &&
                              <option value='' disabled >
                                Zipcode is not available for this state.
                              </option>
                            }
                            {zipcodeList?.map((state: any, index: any) => {
                              return (
                                <option key={index} value={state?.zipcode}>
                                  {state?.zipcode}
                                </option>
                              );
                            })}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                        {userDetail.zipcode == '' && (
                          <p className="text-red text-sm">
                            {"Zipcode is required."}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex -mx-3 mb-2">
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-phone"
                        >
                          Phone
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("mobile", {
                            // required: userDetail?.mobile == '' ? true : false,
                            // maxLength: 10,
                          })}
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                          id="grid-phone"
                          type="number"
                          placeholder="Enter phone number"
                          value={userDetail?.mobile}
                          onChange={(e) => {
                            setUserDetail((prevUserDetail) => ({
                              ...prevUserDetail,
                              mobile: e.target.value,
                            }))
                            setValue("mobile", e.target.value, { shouldValidate: true })
                          }
                          }
                        />
                        <p className="text-red text-sm">
                          {userDetail.mobile.length !== 10 && 'Phone number must be 10 digits.' || userDetail?.mobile == '' && "Phone is required."}
                        </p>
                      </div>
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-city"
                        >
                          City
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <div className="relative">
                          <input
                            {...register("city", { required: userDetail?.city ? false : true })}
                            className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white cursor-no-drop custom-select"
                            id="grid-city"
                            type="text"
                            placeholder="Enter city"
                            value={userDetail?.city}
                            onChange={(e) => {
                              setUserDetail((prevUserDetail) => ({
                                ...prevUserDetail,
                                city: e.target.value,
                              }))
                              setValue("city", e.target.value)
                            }
                            }
                            disabled
                          />
                        </div>
                        <p className="text-red text-sm">
                          {userDetail?.city == '' && 'City is required'}
                        </p>
                      </div>
                    </div>
                    <div className="flex -mx-3 mb-2">
                      <div className="w-1/2 px-3 address-select-wrapper">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-address-1"
                        >
                          Address Line 1
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <GooglePlacesAutocomplete
                          {...register("address", { required: userDetail?.address == ' ' ? true : false })}
                          apiKey={process.env.REACT_APP_GOOGLE_KEY}
                          selectProps={{
                            styles: {
                              input: (provided) => ({
                                ...provided,
                                color: 'black',
                              }),
                            },
                            isDisabled: true,
                            placeholder: userDetail?.address,
                            onChange: (value: any) => { googleAuto.current = false; handlePlaceSelect(value); setValue("address", value, { shouldValidate: true }) },
                          }}
                          autocompletionRequest={{
                            bounds: [
                              { lat: 34.0489, lng: 111.0937 }, // Utah bounds
                              { lat: 39.3210, lng: 111.0937 }, // Arizona bounds
                            ],
                            componentRestrictions: {
                              country: ['us'],
                            },
                            types: ['geocode'],
                            radius: 100000,
                          }}
                          debounce={300}
                        />
                        {userDetail.address == undefined && (
                          <p className="text-red text-sm">
                            {'Address Line 1 is required'}
                          </p>
                        )
                        }
                      </div>
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-address-2"
                        >
                          Address Line 2
                        </label>
                        <input
                          {...register("address_line_1", {})}
                          className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white cursor-no-drop custom-select"
                          id="grid-address-2"
                          type="text"
                          placeholder="Address Line 2"
                          value={userDetail?.address_line_1}
                          onChange={(e) =>
                            setUserDetail((prevUserDetail) => ({
                              ...prevUserDetail,
                              address_line_1: e.target.value,
                            }))
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="flex -mx-3 mb-2">
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-is-active"
                        >
                          Status
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <div className="flex items-center">
                          <label className="mr-2">
                            <input
                              type="radio"
                              checked={userDetail.is_active}
                              className="mr-1"
                              name="is_active"
                              onChange={(e) =>
                                setUserDetail((prevUserDetail) => ({
                                  ...prevUserDetail,
                                  is_active: true,
                                }))
                              }
                            />
                            Active
                          </label>
                          <label>
                            <input
                              type="radio"
                              checked={!userDetail.is_active}
                              className="mr-1"
                              name="is_active"
                              onChange={(e) =>
                                setUserDetail((prevUserDetail) => ({
                                  ...prevUserDetail,
                                  is_active: false,
                                }))
                              }
                            />
                            In Active
                          </label>
                        </div>
                        <div className="flex-col -mx-3 mb-2 mt-4">
                          <div className="flex justify-left items-center w-1/3 px-3">
                            <p className="block uppercase tracking-wide text-xs font-bold mb-2 whitespace-nowrap">Created By :</p>
                            <p className="block border mb-[5px] ml-[5px] whitespace-nowrap">{userDetail?.created_by ? userDetail?.created_by : '-'}</p>
                          </div>
                          <div className="flex justify-left items-center w-1/3 px-3">
                            <p
                              className="block uppercase tracking-wide text-xs font-bold mb-2 whitespace-nowrap"
                            >
                              Updated By :
                            </p>
                            <p className="border mb-[5px] ml-[5px] whitespace-nowrap">{userDetail?.updated_by ? userDetail?.updated_by : '-'}</p>
                          </div>
                          <div className="flex justify-left items-center w-1/3 px-3">
                            <p
                              className="block uppercase tracking-wide text-xs font-bold mb-2"
                            >
                              Sign up from :
                            </p>
                            <p className="border mb-[7px] ml-[5px]">{userDetail?.sign_up_from ? userDetail?.sign_up_from.charAt(0).toUpperCase() + userDetail?.sign_up_from.slice(1) : '-'}</p>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-is-active"
                        >
                          Onboard Status
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <div className="flex items-center">
                          <label className="mr-2">
                            <input
                              type="radio"
                              checked={userDetail?.is_ohs_onboard}
                              className="mr-1"
                              name="is_ohs_onboard"
                              onChange={(e) =>
                                setUserDetail((prevUserDetail) => ({
                                  ...prevUserDetail,
                                  is_ohs_onboard: true,
                                }))
                              }
                            />
                            Active
                          </label>
                          <label>
                            <input
                              type="radio"
                              checked={!userDetail?.is_ohs_onboard}
                              className="mr-1"
                              name="is_ohs_onboard"
                              onChange={(e) =>
                                setUserDetail((prevUserDetail) => ({
                                  ...prevUserDetail,
                                  is_ohs_onboard: false,
                                }))
                              }
                            />
                            In Active
                          </label>
                        </div>
                        <div className="flex-col -mx-3 mb-2 mt-4">
                          <div className="flex justify-left items-center px-3">
                            <p className="block uppercase tracking-wide text-xs font-bold mb-[5px]">Referral / Discount Code:</p>
                            <p className="border mb-[5px] ml-[5px]">{userDetail?.referral_code ? userDetail?.referral_code : '-'}</p>
                          </div>
                          <div className="flex justify-left items-center px-3">
                            <p className="block uppercase tracking-wide text-xs font-bold mb-[5px]">Mailchimp Subscribe:</p>
                            <p className="border mb-[5px] ml-[5px]">{userDetail?.is_mailchimp_id == true ? 'Yes' : 'No'}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex -mx-3 px-3 mt-5 justify-end">
                      <button
                        type="reset"
                        className="px-4 py-2 bg-red text-white rounded-md text-sm"
                        onClick={() => {
                          navigate(-1)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                        disabled={isUserEdited?.isLoading}
                      >
                        {isUserEdited?.isLoading &&
                          <ClipLoader color="#FFFFFF" size={20} />
                        }
                        {
                          !isUserEdited?.isLoading &&
                          (
                            <>
                              Submit
                            </>
                          )
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div >
      {showDeleteConfirmation &&
        <>
          <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setShowDeleteConfirmation(false)}>
            <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
              <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></button>
              <div className="content p-10 ">
                <h2 className="text-center font-semibold text-xl mb-6 max-w-[400px]">Change Password</h2>
                <div className="flex flex-col w-full">
                  <form
                    onSubmit={handleSubmit(changeUserPass)}
                    className="w-full max-w-full"
                  >
                    <div className="">

                      <div className="px-3 relative">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Email
                        </label>
                        <input
                          disabled
                          {...register("user_email", {
                            required: userDetail?.email == '' && 'Email is required',
                            // eslint-disable-next-line no-useless-escape
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address"
                            }
                          })}
                          name="email"
                          placeholder="Email"
                          type="email"
                          id="email"
                          value={userDetail?.email}
                          className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                          onChange={(e: any) => {
                            setUserCred((prevDetails) => {
                              return {
                                ...prevDetails,
                                user_email: e?.target?.value

                              }
                            });
                            setValue("user_email", e.target.value, { shouldValidate: true })
                          }}
                        />
                        <p className="text-red text-sm">
                          {errors?.user_email?.message}
                        </p>
                      </div>

                      <div className="px-3 relative mt-12">
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mt-2 flex justify-between"
                          htmlFor="grid-first-name"
                        >
                          <span>
                            New Password
                            <span className="pl-[4px] text-red">*</span>
                          </span>
                          <button className="h-8 w-8" onClick={() => autoGeneratePassword()}>
                            <svg className="h-6 w-6" version="1.0" xmlns="http://www.w3.org/2000/svg"
                              width="348.000000pt" height="348.000000pt" viewBox="0 0 348.000000 348.000000"
                              preserveAspectRatio="xMidYMid meet">
                              <title>Auto-generate password</title>

                              <g transform="translate(0.000000,348.000000) scale(0.100000,-0.100000)"
                                fill="#000000" stroke="none">
                                <path d="M2521 2819 c-167 -29 -300 -159 -346 -336 -11 -41 -15 -110 -15 -240
l0 -183 -22 0 c-78 -1 -163 -85 -174 -171 l-7 -49 -746 -2 -746 -3 -68 -33
c-207 -102 -252 -376 -89 -540 24 -24 69 -56 100 -70 l57 -27 746 -3 746 -2 7
-47 c9 -65 46 -124 98 -153 l43 -25 530 0 c480 0 533 2 561 17 45 25 92 82
104 128 7 27 10 174 8 438 -3 391 -3 398 -26 439 -12 24 -41 55 -64 70 l-42
28 -434 3 -434 3 4 187 c5 217 15 254 84 331 58 64 125 91 228 91 98 0 159
-22 217 -80 63 -63 89 -134 89 -245 l0 -85 80 0 80 0 0 78 c0 226 -123 412
-309 466 -69 20 -191 28 -260 15z m-573 -1316 l-3 -228 -695 -3 c-464 -1 -712
1 -747 8 -28 6 -69 24 -91 40 -51 36 -92 117 -92 180 0 59 40 142 86 176 70
54 54 53 827 54 l717 0 -2 -227z m715 213 c42 -17 67 -47 77 -89 9 -42 -10
-105 -40 -129 l-21 -17 30 -96 c17 -52 31 -100 31 -105 0 -6 -49 -10 -125 -10
-93 0 -125 3 -125 12 0 7 14 55 31 106 l31 93 -26 20 c-53 42 -55 139 -3 187
43 41 89 50 140 28z"/>
                                <path d="M630 1625 l0 -56 -52 40 c-49 37 -54 39 -70 24 -28 -25 -22 -37 37
-83 30 -23 55 -46 55 -50 0 -5 -27 -28 -59 -52 l-59 -44 20 -27 19 -27 52 40
52 39 3 -49 c3 -50 3 -50 38 -50 l34 0 0 51 0 51 52 -42 52 -41 18 23 c10 13
18 25 18 29 0 3 -27 25 -60 50 -32 24 -59 46 -59 49 0 3 26 25 59 50 33 24 60
47 60 50 0 3 -9 16 -19 29 l-19 25 -50 -43 -51 -42 -3 53 c-3 52 -4 53 -35 56
l-33 3 0 -56z"/>
                                <path d="M1140 1625 l0 -55 -52 41 -52 41 -18 -24 c-9 -13 -18 -25 -18 -29 0
-3 27 -25 60 -50 32 -24 59 -46 59 -49 0 -3 -26 -25 -59 -48 -33 -24 -60 -47
-60 -50 0 -4 8 -17 18 -30 l18 -23 52 41 52 42 0 -51 0 -51 34 0 c35 0 35 0
38 50 l3 50 50 -40 50 -40 18 21 c9 12 17 25 17 30 0 4 -25 27 -55 50 -30 23
-55 45 -55 50 0 5 25 27 55 50 59 45 65 57 37 82 -16 15 -21 13 -70 -24 l-52
-40 0 56 0 55 -35 0 -35 0 0 -55z"/>
                                <path d="M1658 1624 l-3 -56 -53 42 -52 42 -20 -24 c-11 -12 -19 -25 -19 -28
0 -3 27 -25 59 -49 33 -25 60 -47 60 -50 0 -4 -27 -26 -60 -51 -33 -24 -60
-47 -60 -50 1 -3 9 -15 20 -28 l19 -23 53 42 53 42 3 -55 c3 -54 3 -54 32 -50
27 4 29 7 32 54 l3 51 51 -41 52 -41 21 23 c11 12 21 25 21 27 0 3 -27 25 -60
49 -33 24 -60 47 -60 51 0 4 27 27 60 51 33 23 59 45 59 48 0 3 -8 15 -19 28
l-19 23 -53 -40 -53 -40 -3 54 c-3 53 -4 55 -32 55 -28 0 -29 -2 -32 -56z"/>
                              </g>
                            </svg>
                          </button>
                        </label>
                        <input
                          {...register("user_password", {
                            required: 'New password is required.',
                            minLength: {
                              value: 8,
                              message: 'New password must be at least 8 characters long',
                            },
                            pattern: {
                              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[/\W|_/g])[A-Za-z\d/\W|_/g]/,
                              message: 'Password must contain at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character',
                            }
                          })}
                          className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white pr-[38px]"
                          id="grid-first-name"
                          type={showPassword}
                          placeholder="New Password"
                          value={userCred.user_password}
                          onChange={(e) => {
                            setUserCred((prevDetails) => {
                              return {
                                ...prevDetails,
                                user_password: e?.target?.value
                              }
                            });
                            setValue("user_password", e.target.value, { shouldValidate: true })
                            if (e.target.value == userCred?.user_confirm__password)
                              setTemp(true)
                          }
                          }
                        />
                        <p className="text-red text-sm absolute">
                          {errors?.user_password?.message}
                        </p>
                        <div className="right-[19px] bottom-[4px] cursor-pointer absolute inline">
                          {showPassword === "password" && (
                            <button
                              onClick={() => {
                                setShowPassword("text");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                            </button>
                          )}

                          {showPassword === "text" && (
                            <button
                              onClick={() => {
                                setShowPassword("password");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                        {passwordMatchError && (
                          <p className="text-red text-sm absolute mt-2">{passwordMatchError}</p>
                        )}
                      </div>
                      <div className="px-3 relative mt-14" >
                        <label
                          className="block uppercase tracking-wide text-xs font-bold mb-4"
                          htmlFor="grid-last-name"
                        >
                          Confirm Password
                          <span className="pl-[4px] text-red">*</span>
                        </label>
                        <input
                          {...register("user_confirm__password", {
                            required: true && 'Confirm password is required.',
                            validate: (value) => {
                              return value === watch('user_password') || 'New password and Confirm password must be same.';
                            },

                          })}
                          className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white pr-[38px]"
                          id="grid-last-name"
                          type={showNewPassword}
                          placeholder="Confirm Password"
                          value={userCred?.user_confirm__password}
                          onChange={(e) => {
                            setIsClicked(true)
                            setUserCred((prevDetails) => {
                              return {
                                ...prevDetails,
                                user_confirm__password: e?.target?.value
                              }
                            });
                            setValue("user_confirm__password", e.target.value, { shouldValidate: true })
                          }
                          }
                        />
                        <p className="text-red text-sm absolute">
                          {(!temp && errors?.user_confirm__password?.message) || (userCred?.user_password !== userCred?.user_confirm__password && 'New password and Confirm password must be same.')}
                        </p>
                        <div className="right-[19px] bottom-[4px] cursor-pointer absolute inline">
                          {showNewPassword === "password" && (
                            <button
                              onClick={() => {
                                setNewShowPassword("text");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                              </svg>
                            </button>
                          )}

                          {showNewPassword === "text" && (
                            <button
                              onClick={() => {
                                setNewShowPassword("password");
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex mx-3 px-3 mt-[60px] justify-center">
                      <button
                        className="px-4 py-2 bg-red text-white rounded-md text-sm"
                        onClick={() => {
                          setShowDeleteConfirmation(false)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={errors?.user_password?.message ? true : false}
                        type="button"
                        className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                        onClick={() => changeUserPass()}
                      >
                        {isPasswordChanged?.isLoading &&
                          <ClipLoader color="#FFFFFF" size={20} />
                        }
                        {
                          !isPasswordChanged?.isLoading &&
                          (
                            <>
                              Submit
                            </>
                          )
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>}
    </div >
  );
};

export default EditUser;

