import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IJobList {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  joblist: [];
  page: null;
  limit: null;
  search_text?: string;
  start_date?: string;
  end_date?: string;
  status?: string
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  joblist: []
};

export const joblist: any = createAsyncThunk(
  "JobListSlice",
  async ({ page, limit, search_text, start_date, end_date, status }: IJobList) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.jobs.jobList}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}${start_date !== 'Invalid date' && start_date !== undefined && end_date !== 'Invalid date' && end_date !== undefined ? `&start_date=${start_date}` : ''}${end_date !== 'Invalid date' && end_date !== undefined && start_date !== 'Invalid date' && start_date !== undefined ? `&end_date=${end_date}` : ''}${status ? `&status=${status}` : ''}`)
    return response
  }
);

export const JobListSlice = createSlice({
  name: "JobListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(joblist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(joblist.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(joblist.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const jobListReducer = JobListSlice.reducer;