import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Loader from "../../components/loader/Loader";
import { userdetails } from "../../feature/Users/UserDetails-Slice";
import { Breadcrumb } from "../../components/breadcrumb";

const UserLogsPage = () => {
    const [loading, setLoading] = useState(false);
    const [userDetail, setUserDetail] = useState([])
    const [userName, setUserName] = useState({
        first_name: '',
        last_name: ''
    })

    const params = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userdetails({ id: params?.id })).then((data: any) => { setUserDetail(data?.payload?.data?.data?.user_logs); setUserName(data?.payload?.data?.data?.user) })
    }, [window.location.pathname]);



    const { isLoading } = useSelector((state: any) => state?.userDetail)


    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={`${'Log List of'} ${userName?.first_name} ${userName?.last_name}`} />
                    <Breadcrumb
                        paths={[('dashboard'), ('users')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/users']}
                    />
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                    <div className="flex flex-col">
                                    </div>
                                    <div
                                        className="flex items-center sm:mt-4 sm:w-full sm:flex-row-reverse sm:justify-center">
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                                        <table className="w-full text-left table-auto">
                                            <thead className="bg-logo text-white">
                                                <tr className="">
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[565px] ">
                                                        Id
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[198px]">
                                                        App version
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[177px]">
                                                        Device type
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[248px]">
                                                        Access date
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[346px]">
                                                        Ip address
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {isLoading ? (
                                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                        <ClipLoader color="#f27366" size={50} />
                                                    </div>
                                                ) : <>

                                                    {userDetail?.length ? userDetail.map((data: any, index: any) => {
                                                        const isGrayRow = index % 2 === 0;
                                                        return (
                                                            <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                    {data?.id ? data?.id : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                    {data?.app_version ? data?.app_version : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                    {data?.device_type ? data?.device_type : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                    {data?.access_date ? data?.access_date : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                    {data?.ip_address ? data?.ip_address : '-'}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                <div>No Data Found</div>
                                                            </td>
                                                        </tr>}
                                                </>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserLogsPage;
