import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from '../../components/sidebar/Sidebar';
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import Loader from "../../components/loader/Loader";
import { userAddresslist } from "../../feature/Users/UserAddressList-Slice";
import moment from "moment";
import { Breadcrumb } from "../../components/breadcrumb";

let PageSize = 1;

const UserAddressListing = () => {
    const [searchResult, setSearchResult] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [searchCount, setSearchCount] = useState(0)
    const [userListData, setUserListData] = useState([])
    const [fullName, setFullName] = useState({
        firstName: '',
        lastName: ''
    })

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = useParams()
    const propUser = 'users'
    const id = query?.id

    const userId = location.pathname.split('/')[3]

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);


    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            UserList();
        }

    }, [currentPage, navigate, searchResult]);

    const UserList = () => {
        dispatch(userAddresslist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            userId
        })).then((data: any) => {
            const uniqueArray = data?.payload?.data?.data?.addresses.filter((item: any, index: any) => data?.payload?.data?.data?.addresses.findIndex((x: any) => x['street'] === item['street']) === index);
            setUserListData(uniqueArray);
            setCount(data?.payload?.data?.data?.totalRecords);
            setSearchCount(data?.payload?.data?.data?.count);
            setFullName({
                firstName: data?.payload?.data.data.firstName,
                lastName: data?.payload?.data?.data?.lastName
            })
        });
    };


    const { isLoading } = useSelector((state: any) => state?.userAddressList);


    // useEffect(() => {
    //     // reset the state when the component mounts
    //     return () => {
    //         navigate(location.pathname, { state: undefined, replace: true });
    //     };
    // }, [navigate]);

    return (
        <>
            <div className="flex">
                <Sidebar />
                <div className="content  container-wrapper">
                    <Header />
                    <div className="fluid p-4 custom-height-wrapper">
                        <div className="flex justify-between items-center w-100" >
                            <div>
                                <Title title={`Property List  of  ${fullName?.firstName?.charAt(0)?.toUpperCase() + fullName?.firstName?.slice(1)}  ${fullName?.lastName?.charAt(0).toUpperCase() + fullName?.lastName?.slice(1)}`} />
                                <Breadcrumb
                                    paths={[('dashboard'), ('users')]}
                                    className="text-sm mb-3"
                                    url={['/dashboard', '/users']}
                                />
                            </div>
                        </div>
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                                <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                    <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                        <div className="flex items-center justify-between w-full" >
                                            <Search
                                                setSearchResult={setSearchResult}
                                                searchResult={searchResult}
                                                currentPage={setCurrentPage}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                                            <table className="w-full text-left table-auto">
                                                <thead className="bg-logo text-white">
                                                    <tr>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[455px]">
                                                            Address Line 1
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[191px]">
                                                            Address Line 2
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[170px]">
                                                            City
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[166px]">
                                                            State
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[124px]">
                                                            Zipcode
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[210px]">
                                                            Latitude <br />
                                                            Longitude
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[174px]">
                                                            Created At
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                            Created from
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[194px]">
                                                            Job List
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[194px]">
                                                            Estimate List
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? (
                                                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                            <ClipLoader color="#f27366" size={50} />
                                                        </div>
                                                    ) : <>
                                                        {userListData?.length ? userListData.map((data: any, index: any) => {
                                                            const isGrayRow = index % 2 === 0;
                                                            return (
                                                                <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-pre-line align-middle flex items-start max-w-[470px]">
                                                                        <span>
                                                                            {data?.street ? data?.street : '-'}
                                                                        </span>
                                                                        <span className={data?.is_default == true ? `ml-[5px] px-0.5 py-0.5 text-xs border-b border-solid border-gray whitespace-nowrap bg-green text-white rounded` : ''}>
                                                                            {data?.is_default == true && 'Primary'}
                                                                        </span>
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.street_line_2 ? data?.street_line_2 : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.city ? data?.city : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.state ? data?.state : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.zip}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.lat && data?.long ? <>{data.lat},<br />{data.long}</> : data?.lat == 'undefined' && data?.long == 'undefined' ? '-' : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.created_from ? data?.created_from : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        <span className="flex items-center">
                                                                            <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                onClick={() => { navigate(`/users/jobs/${data.id}`, { state: { data, query, userId } }) }}
                                                                            >
                                                                                <button className="text-xs font-bold text-black uppercase" >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                        <title>Jobs list</title>
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        <span className="flex items-center">
                                                                            <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                onClick={() => { navigate(`/estimate-address/${data.id}`, { state: { propUser, data, id } }) }}
                                                                            >
                                                                                <button className=" text-xs font-bold text-black uppercas" >
                                                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline>
                                                                                        <title>Estimate list</title></svg>
                                                                                </button>
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                    <div>No Data Found</div>
                                                                </td>
                                                            </tr>}
                                                    </>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col items-center my-12">
                            {
                                userListData && userListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={count}
                                    pageSize={PageSize}
                                    onPageChange={setCurrentPage}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserAddressListing;
