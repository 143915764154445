import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
interface IZipCodeDetails {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    stateCode: string;
}


const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const allZipcodelist: any = createAsyncThunk(
    "AllZipcodeListSlice",
    async ({ stateCode }: IZipCodeDetails) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.zipcode.allZipcode}${stateCode ? `?stateCode=${stateCode}` : ''}`)
        return response
    }
);

export const AllZipcodeListSlice = createSlice({
    name: "AllZipcodeListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(allZipcodelist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(allZipcodelist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(allZipcodelist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const allzipcodeListReducer = AllZipcodeListSlice.reducer;
