
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";


interface IBookCal {
    booking_date?: string;
    booking_reason?: string;
    hcp_region_id?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const bookSlots: any = createAsyncThunk(
    "BookSlotsSlice",
    async ({ booking_date, booking_reason, hcp_region_id }: IBookCal) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.holidayList.bookSlots}`, {
                booking_date: booking_date,
                booking_reason: booking_reason,
                hcp_region_id: hcp_region_id

            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const BookSlotsSlice = createSlice({
    name: "BookSlotsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(bookSlots.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(bookSlots.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(bookSlots.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const bookSlotsSliceReducer = BookSlotsSlice.reducer;
