
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    first_name?: string;
    last_name?: string
    membership_plan?: string;
    mobile?: number;
    zipcode?: number;
    state?: string;
    city?: string;
    address?: string;
    address_line_1?: string;
    email?: string
}

interface IAddUser {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userId: number;
    userStatus: boolean;
    onBoardStatus: boolean;
    userDetail: IData;
    membership_data: {};
    selectedCoordLat?: any;
    selectedCoordLong?: any;
    stateValue?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    userDetail: []
};

export const addUser: any = createAsyncThunk(
    "AddUserSlice",
    async ({ userDetail, stateValue, membership_data, selectedCoordLat, selectedCoordLong }: IAddUser) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.addUser}`, {
                first_name: userDetail?.first_name,
                last_name: userDetail?.last_name,
                membership_plan: userDetail?.membership_plan,
                mobile: userDetail?.mobile,
                zipcode: userDetail?.zipcode,
                state: stateValue,
                city: userDetail?.city,
                address: userDetail?.address,
                address_line_1: userDetail?.address_line_1,
                email: userDetail?.email,
                password: "",
                membership_data: membership_data,
                lat: selectedCoordLat,
                long: selectedCoordLong

            }).then((data: any) => {
                showToastSuccessMessage('User added successfully')
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)

            throw error
        }
    }
);

export const AddUserSlice = createSlice({
    name: "AddUserSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addUser.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(addUser.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const addUserSliceReducer = AddUserSlice.reducer;
