export const apiconfig = {
	authentication: {
		login: '/auth/sign-in',
		forgotPassword: '/auth/forgot-password',
		resetPassword: '/auth/reset-password',
		changePassword: '/auth/change-password'
	},
	dashboard: {
		dashboardCount: '/admin-api/dashboard'
	},
	users: {
		userList: '/users/user-list',
		editUser: '/users/update-profile',
		userdetails: '/admin-api/user-details',
		jobdetails: '/jobs/list',
		deleteUser: '/auth/delete-user-account',
		draftUser: '/users/draft-user/list',
		addUser: '/users/create-user',
		changePassword: '/admin-api/change-password',
		userAddress: '/customer-address/admin-address-list'

	},
	jobs: {
		jobList: '/jobs/job-list',
		viewJob: '/jobs/job-details',
		syncCustomerJob: '/jobs/sync-customer-jobs',
		syncJob: '/jobs/sync-jobs',
		invoiceJob: '/jobs/job-invoice'
	},
	employees: {
		employeeList: '/employees/employee-list',
		syncEmployee: '/employees/sync-employees',
		statusEmployee: '/admin-api/employee',
		employeeSkill: '/employees/employee-skills-list',
		editEmployee: '/employees/get-employee',
		employeeAdd: '/employees/update-employee'
	},
	estimate: {
		etimateRequestList: '/estimate/estimate-request-list',
		vieweEstimateRequest: '/estimate/estimate-details',
		estimateList: '/estimate/estimate-list',
		estimateListAddress: '/estimate/list'
	},
	advertisement: {
		advertisementList: '/advertisement',
		advertisementdetails: '/advertisement',
		editAdvertisement: '/advertisement',
		deleteAdvertisement: '/advertisement',
		newAdvertisement: '/advertisement',
		sortAdv: '/advertisement/sort'
	},
	services: {
		serviceList: '/service-list/list',
		deleteService: '/service-list/delete',
		editService: '/service-list',
		servicedetails: '/service-list',
		newService: '/service-list',
		sortService: '/service-list/sort',
		syncService: '/service-list/sync'
	},
	hcpRegion: {
		hcpRegionList: '/admin-api/region',
		hcpRegionDetails: '/admin-api/region',
		editHCPRegion: '/admin-api/region'
	},
	customer: {
		customerSync: '/customer/sync-customers'
	},
	zipcode: {
		zipcodeList: '/admin-api/zipcode',
		zipcodeDetail: '/admin-api/zipcode',
		editZipcode: '/admin-api/zipcode',
		addZipcode: '/admin-api/zipcode',
		allZipcode: '/zipcode/state-zipcodes',
		deleteZipcode: '/zipcode/delete-zipcode'
	},
	appConfig: {
		appConfigList: '/app-configs/list',
		editConfig: '/app-configs'
	},
	addressRequest: {
		addressRequestList: '/customer-address/address-request-list',
		changeStatus: '/customer-address/update-status',
		syncAddress: '/customer-address/sync-customers-address'
	},
	property: {
		userPropertyList: '/customer-address/all-addresses'
	},
	taxonomy: {
		industryList: '/service-taxonomy/list',
		adminIndustryList: '/service-taxonomy/industry-list',
		adminIndustryCategoryList: '/service-taxonomy/category-list',
		adminIndustrySubCategoryList: '/service-taxonomy/sub-category-list',
		sortTax: '/service-taxonomy/sort',
		editVendor: '/service-taxonomy/edit-sub-category',
		editTaxonomyStatus: '/service-taxonomy/edit-taxonomy-status',
		exportTaxonomy: '/service-taxonomy/export-taxonomy',
		importTaxonomy: '/service-taxonomy/import-taxonomy-excel'
	},
	holidayList: {
		availabilityCal: '/holiday-handling/availability-calendar',
		bookSlots: '/holiday-handling/admin-slot-booking',
		removeSlots: '/holiday-handling/remove-booked-slot'
	},
	updateProfile: '/admin-api/update-profile',
	webAnalytics: '/web-analytics',
	staffLoginList: '/admin-api/staff-login-logs',
	masterPassword: {
		masterPasswordList: '/admin-api/get-master-password',
		autoPassword: '/admin-api/generate-master-password'
	}
}
