import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import logo from "../../assets/images/core/OH-Logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotpassword } from "../../feature/Authentications/ForgotPassword-Slice";
import { ClipLoader } from "react-spinners";

interface IFormInputs {
  email: string;
}

const Forgot = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm<IFormInputs>();

  const dispatch = useDispatch()
  const [email, setEmail]: any = useState('');

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    dispatch(forgotpassword({ data }))
  };

  const { isLoading } = useSelector((state: any) => state.forgotpassword)

  return (
    <div className="flex flex-col bg-white border-darkGray border-solid border p-8 w-full max-w-lg">
      <div className="logo flex items-center justify-center pb-5">
        <img src={logo} alt="Logo" width={125} height={40} />
      </div>
      <div className="flex flex-col w-full mb-4">
        <div className="text-lg leading-6 pt-2 font-bold">
          Forgot Password
        </div>
      </div>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-y-1 gap-x-2 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <div className="space-y-1">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium whitespace-nowrap"
                >
                  Email
                </label>
                <input
                  {...register("email", {
                    required: 'Email is required',
                    // eslint-disable-next-line no-useless-escape
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address"
                    }
                  })}
                  name="email"
                  placeholder="Email"
                  type="email"
                  id="email"
                  className="form-input block w-full border-darkGray border border-solid bg-white flex-grow-1 outline-0 focus:border-blue focus:ring-0 sm:text-sm rounded-md h-10 px-3 py-2"
                  onChange={(e: any) => { setEmail(e?.target?.value); setValue("email", e.target.value, { shouldValidate: true }) }}
                />
                <p className="text-red text-sm">
                  {errors?.email?.message}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-start space-x-2">
          <button
            onClick={() => reset()}
            type="button"
            className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-darkGray rounded-md shadow-sm focus:outline-none"
          >
            Reset
          </button>
          <button
            disabled={isLoading}
            type="submit"
            className="inline-flex justify-center px-3 py-2 ml-3 text-sm font-medium text-white bg-logo border border-transparent shadow-sm rounded-md focus:outline-none w-[77px]"
          >
            {isLoading &&

              <ClipLoader color="#FFFFFF" size={20} />
            }
            {
              !isLoading &&
              (
                <>
                  Submit
                </>
              )
            }
          </button>
        </div>
      </form>
      <div className="flex flex-row w-full mt-4 text-sm">
        <span className="text-secondary mr-1">Go back to</span>
        <span>
          <Link className="text-blue" to="/">
            Login
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Forgot;
