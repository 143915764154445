import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IEstimateRequest {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  estimaterequest: [];
  page: null;
  limit: null;
  search_text: string
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  estimaterequest: []
};

export const estimaterequestlist: any = createAsyncThunk(
  "EstimateRequestListSlice",
  async ({ page, limit, search_text }: IEstimateRequest) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.estimate.etimateRequestList}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}`)
    return response
  }
);

export const EstimateRequestListSlice = createSlice({
  name: "EstimateRequestListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(estimaterequestlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(estimaterequestlist.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(estimaterequestlist.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const estimaterequestListReducer = EstimateRequestListSlice.reducer;
