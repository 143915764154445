import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { newService } from "../../feature/Services/NewService-Slice";

interface IFormInputs {
    service_title?: string;
    service_category?: string;
    service_id?: any
}

const NewService = () => {
    const [serviceDetail, setServiceDetail] = useState<IFormInputs>({
        service_title: '',
        service_category: '',
        service_id: ''
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<IFormInputs>();

    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        const formData = new FormData();
        formData.append("service_title", serviceDetail?.service_title as string);
        formData.append("service_category", serviceDetail?.service_category as string);
        dispatch(newService({ formData })).then(
            (data: any) => {
                if (data?.meta?.requestStatus === "fulfilled") {
                    setTimeout(() => {
                        navigate("/services");
                    }, 1000);
                }
            }
        );
    };

    const { isLoading } = useSelector((state: any) => state?.serviceDetail)
    const isServiceAdded = useSelector((state: any) => state.newService)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4">
                    <Title title={"New Service"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('services')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/services']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Service title
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("service_title", { required: true })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="text"
                                                    placeholder="Service title"
                                                    value={serviceDetail?.service_title}
                                                    onChange={(e) => {
                                                        setServiceDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            service_title: e.target.value,
                                                        }))
                                                        setValue("service_title", e.target.value, { shouldValidate: true })
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.service_title && "Service title is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    Services
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("service_category", { required: true })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-state"
                                                        onChange={(e) => {
                                                            setServiceDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                service_category: e.target.value,
                                                            }))
                                                            setValue("service_category", e.target.value, { shouldValidate: true })
                                                        }}
                                                        value={serviceDetail?.service_category}
                                                    >
                                                        <option value="">Select services</option>
                                                        <option value='popular'>Popular</option>
                                                        <option value='seasonal'>Seasonal</option>
                                                        <option value='general'>General</option>
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <p className="text-red text-sm">
                                                    {errors?.service_category && "Service category is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 px-3 mt-5 justify-end">

                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm "
                                                onClick={() => {
                                                    navigate('/services')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm ml-2  w-[81px] h-[36px] inline-block"
                                                disabled={isServiceAdded?.isLoading}
                                            >
                                                {isServiceAdded?.isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isServiceAdded?.isLoading &&
                                                    (
                                                        <>
                                                            Submit
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewService;

