
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    industryId?: string;
    status?: any;
    taxonomyType?: string;
    regionId?: any
    skills?: any;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const editTaxoStatus: any = createAsyncThunk(
    "EditTaxonomySlice",
    async ({ industryId, status, taxonomyType, regionId }: IData) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.taxonomy.editTaxonomyStatus}/${industryId}`, {
                status: status,
                taxonomyType: taxonomyType,
                regionId: parseInt(regionId),
            }).then((data: any) => {
                showToastSuccessMessage(`Industry has been ${status == true ? 'Activated' : 'In-Activated'} successfully.`)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const EditTaxonomySlice = createSlice({
    name: "EditTaxonomySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editTaxoStatus.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(editTaxoStatus.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(editTaxoStatus.rejected, (state) => {
            state.isError = true;
            state.isSuccess = false;
        });
    },
});

export const editTaxonomyStatusReducer = EditTaxonomySlice.reducer;
