
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    id?: string;
    vendorType?: string;
    display_name?: string;
    estimated_time?: number
    skills?: any;
    taxnoStatus?: boolean
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const vendorEdit: any = createAsyncThunk(
    "VendorEditSlice",
    async ({ id, vendorType, display_name, estimated_time, skills, taxnoStatus }: IData) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.taxonomy.editVendor}`, {
                id: id,
                vendor_type: vendorType,
                display_name: display_name,
                estimated_time: estimated_time,
                skills: skills,
                is_active: taxnoStatus
            }).then((data: any) => {
                // showToastSuccessMessage('Vendor has been changed successfully.')
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const VendorEditSlice = createSlice({
    name: "VendorEditSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(vendorEdit.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(vendorEdit.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(vendorEdit.rejected, (state) => {
            state.isError = true;
            state.isSuccess = false;
        });
    },
});

export const editVendorSliceReducer = VendorEditSlice.reducer;
