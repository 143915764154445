
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface INewService {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    advertisementId: number;
    formData?: any
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    advertiseDetail: []
};

export const newService: any = createAsyncThunk(
    "NewServiceSlice",
    async ({ formData }: INewService) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.services.newService}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {

            // if (error?.response?.data?.statusCode == 500) {
            //     showToastErrorMessage('This service is already exist on HCP platform.')
            // }

            // if (error?.response?.data?.statusCode == 400) {
            //     showToastErrorMessage('This service is already exist on HCP platform.')
            // }

            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const NewServiceSlice = createSlice({
    name: "NewServiceSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(newService.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(newService.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(newService.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const newServiceSliceReducer = NewServiceSlice.reducer;
