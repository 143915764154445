import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";

const HCPRegionListing = () => {
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        HCPRegionData();
    }, []);

    const HCPRegionData = () => {
        dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
    }
    const { isLoading } = useSelector((state: any) => state?.hcpregion)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`HCP Region`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex flex-col">
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-scroll overflow-custom-scroll overflow-hidden">
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[217px]">
                                                    Region
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[847px]">
                                                    Api-key
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[847px]">
                                                    Email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[847px]">
                                                    Job request email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[847px]">
                                                    Estimation email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[847px]">
                                                    Signup email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[847px]">
                                                    Address request email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[251px]">
                                                    status
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[247px]">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {hcpRegionList?.length ? hcpRegionList.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.name}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.api_key}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.sg_email ? data?.sg_email : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.request_email ? data.request_email.split(',').map((email: any, index: any) => (
                                                                    <div key={index}>{email.trim()}</div>
                                                                )) : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.estimation_email ? data.estimation_email.split(',').map((email: any, index: any) => (
                                                                    <div key={index}>{email.trim()}</div>
                                                                )) : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.signup_email ? data.signup_email.split(',').map((email: any, index: any) => (
                                                                    <div key={index}>{email.trim()}</div>
                                                                )) : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.address_request_mail ? data.address_request_mail.split(',').map((email: any, index: any) => (
                                                                    <div key={index}>{email.trim()}</div>
                                                                )) : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.status == true ? 'Active' : 'In Active'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                <span className="flex items-center">
                                                                    <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                        onClick={() => { navigate(`/hcp-region/${data.id}`, { state: data }) }}
                                                                    >
                                                                        <button className="text-xs font-bold text-black uppercase" >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                <title>Edit</title>
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>

                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HCPRegionListing;
