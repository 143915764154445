import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IZipCodeDetails {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    id: string;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const zipCodeDetails: any = createAsyncThunk(
    "ZipcodeDetailsSlice",
    async ({ id }: IZipCodeDetails) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.zipcode?.zipcodeDetail}/${id}`)
        return response
    }
);

export const ZipCodeDetailSlice = createSlice({
    name: "ZipcodeDetailsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(zipCodeDetails.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(zipCodeDetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(zipCodeDetails.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const zipcodeDetailSliceReducer = ZipCodeDetailSlice.reducer;
