import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import { masterPasswordList } from "../../feature/MasterPassword/MasterPasswordList";
import { autoPassword } from "../../feature/MasterPassword/NewMasterPassword";
import { ClipLoader } from "react-spinners";

const MasterPassword = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [passwordInfo, setPasswordInfo] = useState([])
    const [userCred, setUserCred] = useState('')
    const [isCopied, setIsCopied] = useState(false);

    const dispatch = useDispatch()
    const { isLoading } = useSelector((state: any) => state?.autoPasswordSlice)

    useEffect(() => {
        dispatch(masterPasswordList()).then((data: any) => setPasswordInfo(data?.payload?.data?.data?.info)
        )
    }, [])

    const {
        reset,
    } = useForm();

    const generatePassword = () => {
        reset();
        const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
        const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const digitChars = '0123456789';
        const specialChars = '!@#$%^&*()_-+=<>?';

        const allChars = lowercaseChars + uppercaseChars + digitChars + specialChars;

        let password = '';
        password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
        password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
        password += digitChars[Math.floor(Math.random() * digitChars.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];

        for (let i = 4; i < 8; i++) {
            password += allChars[Math.floor(Math.random() * allChars.length)];
        }

        // Shuffle the password to ensure randomness
        password = password.split('').sort(() => Math.random() - 0.5).join('');
        setUserCred(password)


        return password;
    }

    useEffect(() => {
        if (userCred !== '') {
            dispatch(autoPassword({ userCred })).then((data: any) => {
                if (data?.meta?.requestStatus == "fulfilled") {
                    dispatch(masterPasswordList()).then((data: any) => setPasswordInfo(data?.payload?.data?.data?.info)
                    )
                }
            })
        }
    }, [userCred])

    const copyToClipboard = (text: any) => {
        navigator.clipboard.writeText(text).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); // Reset copied status after 2 seconds
        }, (err) => {
            console.error('Failed to copy: ', err);
        });
    };

    return (
        <>
            <div className="flex holidayListWrapper">
                <Sidebar />
                <div className="content w-full max-width-custom">
                    <Header />
                    <div className="fluid p-4 custom-height-wrapper">
                        <div className="pb-5">
                            <Title title={`Master Password`} />
                            {isLoading ? (
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <ClipLoader color="#f27366" size={50} />
                                </div>
                            ) : (
                                <div className="mt-[50px]">
                                    <div className="master-password h-[450px]">
                                        <div className="justify-center flex flex-col w-[690px]">
                                            <div className="flex flex-col gap-2 items-start">
                                                <div className="w-full relative flex flex-col items-center justify-center gap-y-14">
                                                    <label className="">Current Password:</label>
                                                    <label className="text-6xl h-[45px] flex items-center gap-x-4" onClick={() => copyToClipboard(passwordInfo[0])}
                                                        style={{ cursor: 'pointer', color: 'rgb(239, 68, 68)' }}
                                                        title="Click to copy"
                                                    >
                                                        <span>{passwordInfo[0]}</span>
                                                        {passwordInfo[0] &&

                                                            <div onClick={() => copyToClipboard(passwordInfo[0])}>
                                                                <svg height={'30px'} width={'30px'} xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 438 511.52"><path fill-rule="nonzero" d="M141.44 0h172.68c4.71 0 8.91 2.27 11.54 5.77L434.11 123.1a14.37 14.37 0 0 1 3.81 9.75l.08 251.18c0 17.62-7.25 33.69-18.9 45.36l-.07.07c-11.67 11.64-27.73 18.87-45.33 18.87h-20.06c-.3 17.24-7.48 32.9-18.88 44.29-11.66 11.66-27.75 18.9-45.42 18.9H64.3c-17.67 0-33.76-7.24-45.41-18.9C7.24 480.98 0 464.9 0 447.22V135.87c0-17.68 7.23-33.78 18.88-45.42C30.52 78.8 46.62 71.57 64.3 71.57h12.84V64.3c0-17.68 7.23-33.78 18.88-45.42C107.66 7.23 123.76 0 141.44 0zm30.53 250.96c-7.97 0-14.43-6.47-14.43-14.44 0-7.96 6.46-14.43 14.43-14.43h171.2c7.97 0 14.44 6.47 14.44 14.43 0 7.97-6.47 14.44-14.44 14.44h-171.2zm0 76.86c-7.97 0-14.43-6.46-14.43-14.43 0-7.96 6.46-14.43 14.43-14.43h136.42c7.97 0 14.43 6.47 14.43 14.43 0 7.97-6.46 14.43-14.43 14.43H171.97zM322.31 44.44v49.03c.96 12.3 5.21 21.9 12.65 28.26 7.8 6.66 19.58 10.41 35.23 10.69l33.39-.04-81.27-87.94zm86.83 116.78-39.17-.06c-22.79-.35-40.77-6.5-53.72-17.57-13.48-11.54-21.1-27.86-22.66-48.03l-.14-2v-64.7H141.44c-9.73 0-18.61 4-25.03 10.41C110 45.69 106 54.57 106 64.3v319.73c0 9.74 4.01 18.61 10.42 25.02 6.42 6.42 15.29 10.42 25.02 10.42H373.7c9.75 0 18.62-3.98 25.01-10.38 6.45-6.44 10.43-15.3 10.43-25.06V161.22zm-84.38 287.11H141.44c-17.68 0-33.77-7.24-45.41-18.88-11.65-11.65-18.89-27.73-18.89-45.42v-283.6H64.3c-9.74 0-18.61 4-25.03 10.41-6.41 6.42-10.41 15.29-10.41 25.03v311.35c0 9.73 4.01 18.59 10.42 25.01 6.43 6.43 15.3 10.43 25.02 10.43h225.04c9.72 0 18.59-4 25.02-10.43 6.17-6.17 10.12-14.61 10.4-23.9z" /></svg>
                                                            </div>
                                                        }
                                                    </label>
                                                    <span style={{ color: 'black' }} className="inline-block h-[24px] mb-7"> {isCopied && <>Copied!</>}</span>

                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex justify-center w-full ">
                                                    <button
                                                        type="submit"
                                                        className={'bg-red px-8 py-3 text-white w-[250px]  whitespace-nowrap'}
                                                        onClick={() => setIsPopupOpen(true)}
                                                    >
                                                        Generate New Password
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
            {
                isPopupOpen &&
                <>
                    <div className='modal-wrapper absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center' onClick={() => setIsPopupOpen(false)}>
                        <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded'>
                            <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setIsPopupOpen(false)} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg></button>
                            <div className="content p-10 ">
                                <p className="text-center">{`Are you sure want to generate new master password ?`}</p>
                                <div className="flex justify-center gap-x-5 mt-3">
                                    <button onClick={() => setIsPopupOpen(false)} className="text-center bg-red text-white px-5 py-2  w-[68px] h-[40px] inline-block">No</button>
                                    <button onClick={() => { generatePassword() }} className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block">
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default MasterPassword;
