
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    zipcode?: number;
    timezone?: string;
    state_code?: string;
    state?: string
}

interface IEditZipcode {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    zipcodeId: number;
    zipCodeDetail: IData;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const editZipcode: any = createAsyncThunk(
    "EditZipcodeSlice",
    async ({ zipcodeId, zipCodeDetail }: IEditZipcode) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.zipcode.editZipcode}`, {
                zip_id: zipcodeId,
                zipcode: zipCodeDetail?.zipcode,
                timezone: zipCodeDetail?.timezone,
                state_code: zipCodeDetail?.state_code,
                state: zipCodeDetail?.state

            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const EditZipcodeSlice = createSlice({
    name: "EditZipcodeSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editZipcode.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editZipcode.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(editZipcode.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const editZipcodeSliceReducer = EditZipcodeSlice.reducer;
