import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IChangeStatus {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    status?: string;
    id?: number;
    rejection_reason?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const changeAddressStatusAddReq: any = createAsyncThunk(
    "ChangeAddressStatusSlice",
    async ({ status, id, rejection_reason }: IChangeStatus) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig?.addressRequest?.changeStatus}`, {
                id: id,
                status: status,
                rejection_reason: rejection_reason
            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const ChangeAddressRequestSlice = createSlice({
    name: "ChangeAddressStatusSlice",
    initialState,
    reducers: {
        resetSuccessState: (state) => {
            state.isSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeAddressStatusAddReq.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(changeAddressStatusAddReq.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(changeAddressStatusAddReq.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const { resetSuccessState } = ChangeAddressRequestSlice.actions

export const changeAddressStatusSliceReducer = ChangeAddressRequestSlice.reducer;
