import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  serviceList: []
};

export const servicelist: any = createAsyncThunk(
  "ServiceListSlice",
  async (regionName: any) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.services?.serviceList}${regionName ? `${'?search_text='}${regionName}` : ''}`)
    return response
  }
);

export const ServiceListSlice = createSlice({
  name: "ServiceListSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(servicelist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(servicelist.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(servicelist.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const serviceListSliceReducer = ServiceListSlice.reducer;
