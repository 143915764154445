import axios from "axios";
import Cookies from "js-cookie";
import { apiconfig } from "../api-config/api-config";
import { clearLocalStorage, getLocalStorage } from "../utils/utils";

export const http = {
  post: axios.post,
  get: axios.get,
  delete: axios.delete,
  patch: axios.patch,
  put: axios.put,
};

export const setupInterceptors = (store: any) => {
  console.log("store", store);

  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      if (error?.response?.data?.statusCode == 401) {
        clearLocalStorage();
        // const navigate = useNavigate();
        // navigate("/login");
      }
      return Promise.reject(error);
    }
  );
  axios.interceptors.request.use(
    (response: any) => {
      if (!response.url.includes(apiconfig.authentication.login) &&
        !response.url.includes(apiconfig.authentication.forgotPassword) && !response.url.includes(apiconfig.authentication.resetPassword)) {
        const isLoggedIn = Cookies.get('loggedIn');
        if (!isLoggedIn) {
          clearLocalStorage();
          window.location.href = '/login';
        }
      }
      const Authorization = "Authorization";
      const accessToken = getLocalStorage("accessToken");
      if (accessToken) {
        response.headers[Authorization] = "Bearer " + accessToken;
      }

      return Promise.resolve(response);
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
};
