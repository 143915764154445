import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { joblist } from "../../feature/Job's/JobList-Slice";
import { syncJob } from "../../feature/Job's/SyncJob-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let PageSize = 1;

const JobListing = () => {
  const [searchResult, setSearchResult] = useState("");
  const [currentPage, setCurrentPage]: any = useState();
  const [count, setCount] = useState(0)
  const [jobListData, setJobListData] = useState([])
  const [searchCount, setSearchCount] = useState(0)
  const [filterStatus, setFilterStatus] = useState('')
  const [dateRange, setDateRange] = useState([null, null]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [startDate, endDate]: any = dateRange;
  const startFormatDate = moment(startDate).format('YYYY-MM-DD')
  const endFormatDate = moment(endDate).format('YYYY-MM-DD')


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageParam: any = queryParams.get("page");
    const page = parseInt(pageParam, 10) || 1;
    setCurrentPage(page);
  }, []);


  useEffect(() => {
    if (typeof currentPage !== 'undefined') {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("page", String(currentPage));
      window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
      navigate(`?${queryParams.toString()}`);

      JobList();
    }
  }, [currentPage, navigate, searchResult, filterStatus]);

  const JobList = () => {
    dispatch(joblist({
      page: currentPage,
      limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
      search_text: searchResult,
      status: filterStatus,
      start_date: startFormatDate ? startFormatDate : '',
      end_date: endFormatDate ? endFormatDate : ''
    })).then((data: any) => { setJobListData(data?.payload?.data?.data?.jobList); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
    )
  }

  const { isLoading } = useSelector((state: any) => state?.jobList)
  const isSyncJob = useSelector((state: any) => state?.syncJob)

  const jobSynchandler = () => {
    dispatch(syncJob()).then((val: any) => {
      if (val?.meta?.requestStatus == "fulfilled") {
        setTimeout(() => {
          JobList();
        }, 4000)
      }
    });
  }

  const filterHandler = () => {
    if (!startDate || !endDate) {
      // Show an error message or handle the validation as needed
      return;
    }
    setCurrentPage(1)

    dispatch(joblist({
      page: currentPage,
      limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
      search_text: searchResult,
      status: filterStatus,
      start_date: startFormatDate,
      end_date: endFormatDate
    })).then((data: any) => { setJobListData(data?.payload?.data?.data?.jobList); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
    )
  }

  const closeButton = () => {
    dispatch(joblist({
      page: currentPage,
      limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
      search_text: searchResult,
      status: filterStatus,
    })).then((data: any) => { setJobListData(data?.payload?.data?.data?.jobList); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
    )
    setCurrentPage(1)
  }

  const workStatusFilter = (e: any) => {
    setFilterStatus(e)
    setCurrentPage(1)
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="content w-full">
        <Header />
        <div className="fluid p-4 custom-height-wrapper">
          <div className="pb-5 flex">
            <Title title={`Jobs List`} />
          </div>
          <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
              <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                <div className="flex items-center justify-between w-full" >
                  <Search
                    setSearchResult={setSearchResult}
                    searchResult={searchResult}
                    currentPage={setCurrentPage}
                  />
                  <div date-rangepicker className="flex items-center">
                    <div className="relative  mr-[10px]">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                      </div>
                      <DatePicker
                        showIcon
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update: any) => {
                          setDateRange(update);
                          if (!update || (update[0] === null && update[1] === null)) {
                            // setFilterStatus('')
                            // setDateRange([null, null])
                            closeButton(); // Trigger the JobList function
                          }
                        }}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[240px] ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholderText="Select date range"
                        dateFormat="yyyy /MM/dd"
                        selectsRange={true}
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <button
                    type='button'
                    className="px-4 py-2 text-xs font-bold text-white uppercase bg-logo text-right rounded-lg"
                    onClick={filterHandler}
                  >
                    Submit
                  </button>

                  <div className="relative items-center justify-end ml-auto">
                    <select
                      className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                      id="grid-state"
                      value={filterStatus}
                      onChange={(e) => {
                        workStatusFilter(e?.target?.value)
                      }}
                    >
                      <option value="" hidden>
                        Select work status
                      </option>
                      <option value="scheduled">Scheduled</option>
                      <option value="completed">Completed</option>
                      <option value="canceled">Cancelled</option>
                      <option value="" hidden={filterStatus == ''} disabled={filterStatus !== "scheduled" && filterStatus !== "completed" && filterStatus !== "canceled"}>All</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>

                  <span className="icon inline-block mx-2 sm:ml-0" onClick={() => jobSynchandler()} title='Click here to sync'>
                    <svg
                      className="cursor-pointer hover:animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20px"
                      viewBox="0 0 24 24"
                      id="reload"
                    >
                      <path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden" >
                  <table className="w-full text-left table-auto">
                    <thead className="bg-logo text-white">
                      <tr>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[67px]">
                          Id
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[203px]">
                          Title
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[183px]">
                          Created at
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[244px]">
                          Scheduled start date/time
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[183px]">
                          Scheduled end time
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[173px]">
                          Work status
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[193px]">
                          Assigned Employee
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[150px]">
                          Created from
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[203px]">
                          Created by
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[100px]">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {isLoading || isSyncJob?.isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <ClipLoader color="#f27366" size={50} />
                        </div>
                      ) : <>

                        {jobListData?.length ? jobListData.map((data: any, index: any) => {
                          const isGrayRow = index % 2 === 0;
                          return (
                            <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.id}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data.title.length >= 50
                                  ? data.title.slice(0, 50) + '...'
                                  : data.title || '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD ') : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.scheduled_start ? moment(data?.scheduled_start).format('YYYY-MM-DD HH:mm') : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.scheduled_end ? moment(data?.scheduled_end).format('HH:mm') : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.work_status ? data?.work_status.charAt(0).toUpperCase() + data?.work_status?.slice(1) : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.assigned_employees.length > 0 ? (
                                  data.assigned_employees.map((val: any) => (
                                    <div key={val.id}>
                                      {val.first_name && val.last_name ? (
                                        <>{val.first_name} {val.last_name}</>
                                      ) : (
                                        '-'
                                      )}
                                    </div>
                                  ))
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.created_by ? 'App' : 'HCP'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.created_by_email ? data?.created_by_email : '-'}
                              </td>
                              <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                <span className="flex items-center">
                                  <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                    onClick={() => { navigate(`/jobs/${data.id}`, { state: data }) }}
                                  >
                                    <button className="text-xs font-bold text-black uppercase" >
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                        <title>View</title>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>
                                    </button>
                                  </span>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                          :
                          <tr>
                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                              <div>No Data Found</div>
                            </td>
                          </tr>}
                      </>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center my-12">
            {
              jobListData && jobListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={PageSize}
                onPageChange={(page: React.SetStateAction<number>) =>
                  setCurrentPage(page)
                }
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListing;