import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "../../components/breadcrumb";
import { viewJob } from "../../feature/Job's/ViewJob-Slice";
import { ClipLoader } from "react-spinners";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import download from '../../assets/images/core/jobDownload.png'
import { invoiceJob } from "../../feature/Job's/GenerateInvoice-Slice";
import { showToastSuccessMessage } from "../../utils/utils";
interface IFormInputs {
  description?: string;
  work_status?: string;
  scheduled_start?: string;
  scheduled_end?: string;
  id?: string;
  hcp_job_id?: string;
  notes?: [];
  request_for_call?: string;
  ohs_created_at?: string;
  ohs_updated_at?: string;
  is_cancel?: any;
  invoice_url?: string;
  industry_name?: string;
  category_name?: string;
  sub_category_name?: string;
  created_by?: any
}

interface IAssigDet {
  email?: string;
  first_name?: string;
  last_name?: string;
  street?: string;
  street_line_2?: string
}

const ViewJob = () => {
  const [jobView, setJobView] = useState<IFormInputs>({
    description: "",
    work_status: "",
    scheduled_start: "",
    scheduled_end: "",
    id: '',
    hcp_job_id: '',
    notes: [],
    request_for_call: '',
    ohs_created_at: '',
    ohs_updated_at: '',
    is_cancel: false,
    invoice_url: '',
    industry_name: '',
    category_name: '',
    sub_category_name: '',
    created_by: ''
  })

  const [assigneeDetails, setAssigneeDetails] = useState<IAssigDet>({
    email: '',
    first_name: '',
    last_name: ''
  })

  const [assignedEmployees, setAssignedEmployees] = useState([])
  const [propAddress, setPropAddress] = useState<IAssigDet>({
    street: '',
    street_line_2: '',
  })

  const [jobLineItems, setJobLineItems]: any = useState([])
  const params = useParams()
  const [fullName, setFullName]: any = useState({
    firstName: '',
    lastName: ''
  })
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: any) => state?.viewJob)
  const location = useLocation()

  const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false)

  // To get the data of the user from the api.
  useEffect(() => {
    dispatch(viewJob({ id: params?.id })).then((data: any) => {
      setAssignedEmployees(data?.payload?.data?.data?.assigneeDetails)
      setJobView(data?.payload?.data?.data?.job); setJobLineItems(data?.payload?.data?.data?.jobLineItems); setAssigneeDetails(data?.payload?.data?.data?.assigneeDetails); setPropAddress(data?.payload?.data?.data?.address); setFullName((preValue: any) => {
        return {
          ...preValue,
          firstName: data?.payload?.data?.data?.firstName,
          lastName: data?.payload?.data?.data?.lastName
        }
      })
    })
  }, [window.location.pathname, isInvoiceGenerated]);

  const tittle = jobLineItems?.find((val: any) => val?.kind == 'labor')?.name

  const handleGenerateInvoice = () => {
    dispatch(invoiceJob({ id: jobView?.hcp_job_id })).then((data: any) => {
      if (data?.payload?.data?.statusCode) {
        showToastSuccessMessage(data?.payload.data.message)
        setIsInvoiceGenerated(!isInvoiceGenerated)
      }
    }
    )

  }
  const isGenerateInvoice = useSelector((state: any) => state?.invoiceJob)

  return (
    <div className="flex">
      <Sidebar />
      <div className="content w-full">
        <Header />
        <div className="fluid p-4 custom-height-wrapper">
          <Title title={`Job Details of ${propAddress?.street} ${propAddress?.street_line_2} (${fullName?.firstName} ${fullName?.lastName})`} />
          <Breadcrumb
            paths={[('dashboard'), (location.pathname == `${'/users/job_details/'}${params?.id}` && location?.state?.temp !== 'ALL-PROP' ? ('users') : location.pathname == `${'/jobs/'}${params?.id}` && location?.state?.temp !== 'ALL-PROP' ? ('jobs') : ('users-properties')), location?.state?.prop ? (`${'Property -list'} of  ${fullName?.firstName} ${fullName?.lastName}`) : null, `${location.state?.params?.id ? (`${'Job -list'} of ${location?.state?.add} (${fullName?.firstName} ${fullName?.lastName})`) : ''}`]}
            className="text-sm mb-3"
            url={[location.state?.params?.id && `${'users/jobs/'}${location.state?.params?.id}`]}
            url2={[location?.state?.prop && `${'users/address-list/'}${location?.state?.prop}`]}
          />
          {isLoading ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <ClipLoader color="#f27366" size={50} />
            </div>
          ) : (
            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4 ">
              <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray mb-[20px]">
                <div className="flex flex-col w-full">
                  <div className="w-full max-w-full relative">
                    <div className="px-3 flex items-center gap-x-10">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px] "
                        htmlFor="grid-first-name"
                      >
                        Id
                      </label>
                      <h1 className='appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white'>{jobView?.id}</h1>
                    </div>
                    {jobView?.work_status == 'complete unrated' || jobView?.work_status == 'complete rated' ?

                      <div className="flex justify-end absolute right-0 top-0">
                        {jobView?.invoice_url &&

                          <Link className="flex" to={`${process.env.REACT_APP_IMAGE_PATH}${jobView?.invoice_url}`} target='_blank'>
                            <img className="object-contain" src={download} height='25px' width='25px'></img>
                          </Link>
                        }
                        <button className="px-4 py-2 m-0 ml-2 items-center text-xs font-bold text-white uppercase bg-blue w-[146px] h-[32px] inline-block" onClick={handleGenerateInvoice} disabled={isGenerateInvoice?.isLoading}>
                          {isGenerateInvoice?.isLoading &&
                            <ClipLoader color="#FFFFFF" size={20} />
                          }
                          {
                            !isGenerateInvoice?.isLoading &&
                            (
                              <>
                                Generate Invoice
                              </>
                            )
                          }
                        </button>
                      </div>
                      :
                      ''
                    }
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        hcp job id
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{jobView?.hcp_job_id}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Property address
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{propAddress?.street} {propAddress?.street_line_2}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        Title
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{tittle ? tittle : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Description
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{jobView?.description ? jobView?.description : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Assignee Email
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{assigneeDetails?.email ? assigneeDetails?.email : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Assignee Fullname
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{assigneeDetails?.first_name ? assigneeDetails?.first_name?.charAt(0)?.toUpperCase() + assigneeDetails?.first_name?.slice(1) : '-'} {assigneeDetails?.last_name && assigneeDetails?.last_name?.charAt(0)?.toUpperCase() + assigneeDetails?.last_name?.slice(1)}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Assigned Employee
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">
                        <>
                          {assignedEmployees && assignedEmployees?.length > 0 ? (
                            assignedEmployees.map((val: any, index: number) => (
                              <span key={val?.id}>
                                {val?.first_name} {val?.last_name}
                                {index < assignedEmployees?.length - 1 && ','}{' '}
                              </span>
                            ))
                          ) : (
                            '-'
                          )}
                        </>
                      </h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        Notes
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">
                        {jobView?.notes?.length ? (
                          <>
                            {jobView?.notes?.map((note: any) => note.content).join(', ')}
                          </>
                        ) : '-'}
                      </h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Request for call
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{`${jobView?.request_for_call == "true" ? 'Yes' : jobView?.request_for_call == "false" ? 'No' : '-'}`}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        ohs created
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.ohs_created_at ? moment(jobView?.ohs_created_at).format('YYYY-MM-DD HH:mm') : '-'}
                      </h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        ohs updated
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{jobView?.ohs_updated_at ? moment(jobView?.ohs_updated_at).format('YYYY-MM-DD HH:mm') : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold text-green mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        scheduled start date / time
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.scheduled_start ? moment(jobView?.scheduled_start).format('YYYY-MM-DD HH:mm') : '-'}
                      </h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold text-green mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        scheduled end time
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{jobView?.scheduled_end ? moment(jobView?.scheduled_end).format("HH:mm") : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold text-green mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        work status
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.work_status ? jobView?.work_status?.charAt(0)?.toUpperCase() + jobView?.work_status?.slice(1) : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-last-name"
                      >
                        Job is cancel?
                      </label>
                      <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{`${jobView?.is_cancel == true ? 'Yes' : jobView?.is_cancel == false ? 'No' : '-'}`}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        industry
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.industry_name ? jobView?.industry_name : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        category
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.category_name ? jobView?.category_name : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        sub-category
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.sub_category_name ? jobView?.sub_category_name : '-'}</h1>
                    </div>
                    <div className="px-3 flex items-center gap-x-10 mt-3">
                      <label
                        className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                        htmlFor="grid-first-name"
                      >
                        created from
                      </label>
                      <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{jobView?.created_by ? 'App' : 'HCP'}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <Title title={"Job Line Items"} />
              <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                <div className="flex flex-col w-full">
                  <div className="overflow-hidden mb-[30px]">
                    <table className="w-full text-left table-auto mt-[10px]">
                      <thead className="bg-logo text-white">
                        <tr>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Id
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Name
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[300px]">
                            Description
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Kind
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Quantity
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Unit cost
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Unit price ($)
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                            Amount ($)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobLineItems && jobLineItems?.length ? jobLineItems.map((data: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.id}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.name ? data?.name : "-"}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray  w-[300px]">
                                {data?.description ? data?.description : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.kind ? data?.kind : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.quantity || data?.quantity == 0 ? data?.quantity : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.unit_cost || data?.unit_cost == 0 ? data?.unit_cost : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.unit_price || data?.unit_price == 0 ? data?.unit_price / 100 : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                {data?.amount || data?.amount == 0 ? data?.amount / 100 : '-'}
                              </td>
                            </tr>
                          );
                        })
                          :
                          <tr>
                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                              No Data Found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewJob;

