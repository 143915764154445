import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userlist } from "../../feature/Users/UserList-Slice";
import { syncCustomerlist } from "../../feature/Users/CustomerSync-Slice";
import Sidebar from '../../components/sidebar/Sidebar';
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import { deleteUser } from "../../feature/Users/DeleteUser-Slice";
import { stateData } from "../../constants/StateData";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

let PageSize = 1;

const UserListing = () => {
  const [searchResult, setSearchResult] = useState("");
  const [currentPage, setCurrentPage]: any = useState();
  const [count, setCount] = useState(0)
  const [searchCount, setSearchCount] = useState(0)
  const [userListData, setUserListData] = useState([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filterStatus, setFilterStatus]: any = useState('')
  const [hcpRegionList, setHCPRegionList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageParam: any = queryParams.get("page");
    const page = parseInt(pageParam, 10) || 1;
    setCurrentPage(page);
  }, []);


  useEffect(() => {
    if (typeof currentPage !== 'undefined') {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set("page", String(currentPage));
      window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
      navigate(`?${queryParams.toString()}`);

      UserList();
    }

  }, [currentPage, navigate, searchResult, filterStatus]);

  const UserList = () => {
    dispatch(userlist({
      page: currentPage,
      limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
      search_text: searchResult,
      region: filterStatus,
    })).then((data: any) => {
      setUserListData(data?.payload?.data?.data?.userList);
      setCount(data?.payload?.data?.data?.totalRecords);
      setSearchCount(data?.payload?.data?.data?.count);
    });
  };


  const { isLoading } = useSelector((state: any) => state?.userList);
  const isCustomerSync = useSelector((state: any) => state?.syncCustomer);
  const isUserDeleted = useSelector((state: any) => state?.deleteUser)

  const syncCustomerhandler = () => {
    dispatch(syncCustomerlist()).then((val: any) => {
      if (val?.meta?.requestStatus == "fulfilled") {
        setTimeout(() => {
          UserList();
        }, 4000)
      }
    });
  };

  const confirmDeleteUser = (id: any) => {
    dispatch(deleteUser({ id })).then((val: any) => {
      if (val?.meta?.requestStatus === "fulfilled") {
        UserList();
      }
    });

    setShowDeleteConfirmation(false);
  };

  const deleteUserHandler = (id: any) => {
    setSelectedUserId(id);
    setShowDeleteConfirmation(true);
  }

  const addUser = () => {
    navigate('/users/add-user')

  }

  const handleStateChange = (e: any) => {
    setFilterStatus(e.target.value)
    setCurrentPage(1)
  };

  useEffect(() => {
    dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
  }, [])

  showDeleteConfirmation
    ? (document.body.style.overflow = 'hidden')
    : (document.body.style.overflow = 'auto')

  return (
    <div className="flex">
      <Sidebar />
      <div className="content  container-wrapper">
        <Header />
        <div className="fluid p-4 custom-height-wrapper">
          <div className="flex justify-between items-center w-100 pb-5" >
            <Title title={`Users List`} />
            <button
              className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue text-right"
              onClick={addUser}
            >
              + Add
            </button>
          </div>
          <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
              <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                <div className="flex items-center justify-between w-full" >
                  <Search
                    setSearchResult={setSearchResult}
                    searchResult={searchResult}
                    currentPage={setCurrentPage}
                  />
                  <div className="relative items-center justify-end ml-auto">
                    <select
                      className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                      id="grid-state"
                      value={filterStatus}
                      onChange={handleStateChange}
                    >
                      <option value="" hidden>
                        Select Region
                      </option>

                      {hcpRegionList && hcpRegionList.length > 0 && (
                        hcpRegionList.map((state: any, index) => (
                          <option key={index} value={state.id} >
                            {state.name}
                          </option>
                        ))
                      )}
                      <option value="" hidden={filterStatus == ''} disabled={filterStatus !== filterStatus}>All</option>

                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <span className="icon inline-block mx-2 sm:ml-0" onClick={() => syncCustomerhandler()} title='Click here to sync'>
                    <svg
                      className="cursor-pointer hover:animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20px"
                      viewBox="0 0 24 24"
                      id="reload"
                    >
                      <path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                  <table className="w-full text-left table-auto">
                    <thead className="bg-logo text-white">
                      <tr>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[66px]">
                          Id
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[155px]">
                          Full Name
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[391px]">
                          Email
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[170px]">
                          Phone
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[166px]">
                          Membership
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[99px]">
                          State
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[124px]">
                          Zipcode
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[174px]">
                          Created at
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[124px]">
                          Properties
                        </th>
                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[130px]">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading || isCustomerSync?.isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <ClipLoader color="#f27366" size={50} />
                        </div>
                      ) : <>
                        {userListData?.length ? userListData.map((data: any, index: any) => {
                          const isGrayRow = index % 2 === 0;
                          return (
                            <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.id}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.first_name?.charAt(0)?.toUpperCase() + data?.first_name?.slice(1)}  {data?.last_name?.charAt(0)?.toUpperCase() + data?.last_name?.slice(1)}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.email}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.mobile}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.membership_plan ? data?.membership_plan?.charAt(0,).toUpperCase() + data?.membership_plan?.slice(1) : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {stateData.map((state, index) => {
                                  const abbreviation = Object.keys(state)[0];
                                  const name = Object.values(state)[0];
                                  if (abbreviation == data?.state) {
                                    return (
                                      <>
                                        {name}
                                      </>
                                    )
                                  }
                                })}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                {data?.zipcode}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                              </td>
                              <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                <span className="flex items-center">
                                  <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                    onClick={() => { navigate(`/users/address-list/${data.id}`, { state: data }) }}
                                  >
                                    <button className="text-xs font-bold text-black uppercase" >
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                        <title>Property List</title>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                      </svg>
                                    </button>
                                  </span>
                                  <p title="Count" className="cursor-pointer">{data?.address_count}</p>
                                </span>
                              </td>
                              <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                <div className="flex gap-x-2">
                                  <span className="flex items-center">
                                    <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                      onClick={() => { navigate(`/users/${data.id}`, { state: data }) }}
                                    >
                                      <button className="text-xs font-bold text-black uppercase w-[22px]" >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[45px]">
                                          <title>Edit</title>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                      </button>
                                    </span>
                                  </span>
                                  <span className="flex items-center">
                                    <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                      onClick={() => deleteUserHandler(data?.id)}
                                    >
                                      <button className=" text-xs font-bold text-black uppercase w-[22px]" >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[45px]">
                                          <title>Delete</title>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                      </button>
                                    </span>
                                  </span>
                                  <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                    onClick={() => { navigate(`/users/logs/${data.id}`, { state: data }) }}
                                  >
                                    <button className="text-xs font-bold text-black uppercase w-[22px]" >
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[45px]">
                                        <title>User logs</title>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                      </svg>
                                    </button>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                          :
                          <tr>
                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                              <div>No Data Found</div>
                            </td>
                          </tr>}
                      </>}
                    </tbody>
                  </table>
                  {showDeleteConfirmation &&
                    <>
                      <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setShowDeleteConfirmation(false)}>
                        <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded'>
                          <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg></button>
                          <div className="content p-10 ">
                            <p className="text-center">Are you sure you want to delete this user?</p>
                            <div className="flex justify-center gap-x-5 mt-3">
                              <button onClick={() => setShowDeleteConfirmation(false)} className="text-center bg-red text-white px-5 py-2 w-[68px] h-[40px] inline-block">No</button>
                              <button type="button" onClick={() => confirmDeleteUser(selectedUserId)} className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block">
                                {isUserDeleted?.isLoading &&
                                  <ClipLoader color="#FFFFFF" size={20} />
                                }
                                {
                                  !isUserDeleted?.isLoading &&
                                  (
                                    <>
                                      Yes
                                    </>
                                  )
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center my-12">
            {
              userListData && userListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={count}
                pageSize={PageSize}
                onPageChange={setCurrentPage}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListing;
