import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <main className="grid min-h-screen place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600 italic">404</p>
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 inline-flex items-center justify-center gap-x-6 rounded-md bg-logo px-3.5 py-2.5 text-white shadow-sm cursor-pointer">
          <span onClick={() => { navigate("/") }} className="text-sm font-semibold"
          >Go Back</span>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage