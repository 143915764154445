import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Loader from "../../components/loader/Loader";
import { editAppConfig } from "../../feature/AppConfig/EditConfig-Slice";
import { Breadcrumb } from "../../components/breadcrumb";

const AppConfigInfoListing = () => {
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { isLoading } = useSelector((state: any) => state?.appConfigList);
    const [editingData, setEditingData]: any = useState(null);
    const [updatedListData, setUpdatedListData]: any = useState([]);
    const [membershipId, setMemberShipId] = useState('')
    const [membershipTitle, setMembershipTitle] = useState('')

    const editConfig = (data: any) => {
        setEditingData(data);
    };

    const handleTextChange = (event: any, index: number, field: string) => {
        const { value } = event.target;
        const updatedData = [...updatedListData];
        updatedData[index].info[field] = value;
        setUpdatedListData(updatedData);
    };

    useEffect(() => {
        // Set the initial list data to state?.info
        setUpdatedListData(state?.info);
        setMemberShipId(state?.id);
        setMembershipTitle(state?.title)
    }, []);

    const saveEditConfig = () => {
        dispatch(editAppConfig({ appConfigDetail: updatedListData, configId: membershipId, title: membershipTitle })).then((data: any) => {
            if (data?.meta?.requestStatus == 'fulfilled') {
                navigate('/app-config')
            }
        }
        )
    }
    const isAppInfoEdited = useSelector((state: any) => state?.editConfig)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={`Contact One Home List`} />
                    <Breadcrumb
                        paths={[('dashboard'), ('app-config')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/app-config']}
                    />
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                    <div className="flex flex-col">
                                    </div>
                                    <div className="flex items-center sm:mt-4 sm:w-full sm:flex-row-reverse sm:justify-center">
                                        <div className="flex justify-end">
                                            <button
                                                className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue  w-[63px] h-[32px] inline-block"
                                                onClick={() => saveEditConfig()}
                                                disabled={isAppInfoEdited?.isLoading}
                                            >
                                                {isAppInfoEdited?.isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isAppInfoEdited?.isLoading &&
                                                    (
                                                        <>
                                                            Save
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]">
                                        <table className="w-full text-left table-auto">
                                            <thead className="bg-logo text-white">
                                                <tr>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[274px]">
                                                        Id
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[574px]">
                                                        Heading
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[584px]">
                                                        Text
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[205px]">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                        <ClipLoader color="#f27366" size={50} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {updatedListData?.length ? (
                                                            updatedListData?.map((data: any, index: any) => {
                                                                const isGrayRow = index % 2 === 0;
                                                                const isEditing = editingData && editingData.id === data.id;

                                                                return (
                                                                    <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                            {data?.id}
                                                                        </td>
                                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                            {isEditing ? (
                                                                                <input
                                                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                    type="text"
                                                                                    value={updatedListData[index]?.info?.heading || ''}
                                                                                    onChange={(event) => handleTextChange(event, index, "heading")}
                                                                                />
                                                                            ) : (
                                                                                data?.info?.heading || '-'
                                                                            )}
                                                                        </td>
                                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                            {isEditing ? (
                                                                                <input
                                                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                    type="text"
                                                                                    value={updatedListData[index]?.info?.text || ''}
                                                                                    onChange={(event) => handleTextChange(event, index, "text")}
                                                                                />
                                                                            ) : (
                                                                                data?.info?.text || '-'
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {isEditing ? (
                                                                                ''
                                                                            ) : (
                                                                                <button className="text-xs font-bold text-black uppercase" onClick={() => editConfig(data)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                        <title>Edit</title>
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                                    </svg>
                                                                                </button>

                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td
                                                                    colSpan={8}
                                                                    className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center"
                                                                >
                                                                    <div>No Data Found</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppConfigInfoListing;
