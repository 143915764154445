
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IExportTaxonomy {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    id: number;
    value?: boolean
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const exportTaxonomy: any = createAsyncThunk(
    "ExportTaxonomySlice",
    async ({ id, value }: IExportTaxonomy) => {

        try {
            const response: any = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.taxonomy.exportTaxonomy}/${id}?isSampleFile=${value}`);
            return response
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
);

export const ExportTaxonomySlice = createSlice({
    name: "ExportTaxonomySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(exportTaxonomy.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(exportTaxonomy.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(exportTaxonomy.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const exportTaxonomySliceReducer = ExportTaxonomySlice.reducer;
