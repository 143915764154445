
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    region?: string;
    api_key?: string;
    status?: boolean;
    sg_email?: string
}

interface IEditConfig {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    configId: number;
    title: string;
    appConfigDetail: {};
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const editAppConfig: any = createAsyncThunk(
    "EditConfigSlice",
    async ({ configId, appConfigDetail, title }: IEditConfig) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.appConfig.editConfig}`, {
                id: configId,
                title: title,
                info: appConfigDetail

            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const EditConfigSlice = createSlice({
    name: "EditConfigSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editAppConfig.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(editAppConfig.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(editAppConfig.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const editConfigSliceReducer = EditConfigSlice.reducer;
