import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import {
  setLocalStorage,
  showToastErrorMessage,
  showToastSuccessMessage,
} from "../../utils/utils";
import Cookies from 'js-cookie';

interface ILogin {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  data: {
    password: string;
    email: string;
  };
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  email: "",
  password: "",
  details: {}
};

export const login: any = createAsyncThunk(
  "loginSlice",
  async ({ data }: ILogin) => {
    const setCrossSubdomainCookie = (name: any, value: any, hours: any) => {
      const assign = `${name}=${value};`
      const d = new Date()
      d.setTime(d.getTime() + hours * 60 * 60 * 1000)
      const expires = `expires=${d.toUTCString()};`
      const path = "path=/;"
      if (typeof document !== "undefined") {
        const domain = `domain=${document?.domain?.match(/[^\.]*\.[^.]*$/)?.[0] || document.domain
          };`
        document.cookie = assign + expires + path + domain
      }
    }
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASE_URL}${apiconfig.authentication.login}`,
        {
          email: data?.email,
          password: data?.password,
        }
      );
      if (response?.data?.user?.roles == "SUPER_ADMIN" || response?.data?.user?.roles == "ADMIN_USER") {
        const accessToken = response?.data?.accessToken;
        setCrossSubdomainCookie('loggedIn', true, 8)
        setLocalStorage("accessToken", accessToken);
        setLocalStorage('first_name', response?.data?.user?.first_name)
        setLocalStorage('last_name', response?.data?.user?.last_name)
        setLocalStorage('user_id', response?.data?.user?.id)
        showToastSuccessMessage(response.data.message);
        return response.data;
      } else {
        showToastErrorMessage('Access denied')
        throw new Error("Invalid response status");
      }
    } catch (error: any) {
      showToastErrorMessage(error?.response?.data?.message);
      throw error; // Re-throw the error to be caught by rejected case
    }
  }
);

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.password = payload.password;
      state.details = payload.user
    });
    builder.addCase(login.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const loginSliceReducer = loginSlice.reducer;
