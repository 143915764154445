import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Pagination from "../../components/common/Pagination";
import Search from "../../components/search/Search";
import moment from "moment";
import { useForm } from "react-hook-form";
import { addressRequestlist } from "../../feature/AddressRequest/AddressRequest-Slice";
import { changeAddressStatusAddReq } from "../../feature/AddressRequest/ChangeStatus-Slice";

let PageSize = 1;

interface IFormInputs {
    reason: string
}

const BillingAddressRequestListing = () => {
    const [searchResult, setSearchResult] = useState("");
    const [addressList, setAddressList]: any = useState([]);
    const [currentPage, setCurrentPage]: any = useState();
    const [searchCount, setSearchCount] = useState(0)
    const [count, setCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState('')
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showRejectionConfirmation, setShowRejectionConfirmation] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [addStatus, setAddStatus] = useState('')
    const [rejectReason, setRejectReason] = useState('')
    const [userRejected, setUserRejected] = useState({
        rejection_reason: '',
        street: '',
        street_line_2: '',
        city: '',
        state: ''
    })
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);

    const { isLoading } = useSelector((state: any) => state?.addressRequestList)
    const changeStatus = useSelector((state: any) => state?.changeAddressStatus)

    const statusChangeHandler = async () => {

        const isValid = await trigger();
        if (isValid) {
            dispatch(changeAddressStatusAddReq({ status: addStatus, id: selectedUserId, rejection_reason: rejectReason })).then((val: any) => {
                if (val?.meta?.requestStatus == "fulfilled") {
                    addressRequestData()
                }
                setShowDeleteConfirmation(!showDeleteConfirmation)
            })
        }
    }

    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            addressRequestData();
        }

    }, [currentPage, navigate, searchResult, filterStatus]);

    useEffect(() => {
        if (showDeleteConfirmation) {
            setRejectReason('')
            reset();
        }
    }, [showDeleteConfirmation])

    const addressRequestData = () => {
        dispatch(addressRequestlist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            status: filterStatus,
            address_type: 'billing'
        })).then((val: any) => {
            setAddressList(val?.payload?.data?.data?.list);
            setCount(val?.payload?.data?.data?.totalRecords);
            setSearchCount(val?.payload?.data?.data?.count);
        }
        )
    }

    const deleteUserHandler = (status: string, id: any) => {
        setSelectedUserId(id);
        setAddStatus(status)
        setShowDeleteConfirmation(true);
    }
    const {
        register,
        formState: { errors },
        setValue,
        handleSubmit,
        reset,
        trigger
    }: any = useForm<IFormInputs>();

    const showRejectionPopup = (reason: any) => {
        setUserRejected(reason)
        setShowRejectionConfirmation(!showRejectionConfirmation)
    }

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Billing Address Requests`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex items-center justify-between w-full" >
                                    <Search
                                        setSearchResult={setSearchResult}
                                        searchResult={searchResult}
                                        currentPage={setCurrentPage}
                                    />
                                </div>
                                <div className="relative w-[175px]">
                                    <select
                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                        id="grid-state"
                                        value={filterStatus}
                                        onChange={(e) => {
                                            setFilterStatus(e?.target?.value)
                                            setCurrentPage(1)
                                        }}
                                    >
                                        <option value="" hidden>
                                            Select status
                                        </option>
                                        <option value="Pending">Pending</option>
                                        <option value="Approved">Approved</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="" hidden={filterStatus == ''} disabled={filterStatus !== "Pending" && filterStatus !== "Approved" && filterStatus !== "Rejected"}>All</option>
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-scroll overflow-custom-scroll overflow-hidden">
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4">
                                                    Full Name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4">
                                                    Address
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Region
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Zipcode
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Latitude <br />
                                                    Longitude
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Membership Plan
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Status
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Requested At
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {addressList?.length ? addressList.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.first_name?.charAt(0)?.toUpperCase() + data?.first_name?.slice(1)} {data?.last_name?.charAt(0)?.toUpperCase() + data?.last_name?.slice(1)}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.email}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.street ? data.street.split(',').map((str: any, index: any) => (
                                                                    <div key={index}>{str.trim()},</div>
                                                                )) : ''}
                                                                {data?.street_line_2 ? data.street_line_2.split(',').map((str: any, index: any) => (
                                                                    <div key={index}>{str.trim()},</div>
                                                                )) : ''}
                                                                {data?.city ? data.city.split(',').map((str: any, index: any) => (
                                                                    <div key={index}>{str.trim()},</div>
                                                                )) : ''}
                                                                {data?.state ? data.state.split(',').map((str: any, index: any) => (
                                                                    <div key={index}>{str.trim()}</div>
                                                                )) : ''}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.region}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.zip}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.lat !== 'undefined' && data?.long !== 'undefined' ? <>{data.lat},<br />{data.long}</> : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.membership_plan ? data?.membership_plan?.charAt(0)?.toUpperCase() + data?.membership_plan?.slice(1) : '-'}
                                                            </td>
                                                            <td className="align-middle relative">
                                                                <div className="flex items-center">
                                                                    <span className={data?.status == 'Approved' ? `px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap bg-green text-white rounded dark:bg-green-900` : data?.status == 'Rejected' ? `px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap bg-red text-white font-medium mr-2 rounded dark:bg-red-900 dark:text-red-300` : `px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap bg-yellow text-white font-medium mr-2 rounded dark:bg-yellow-900 dark:text-yellow-300`}> {data?.status}</span>
                                                                    {data?.status == 'Rejected' &&
                                                                        <span onClick={() => showRejectionPopup(data)} className='inline-block w-[24px] cursor-pointer'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                <title>Rejection Reason</title>
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                                                            </svg>
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <div className="flex gap-x-2">
                                                                    {data?.status == 'Pending' ?
                                                                        <>
                                                                            <span className="flex items-center">
                                                                                <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                    onClick={() => deleteUserHandler('Rejected', data?.id)}
                                                                                >
                                                                                    <button className="px-4 py-2 text-xs font-bold text-white uppercase bg-red" >Reject</button>
                                                                                </span>
                                                                            </span>

                                                                            <span className="flex items-center">
                                                                                <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                    onClick={() => deleteUserHandler('Approved', data?.id)}
                                                                                >
                                                                                    <button className="px-4 py-2 text-xs font-bold text-white uppercase bg-green" >Approve</button>
                                                                                </span>
                                                                            </span>
                                                                        </>
                                                                        :
                                                                        <span className="ml-[25px]">-</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                    {showDeleteConfirmation &&
                                        <>
                                            <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)}>
                                                <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
                                                    <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg></button>
                                                    <form onSubmit={handleSubmit(statusChangeHandler)}>
                                                        <div className="content p-10 ">
                                                            <p className="text-center">Are you sure you want to {addStatus == 'Approved' ? 'Approve' : 'Reject'} this billing address?</p>
                                                            {addStatus == 'Rejected' &&
                                                                (
                                                                    <>
                                                                        <p className="text-left mt-[15px]">Rejection Reason:</p>
                                                                        <textarea
                                                                            {...register("rejectReason", {
                                                                                required: 'Reject reason is required.',
                                                                                maxLength: {
                                                                                    value: 100,
                                                                                    message: "Reject reason should not exceed 100 characters.",
                                                                                },
                                                                            })}
                                                                            className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                            id="grid-zipcode"
                                                                            value={rejectReason}
                                                                            placeholder='Please enter your rejection reason'
                                                                            onChange={(e) => {
                                                                                setRejectReason(e?.target?.value);
                                                                                setValue("rejectReason", e.target.value, {
                                                                                    shouldValidate: true,
                                                                                });
                                                                            }}
                                                                        />

                                                                        <p className="text-red text-sm">
                                                                            {errors?.rejectReason && errors.rejectReason.message}
                                                                        </p>
                                                                    </>
                                                                )
                                                            }
                                                            <div className="flex justify-center gap-x-5 mt-3">
                                                                <button onClick={() => setShowDeleteConfirmation(false)} className="text-center bg-red text-white px-5 py-2 w-[68px] h-[40px] inline-block">No</button>
                                                                <button type="submit" className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block" disabled={changeStatus?.isLoading}>
                                                                    {changeStatus?.isLoading &&
                                                                        <ClipLoader color="#FFFFFF" size={20} />
                                                                    }
                                                                    {
                                                                        !changeStatus?.isLoading &&
                                                                        (
                                                                            <>
                                                                                Yes
                                                                            </>
                                                                        )
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </>}

                                    {showRejectionConfirmation &&
                                        <>
                                            <div className='modal-wrapper fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center' onClick={() => setShowRejectionConfirmation(!showRejectionConfirmation)}>
                                                <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
                                                    <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowRejectionConfirmation(false)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg></button>

                                                    <div className="content p-10 max-w-[600px] ">
                                                        <p className="text-center mb-[30px]">Rejection Reason</p>
                                                        <p className="text-center mb-[30px]">{`${'Billing Address :'} ${userRejected?.street},${userRejected?.street_line_2},${userRejected?.city},${userRejected?.state}`}</p>
                                                        <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white text-center ">{`${'Reason :'} ${userRejected?.rejection_reason ? userRejected?.rejection_reason : '-'}`}
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>

                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-14">
                        {
                            addressList && addressList.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={setCurrentPage}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingAddressRequestListing;
