
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
  first_name?: string;
  last_name?: string
  membership_plan?: string;
  mobile?: any;
  zipcode?: number;
  state?: string;
  city?: string;
  address?: string;
  address_line_1?: string;
  is_active?: boolean;
  is_ohs_onboard?: boolean
}

interface IEditUser {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  userId: number;
  userStatus: boolean;
  onBoardStatus: boolean;
  userDetail: IData;
  membership_data?: {};
  memPlan?: string;
  selectedCoordLat?: any;
  selectedCoordLong?: any
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  userDetail: []
};

export const editUser: any = createAsyncThunk(
  "UserListSlice",
  async ({ userDetail, userId, membership_data, memPlan, selectedCoordLat, selectedCoordLong }: IEditUser) => {
    try {
      const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.editUser}/${userId}`, {
        first_name: userDetail?.first_name,
        last_name: userDetail?.last_name,
        membership_plan: memPlan,
        mobile: parseInt(userDetail?.mobile),
        zipcode: userDetail?.zipcode,
        state: userDetail?.state,
        city: userDetail?.city,
        address: userDetail?.address,
        address_line_1: userDetail?.address_line_1,
        is_active: userDetail?.is_active,
        is_ohs_onboard: userDetail?.is_ohs_onboard,
        membership_data: membership_data,
        lat: selectedCoordLat,
        long: selectedCoordLong

      }).then((data: any) => {
        showToastSuccessMessage(data?.data?.message)
      })
      return response
    } catch (error: any) {
      showToastErrorMessage(error?.response?.data?.message)
      throw error
    }
  }
);

export const EditUserSlice = createSlice({
  name: "EditUserSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(editUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(editUser.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const editUserSliceReducer = EditUserSlice.reducer;
