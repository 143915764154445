import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    appConfigList: []
};

export const appConfiglist: any = createAsyncThunk(
    "AppConfigListSlice",
    async () => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.appConfig?.appConfigList}`)
        return response
    }
);

export const AppConfigListSlice = createSlice({
    name: "AppConfigListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(appConfiglist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(appConfiglist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(appConfiglist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const appConfigListSliceReducer = AppConfigListSlice.reducer;
