const Title = (props: any) => {
  return (
    <div className='title'>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col mb-[5px]">
          <div className="text-xl font-bold">{props.title}</div>
        </div>
      </div>
    </div>
  )
}

export default Title