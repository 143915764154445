
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IImportTaxonomy {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    regionId: number;
    formData?: any;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const importTaxonomy: any = createAsyncThunk(
    "ImportTaxonomySlice",
    async ({ formData, regionId }: IImportTaxonomy) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.taxonomy.importTaxonomy}/${regionId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const ImportTaxonomySlice = createSlice({
    name: "ImportTaxonomySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(importTaxonomy.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
        });
        builder.addCase(importTaxonomy.fulfilled, (state) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(importTaxonomy.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const importTaxonomySliceReducer = ImportTaxonomySlice.reducer;
