import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { zipcodelist } from "../../feature/Zipcode/ZipcodeList-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import { deleteZipcode } from "../../feature/Zipcode/DeleteZipcode-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

let PageSize = 1;

const ZipcodeListing = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [zipCodeListData, setZipCodeListData] = useState([])
    const [searchCount, setSearchCount] = useState(0)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedZipcodeId, setSelectedZipcodeId] = useState(null);
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [filterStatus, setFilterStatus]: any = useState('')

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            ZipcodeList();
        }
    }, [currentPage, navigate, searchResult, filterStatus]);

    const ZipcodeList = () => {
        dispatch(zipcodelist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            region: filterStatus,
        })).then((data: any) => { setZipCodeListData(data?.payload?.data?.data?.zipcodeList); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.zipcode)
    const isZipcodeDeleted = useSelector((state: any) => state.deleteZipcode)


    const addZipcode = () => {
        navigate('/zipcode/new')
    }

    const deleteZipcodeHandler = (id: any) => {
        setSelectedZipcodeId(id)
        setShowDeleteConfirmation(true);
    }

    const confirmDeleteZipcode = (id: any) => {
        dispatch(deleteZipcode({ id })).then((val: any) => {
            if (val?.meta?.requestStatus == "fulfilled") {
                ZipcodeList()
            }
        });
        setShowDeleteConfirmation(false);
    }

    const handleStateChange = (e: any) => {
        setFilterStatus(e.target.value)
        setCurrentPage(1)
    };

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
    }, [])

    showDeleteConfirmation
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto')


    return (
        <>
            <div className="flex">
                <Sidebar />
                <div className="content w-full">
                    <Header />
                    <div className="fluid p-4 custom-height-wrapper">
                        <div className="flex justify-between items-center w-100 pb-5" >
                            <Title title={`Zipcode List`} />
                            <button
                                className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue text-right"
                                onClick={addZipcode}
                            >
                                + Add
                            </button>
                        </div>
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex items-center justify-end mb-6 sm:flex-wrap sm:justify-center">
                                    <div className="flex items-center justify-start mr-auto w-full" >
                                        <Search
                                            setSearchResult={setSearchResult}
                                            searchResult={searchResult}
                                            currentPage={setCurrentPage}
                                        />
                                    </div>
                                    <div className="relative w-full max-w-[180px] items-center justify-end ml-[650px]">
                                        <select
                                            className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                            id="grid-state"
                                            value={filterStatus}
                                            onChange={handleStateChange}
                                        >
                                            <option value="" hidden>
                                                Select Region
                                            </option>
                                            {hcpRegionList && hcpRegionList.length > 0 && (
                                                hcpRegionList.map((state: any, index) => (
                                                    <option key={index} value={state.region} >
                                                        {state.name}
                                                    </option>
                                                ))
                                            )}
                                            <option value="" hidden={filterStatus == ''} disabled={filterStatus !== filterStatus}>All</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                        <table className="w-full text-left table-auto">
                                            <thead className="bg-logo text-white">
                                                <tr>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[74px]">
                                                        Id
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[110px]">
                                                        Zipcode
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[180px]">
                                                        State
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[274px]">
                                                        Created at
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[274px]">
                                                        Updated at
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[100px]">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                        <ClipLoader color="#f27366" size={50} />
                                                    </div>
                                                ) : <>

                                                    {zipCodeListData?.length ? zipCodeListData.map((data: any, index: any) => {
                                                        const isGrayRow = index % 2 === 0;
                                                        return (
                                                            <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.id}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.zipcode ? data?.zipcode : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.state ? data?.state : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.updated_at ? moment(data?.updated_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                                </td>

                                                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                    <div className="flex gap-x-2">
                                                                        <span className="flex items-center">
                                                                            <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                onClick={() => { navigate(`/zipcode/${data.id}`, { state: data }) }}
                                                                            >
                                                                                <button className="text-xs font-bold text-black uppercase" >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                        <title>Edit</title>
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                                    </svg>
                                                                                </button>
                                                                            </span>
                                                                        </span>
                                                                        <span className="flex items-center">
                                                                            <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                onClick={() => deleteZipcodeHandler(data?.id)}
                                                                            >
                                                                                <button className=" text-xs font-bold text-black uppercase" >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                        <title>Delete</title>
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                                    </svg>
                                                                                </button>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                <div>No Data Found</div>
                                                            </td>
                                                        </tr>}
                                                </>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center my-12">
                            {
                                zipCodeListData && zipCodeListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={count}
                                    pageSize={PageSize}
                                    onPageChange={(page: React.SetStateAction<number>) =>
                                        setCurrentPage(page)
                                    }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showDeleteConfirmation &&
                <>
                    <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setShowDeleteConfirmation(false)}>
                        <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded'>
                            <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg></button>
                            <div className="content p-10 ">
                                <p className="text-center">Are you sure you want to delete this zipcode?</p>
                                <div className="flex justify-center gap-x-5 mt-3">
                                    <button onClick={() => confirmDeleteZipcode(selectedZipcodeId)} className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block">

                                        {isZipcodeDeleted?.isLoading &&
                                            <ClipLoader color="#FFFFFF" size={20} />
                                        }
                                        {
                                            !isZipcodeDeleted?.isLoading &&
                                            (
                                                <>
                                                    Yes
                                                </>
                                            )
                                        }
                                    </button>
                                    <button onClick={() => setShowDeleteConfirmation(false)} className="text-center bg-red text-white px-5 py-2">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
};

export default ZipcodeListing;
