import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userjobsdetails } from "../../feature/Users/UserJobs-Slice";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from '../../components/sidebar/Sidebar';
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import Loader from "../../components/loader/Loader";

let PageSize = 1;

const UserJobsListing = () => {
    const [searchResult, setSearchResult] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0)
    const [searchCount, setSearchCount] = useState(0)
    const [userJobsListData, setUserJobsListData]: any = useState([])
    const [fullName, setFullName]: any = useState({
        firstName: '',
        lastName: ''
    })

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const prop = location?.state?.query?.id
    const prop2 = location?.state?.prop2 ?? location.state
    const temp = location?.state?.property

    const add = location?.state?.data?.street ?? location?.state?.prop2?.data?.street

    useEffect(() => {
        UserJobList();
    }, [searchResult, currentPage, window.location.pathname]);

    const UserJobList = () => {
        dispatch(userjobsdetails({
            user_id: params?.id,
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
        })).then((data: any) => {
            setUserJobsListData(data?.payload?.data?.data?.jobList); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count);
            setFullName((preValue: any) => {
                return {
                    ...preValue,
                    firstName: data?.payload?.data?.data?.firstName,
                    lastName: data?.payload?.data?.data?.lastName
                }
            })
        }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.userjobDetail)

    return (
        <>
            <div className="flex">
                <Sidebar />
                <div className="content w-full">
                    <Header />
                    <div className="fluid p-4 custom-height-wrapper">
                        <Title title={`Jobs List of ${add} (${fullName?.firstName?.charAt(0)?.toUpperCase() + fullName?.firstName?.slice(1)} ${fullName?.lastName?.charAt(0)?.toUpperCase() + fullName?.lastName?.slice(1)})`} />
                        <Breadcrumb
                            paths={[
                                ('dashboard'), `${location?.state?.property == 'ALL-PROP' ? ('users-properties') : ('users')}`, `${location?.state?.property == 'ALL-PROP' ? ('') : (`${'Property-list'} of ${fullName?.firstName} ${fullName?.lastName}`)}`
                            ]}
                            className="text-sm mb-3"
                            url={`${location?.state?.property == 'ALL-PROP' ? '' : `${'users/address-list'}/${prop ? prop : location?.state.prop}`}`}
                        />
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                                <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                    <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                        <div className="flex items-center justify-start w-full" >
                                            <Search
                                                setSearchResult={setSearchResult}
                                                searchResult={searchResult}
                                                currentPage={setCurrentPage}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                                            <table className="w-full text-left table-auto">
                                                <thead className="bg-logo text-white">
                                                    <tr>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[104px]">
                                                            Id
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[257px]">
                                                            Scheduled start
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[249px]">
                                                            Scheduled end
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[280px]">
                                                            Title
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[220px]">
                                                            Work status
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[350px]">
                                                            Assigned Employee
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[269px]">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? (
                                                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                            <ClipLoader color="#f27366" size={50} />
                                                        </div>
                                                    ) : <>
                                                        {userJobsListData?.length ? userJobsListData.map((data: any, index: any) => {
                                                            const isGrayRow = index % 2 === 0;
                                                            return (
                                                                <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                        {data?.id}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                        {data?.scheduled_start ? data?.scheduled_start : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                        {data?.scheduled_end ? data?.scheduled_end : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                        {data.title.length >= 50
                                                                            ? data.title.slice(0, 50) + '...'
                                                                            : data.title || '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                        {data?.work_status ? data?.work_status.charAt(0).toUpperCase() + data?.work_status?.slice(1) : '-'}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.assigned_employees.length > 0 ? (
                                                                            data.assigned_employees.map((val: any) => (
                                                                                <div key={val.id}>
                                                                                    {val.first_name && val.last_name ? (
                                                                                        <>{val.first_name} {val.last_name}</>
                                                                                    ) : (
                                                                                        '-'
                                                                                    )}
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </td>
                                                                    <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                        <span className="flex items-center">
                                                                            <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                onClick={() => { navigate(`/users/job_details/${data.id}`, { state: { ...location.state, data, params, prop, prop2, temp, add } }) }}
                                                                            >
                                                                                <button className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue" >View</button>
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                    <div>No Data Found</div>
                                                                </td>
                                                            </tr>}
                                                    </>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col items-center my-12">
                            {
                                userJobsListData && userJobsListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={count}
                                    pageSize={PageSize}
                                    onPageChange={(page: React.SetStateAction<number>) =>
                                        setCurrentPage(page)
                                    }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserJobsListing;
