import { useState, useEffect, useRef, useContext } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Loader from "../../components/loader/Loader";
import Pagination from "../../components/common/Pagination";
import { adminindustryCategorylist } from "../../feature/Taxonomy/AdminIndustryCategory-Slice";
import { Breadcrumb } from "../../components/breadcrumb";
import { sortTaxonomy } from "../../feature/Taxonomy/SortTaxonomy-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import Switch from "react-switch";
import { editTaxoStatus } from "../../feature/Taxonomy/EditTaxonomyStatus-Slice";
import RegionContext from "../../utils/RegionContext";

let PageSize = 1;

const IndustryCategoryList = () => {
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState(1);
    const query = useParams()
    const [count, setCount] = useState(0)
    const [adminIndustryCategoryData, setAdminIndustryCategoryData] = useState([])
    const [searchCount, setSearchCount] = useState(0)
    const [industryName, setIndustryName] = useState('')
    const { isSuccess } = useSelector((state: any) => state?.sortTaxonomy)
    const location = useLocation();
    const [regionUtahId, setRegionUtahId] = useState()
    const { contextRegionId, setContextRegionId }: any = useContext(RegionContext)
    const [filterStatus, setFilterStatus]: any = useState(contextRegionId ? contextRegionId : regionUtahId)
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [isLoad, setIsLoad] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const tdRefs: any = useRef([]);

    useEffect(() => {
        if (filterStatus) {
            adminIndustry();
            setIsLoad(true)
        }
    }, [currentPage, navigate, searchResult, filterStatus]);


    useEffect(() => {
        if (isSuccess) {
            adminIndustry();
        }

    }, [isSuccess])

    const adminIndustry = () => {
        dispatch(adminindustryCategorylist({
            page: currentPage,
            limit: 100,
            search_text: searchResult,
            id: query?.id,
            filterStatus: contextRegionId ? contextRegionId : filterStatus
        })).then((data: any) => { setAdminIndustryCategoryData(data?.payload?.data?.categories); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count); setIndustryName(data?.payload?.data?.industryName) }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.adminindustrycategoryList)
    const id = query?.id

    const handleDrop = async (event: any, newIndex: any) => {
        event.preventDefault();
        const sourceIndex = parseInt(event.dataTransfer.getData('text/plain'));
        const draggedItem = adminIndustryCategoryData[sourceIndex];
        const newCategoryListData: any = adminIndustryCategoryData.filter((_, index) => index !== sourceIndex);
        newCategoryListData.splice(newIndex, 0, draggedItem);
        setAdminIndustryCategoryData(newCategoryListData);
        tdRefs.current = tdRefs.current.slice();

        const updatedSortOrder = newCategoryListData?.map((item: any, index: any) => ({
            id: item?.category_id,
            display_order: index + 1,
            region_id: parseInt(filterStatus)
        }));
        const sort_type = 'category'
        dispatch(sortTaxonomy({ updatedSortOrder, sort_type }))

    };

    const handleDragStart = (event: any, index: any) => {
        event.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    const handleStateChange = (e: any) => {
        setFilterStatus(e.target.value)
        setCurrentPage(1)
        setContextRegionId(e.target.value)
    };

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => {
            setHCPRegionList(val?.payload?.data?.data);
            if (!contextRegionId) {
                const temp = val?.payload?.data?.data.find((val: any) => val?.region == 'UT').id
                setRegionUtahId(temp)
                setFilterStatus(temp)
            }
        })
    }, [])

    const handleToggle = (id: string, e: any) => {
        const updatedTaxonomyList: any = adminIndustryCategoryData.map((taxonomy: any) => {
            if (taxonomy.category_id === id) {
                const updatedEmployee = { ...taxonomy, is_active: !taxonomy.is_active };
                dispatch(editTaxoStatus({ industryId: id, regionId: filterStatus, taxonomyType: 'Category', status: e }))
                return updatedEmployee;
            }
            return taxonomy;
        });

        setAdminIndustryCategoryData(updatedTaxonomyList);
    };


    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Category Lists of ${industryName}`} />
                        <Breadcrumb
                            paths={[('dashboard'), ('industries')]}
                            className="text-sm mb-3"
                            url={['/dashboard', '/industry-list']}
                        />
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                    <div className="flex items-center justify-start mr-auto w-full" >
                                        <Search
                                            setSearchResult={setSearchResult}
                                            searchResult={searchResult}
                                            currentPage={setCurrentPage}
                                        />
                                    </div>
                                    <div className="relative w-full max-w-[180px] items-center justify-end ml-[650px]">
                                        <select
                                            className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                            id="grid-state"
                                            value={filterStatus}
                                            onChange={handleStateChange}
                                        >
                                            {hcpRegionList && hcpRegionList.length > 0 && (
                                                hcpRegionList.map((state: any, index) => (
                                                    <option key={index} value={state.id} >
                                                        {state.name}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                        <table className="w-full text-left table-auto">
                                            <thead className="bg-logo text-white">
                                                <tr>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                        Display Order
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[319px]">
                                                        Category Name
                                                    </th>
                                                    {/* <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[212px]">
                                                        Status
                                                    </th> */}
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[125px]">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(isLoading) || (!contextRegionId && !regionUtahId) ? (
                                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                        <ClipLoader color="#f27366" size={50} />
                                                    </div>
                                                ) : <>
                                                    {adminIndustryCategoryData?.length ? adminIndustryCategoryData.map((data: any, index: any) => {
                                                        const isGrayRow = index % 2 === 0;
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className={isGrayRow ? 'bg-gray-table cursor-grab' : 'cursor-grab'}
                                                                draggable
                                                                onDragStart={(event) => handleDragStart(event, index)}
                                                                onDragOver={handleDragOver}
                                                                onDrop={(event) => handleDrop(event, index)}
                                                            >
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                    {data?.category_name ? data?.category_name : '-'} ({data?.sub_category_count ? data?.sub_category_count : '-'})
                                                                </td>
                                                                {/* <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                                                    <Switch
                                                                        checked={data.is_active}
                                                                        onChange={(e: any) => handleToggle(data?.category_id, e)}
                                                                        onColor="#f27367"
                                                                        onHandleColor="#fff"
                                                                        handleDiameter={22}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={15}
                                                                        width={48}
                                                                        className="react-switch"
                                                                        id="material-switch"
                                                                    />
                                                                </td> */}
                                                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                    <span className="flex items-center">
                                                                        <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                            onClick={() => { navigate(`/industry-list/sub-category/${data?.category_id}`, { state: { data, id, industryName, filterStatus } }) }}
                                                                        >
                                                                            <button className="text-xs font-bold text-black uppercase" >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                    <title>View</title>
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                <div>{isLoad ? 'No Data Found' : ''}</div>
                                                            </td>
                                                        </tr>}
                                                </>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col items-center my-12">
                        {
                            adminIndustryCategoryData && adminIndustryCategoryData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndustryCategoryList;
