
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IViewEstimateRequest {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  estimateId:number;
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data:[]
};

export const viewestimaterequest: any = createAsyncThunk(
  "ViewEstimateRequest",
  async (estimateId: IViewEstimateRequest) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.estimate.vieweEstimateRequest}/${estimateId}`)
    return response
  }
);

export const ViewEstimateRequestSlice = createSlice({
  name: "ViewEstimateRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewestimaterequest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewestimaterequest.fulfilled, (state, { payload }) => {      
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(viewestimaterequest.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const viewEstimateRequestReducer = ViewEstimateRequestSlice.reducer;
