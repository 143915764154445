import Sidebar from '../components/sidebar/Sidebar'
import Header from '../components/header/Header'
import Title from '../components/title/Title'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { dashboardCount } from '../feature/Dashboard/DashboardCount-Slice'

const DashboardPage = () => {

  const dispatch = useDispatch()
  const [showDashboardCount, setShowDashboardCount] = useState({
    totalActiveUsers: '',
    totalUsers: '',
    totalJobs: '',
    totalEstimations: '',
    totalAddresses: '',
    totalPendingServiceAddress: '',
    totalPendingBillingAddress: '',
    totalCancelJobs: '',
    totalScheduledJobs: '',
    totalCompletedJobs: '',
    totalAndroidUsers: '',
    totalIosUsers: ''
  })

  const [regionZipcode, setRegionZipcode] = useState([])
  const [userWiseZipcode, setUserWiseZipcode] = useState([])


  useEffect(() => {
    dispatch(dashboardCount()).then((data: any) => {
      setShowDashboardCount(data?.payload?.data?.data)
      setRegionZipcode(data?.payload?.data?.data?.zipcodeCounts)
      setUserWiseZipcode(data?.payload?.data?.data?.totalRegionUser)
    }
    )
  }, [])

  return (
    <div className='flex'>
      <Sidebar />
      <div className='content w-full h-full'>
        <Header />
        <div className='fluid p-4'>
          <div className='pb-3'>
            <Title title={"Dashboard"} />
          </div>
          <div className='mt-8 box-d'>
            <div className='border-bottom'>
              <Title title={"Users"} />
            </div>
            <div className='flex my-1 -mx-1 lg:flex-col flex-wrap w-full flex-row lg:mb-4'>
              <div className='w-1/4 lg:w-full p-1 '>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Users</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalUsers}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 stroke-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1 '>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Active Users</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalActiveUsers}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 stroke-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1 '>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">IOS Users</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalIosUsers}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 stroke-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1 '>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Android Users</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalAndroidUsers}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 stroke-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8 box-d'>
            <div className='border-bottom'>
              <Title title={"Region Users"} />
            </div>
            <div className='flex my-1 -mx-1 lg:flex-col flex-wrap w-full mb-2 flex-row lg:mb-4'>
              {userWiseZipcode?.map((val: any) =>
                <div className='w-1/4 lg:w-full p-1'>
                  <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                    <div className='flex flex-row items-center justify-between'>
                      <div className='flex flex-col'>
                        <div className="text-sm font-bold text-gray-500 uppercase">{val?.name}</div>
                        <div className="text-xl font-bold mt-1">{val?.total_user}</div>
                      </div>
                      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500 stroke-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='mt-8 box-d'>
            <div className='border-bottom'>
              <Title title={"Properties"} />
            </div>
            <div className='flex my-1 -mx-1 lg:flex-col flex-wrap w-full mb-2 flex-row lg:mb-4'>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Properties</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalAddresses}</div>
                    </div>
                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='h-6 w-6'>
                      <path d="M6 7H7M6 10H7M11 10H12M11 13H12M6 13H7M11 7H12M7 21V18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18V21H7ZM7 21H3V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H13.4C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V9M19.7 13.5C19.7 14.3284 19.0284 15 18.2 15C17.3716 15 16.7 14.3284 16.7 13.5C16.7 12.6716 17.3716 12 18.2 12C19.0284 12 19.7 12.6716 19.7 13.5ZM21.5 21V20.5C21.5 19.1193 20.3807 18 19 18H17.5C16.1193 18 15 19.1193 15 20.5V21H21.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Pending Billing Address</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalPendingBillingAddress}</div>
                    </div>
                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='h-6 w-6'>
                      <path d="M6 7H7M6 10H7M11 10H12M11 13H12M6 13H7M11 7H12M7 21V18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18V21H7ZM7 21H3V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H13.4C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V9M19.7 13.5C19.7 14.3284 19.0284 15 18.2 15C17.3716 15 16.7 14.3284 16.7 13.5C16.7 12.6716 17.3716 12 18.2 12C19.0284 12 19.7 12.6716 19.7 13.5ZM21.5 21V20.5C21.5 19.1193 20.3807 18 19 18H17.5C16.1193 18 15 19.1193 15 20.5V21H21.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Pending Service Address</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalPendingServiceAddress}</div>
                    </div>
                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='h-6 w-6'>
                      <path d="M6 7H7M6 10H7M11 10H12M11 13H12M6 13H7M11 7H12M7 21V18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18V21H7ZM7 21H3V4.6C3 4.03995 3 3.75992 3.10899 3.54601C3.20487 3.35785 3.35785 3.20487 3.54601 3.10899C3.75992 3 4.03995 3 4.6 3H13.4C13.9601 3 14.2401 3 14.454 3.10899C14.6422 3.20487 14.7951 3.35785 14.891 3.54601C15 3.75992 15 4.03995 15 4.6V9M19.7 13.5C19.7 14.3284 19.0284 15 18.2 15C17.3716 15 16.7 14.3284 16.7 13.5C16.7 12.6716 17.3716 12 18.2 12C19.0284 12 19.7 12.6716 19.7 13.5ZM21.5 21V20.5C21.5 19.1193 20.3807 18 19 18H17.5C16.1193 18 15 19.1193 15 20.5V21H21.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8 box-d'>
            <div className='border-bottom'>
              <Title title={"Jobs"} />
            </div>
            <div className='flex my-1 -mx-1 lg:flex-col flex-wrap w-full mb-2 flex-row lg:mb-4'>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Jobs</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalJobs}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Scheduled Jobs</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalScheduledJobs}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Completed Jobs</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalCompletedJobs}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </div>
                </div>
              </div>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Cancelled Jobs</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalCancelJobs}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8 box-d'>
            <div className='border-bottom'>
              <Title title={"Estimations"} />
            </div>
            <div className='flex my-1 -mx-1 lg:flex-col flex-wrap w-full mb-2 flex-row lg:mb-4'>
              <div className='w-1/4 lg:w-full p-1'>
                <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                  <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className="text-sm font-bold text-gray-500 uppercase">Estimations Request</div>
                      <div className="text-xl font-bold mt-1">{showDashboardCount?.totalEstimations}</div>
                    </div>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8 box-d mb-[80px]'>
            <div className='border-bottom'>
              <Title title={"Zipcodes"} />
            </div>
            <div className='flex my-1 -mx-1 lg:flex-col flex-wrap w-full mb-2 flex-row lg:mb-4'>
              {regionZipcode?.map((val: any) =>
                <div className='w-1/4 lg:w-full p-1'>
                  <div className='w-full px-4 py-6 rounded-lg bg-white border border-solid border-black'>
                    <div className='flex flex-row items-center justify-between'>
                      <div className='flex flex-col'>
                        <div className="text-sm font-bold text-gray-500 uppercase">{val?.state}</div>
                        <div className="text-xl font-bold mt-1">{val?.count}</div>
                      </div>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        width="24px" height="24px" viewBox="0 0 164 256" enable-background="new 0 0 164 256">
                        <path d="M161.777,37.14c0-19.409-15.731-35.14-35.14-35.14c-19.409,0-35.14,15.731-35.14,35.14c0,4.87,1.01,10.729,2.781,14.953
	c6.731,16.055,24.315,58.217,30.316,72.607c-14.107-12.36-32.92-19.342-53.163-17.996c-40.704,2.353-71.643,37.41-69.055,78.114
	c2.47,40.586,37.528,71.408,78.231,69.055c40.704-2.47,71.643-37.527,68.938-78.231c-1.154-19.015-9.397-35.896-22-48.214
	c4.463-10.692,24.22-58.024,31.452-75.272C160.766,47.931,161.777,42.01,161.777,37.14z M72.371,245.168
	c-14.352-0.941-28.116-6.588-39.174-16.234c-13.058-11.529-20.705-27.528-21.881-44.703c-1.176-15.529,2.588-30.116,11.999-42.351
	c0.353,3.176,2.117,5.647,2.117,7.882c0,8.823-0.706,14.117,4.941,21.058c2.235,2.588,3.059,6.823,4,10.117
	c1.176,3.176,4.941,4.706,7.764,6.588c5.411,3.529,10.705,8.117,16.587,11.293c3.764,2.118,6,3.176,5.411,7.764
	c-0.706,3.529-0.706,5.882-2.47,9.176c-0.353,1.176,2.588,7.176,3.529,7.882c3.059,2.47,5.882,4.941,9.058,7.176
	C79.194,234.345,74.253,239.639,72.371,245.168z M132.956,192.348c0,0,3.764,1.176,6.823,0.353
	c-2.235,11.529-7.294,21.999-15.293,31.057c-8.823,10-19.999,16.94-32.587,19.999c1.529-4.47,4.47-8.823,7.294-11.294
	c2.47-2.235,5.411-6.235,6.588-9.176c1.176-3.176,2.588-5.882,4.47-8.823c2.235-4-6.941-9.646-9.999-10.588
	c-6.823-2.47-11.882-5.882-17.764-9.646c-4.353-2.588-17.293,3.412-22.234,1.529c-6.588-2.47-8.823-4.47-14.705-8.235
	c-6-4-4.353-12.47-4.706-18.705c4.47,0,10.705-1.882,13.999,1.529c0.941,1.176,4.47,5.882,6.588,4c2.235-2.353-1.176-8-1.882-9.294
	c-2.118-4,3.764-6.235,6.823-9.176c3.765-4,11.882-10.588,10.941-13.058c-0.941-2.588-9.176-10-13.764-8.235
	c-0.941,0-6.235,5.882-7.294,6.823c0-2.118-0.353-3.176-0.353-5.059c0-1.294-2.588-2.47-2.47-3.412
	c0.235-2.235,5.882-6.588,7.176-8.235c-1.177-0.706-4.941-3.412-5.882-3.059c-2.47,1.294-5.412,2.235-8.117,3.412
	c0-0.941-0.235-1.882-0.353-2.47c5.059-2.588,10.588-4.47,16.234-5.882l5.059,1.882l3.765,4l3.765,3.529
	c0,0,2.235,0.941,3.176,0.941c1.177-0.235,4.706-4.941,4.706-4.941l-1.529-3.529l-0.235-3.176c10.117,0.941,19.646,4,28.116,9.176
	c-1.294,0.235-3.176,0.353-4.941,0.941c-0.706-0.353-4.706,0.353-4.47,2.118c0.235,1.294,7.176,6.941,10.117,11.882
	c3.059,5.059,11.529,8.235,13.058,13.999c1.529,6.588-0.941,15.058,0.235,22.94C124.486,184.23,132.956,192.348,132.956,192.348z
	 M126.636,53.365c-8.822,0-15.975-7.153-15.975-15.975c0-8.823,7.153-15.975,15.975-15.975c8.823,0,15.975,7.153,15.975,15.975
	C142.612,46.212,135.459,53.365,126.636,53.365z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage