
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    zip?: number;
    state?: string;
    city?: string;
    street?: string;
    street_line_2?: string;
    email?: string
    radius?: any
}

interface IAddEmp {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userId: number;
    userDetail: IData;
    selectedCoordLat?: any;
    selectedCoordLong?: any;
    stateValue?: string;

}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    userDetail: []
};

export const addEmployee: any = createAsyncThunk(
    "AddEmployeeSlice",
    async ({ userId, userDetail, stateValue, selectedCoordLat, selectedCoordLong }: IAddEmp) => {
        try {
            const response = await http.patch(`${process.env.REACT_APP_BASE_URL}${apiconfig.employees.employeeAdd}/${userId}`, {
                street: userDetail?.street,
                street_line_2: userDetail?.street_line_2,
                city: userDetail?.city,
                state: stateValue,
                zip: userDetail?.zip,
                country: 'USA',
                lat: selectedCoordLat,
                long: selectedCoordLong,
                radius: parseInt(userDetail?.radius)

            }).then((data: any) => {
                showToastSuccessMessage('Profile has been updated successfully')
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)

            throw error
        }
    }
);

export const AddEmployeeSlice = createSlice({
    name: "AddEmployeeSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addEmployee.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addEmployee.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(addEmployee.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const addEmployeeSliceReducer = AddEmployeeSlice.reducer;
