import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { hcpregiondetails } from "../../feature/HCP Region/HCPRegionDetails-Slice";
import { editRegion } from "../../feature/HCP Region/EditHCPRegion-Slice";
import Tagify from "@yaireo/tagify";
interface IFormInputs {
    region?: string;
    api_key?: string;
    status?: any;
    hcp_region_id?: any;
    sg_email?: string;
    request_email?: string;
    signup_email?: string;
    estimation_email?: string;
    address_request_mail?: string

}

const EditHCPRegion = () => {
    const [regionId, setRegionId] = useState('')
    const [hcpRegionDetail, setHCPRegionDetail] = useState<any>({
        region: '',
        api_key: '',
        sg_email: '',
        status: false,
        hcp_region_id: 0,
        request_email: '',
        signup_email: '',
        estimation_email: '',
        address_request_mail: ''
    })

    const [newReqEmail, setNewReqEmail]: any = useState<any>()
    const [newEstEmail, setNewEstEmail]: any = useState<any>()
    const [newSignEmail, setNewSignEmail]: any = useState<any>()
    const [newAddEmail, setNewAddEmail]: any = useState<any>()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<IFormInputs>();


    // To get the data of the user from the api.
    useEffect(() => {
        const path = window.location.pathname
        const id = path.split('/')[2]
        setRegionId(id)
        dispatch(hcpregiondetails({ id })).then((data: any) => {

            setHCPRegionDetail(data?.payload?.data?.data);
            setNewReqEmail(data?.payload?.data?.data?.request_email.split(",").map((a: any) => { a["value"] = a }));
            setNewEstEmail(data?.payload?.data?.data?.estimation_email.split(",").map((a: any) => { a["value"] = a }));
            setNewSignEmail(data?.payload?.data?.data?.signup_email.split(",").map((a: any) => { a["value"] = a }));
            setNewAddEmail(data?.payload?.data?.data?.address_request_mail.split(",").map((a: any) => { a["value"] = a }));

        })

    }, [window?.location?.pathname]);

    const RequestEmails = (typeof newReqEmail == 'object' && newReqEmail?.length) ? newReqEmail?.map((e: any) => e['value']).toString() : newReqEmail
    const EstimateEmails = (typeof newEstEmail == 'object' && newEstEmail?.length) ? newEstEmail?.map((e: any) => e['value']).toString() : newEstEmail
    const SignupEmails = (typeof newSignEmail == 'object' && newSignEmail?.length) ? newSignEmail?.map((e: any) => e['value']).toString() : newSignEmail
    const AddressEmails = (typeof newAddEmail == 'object' && newAddEmail?.length) ? newAddEmail?.map((e: any) => e['value']).toString() : newAddEmail


    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        // newRequestEmail, newSignupEmail, newEstimateEmail
        dispatch(editRegion({ regionId, hcpRegionDetail, newRequestEmail: RequestEmails, newSignupEmail: SignupEmails, newEstimateEmail: EstimateEmails, newAddressEmail: AddressEmails })).then(
            (data: any) => {
                if (data?.meta?.requestStatus === "fulfilled") {
                    setTimeout(() => {
                        navigate("/hcp-region");
                    }, 1000);
                }
            }
        );
    };


    setTimeout(() => {

        var input: any = document.querySelector('input[name=tags]');
        new Tagify(input);
        var input: any = document.querySelector('input[name=tags1]');
        new Tagify(input);
        var input: any = document.querySelector('input[name=tags2]');
        new Tagify(input);
        var input: any = document.querySelector('input[name=tags3]');
        new Tagify(input);
    }, 1000)

    const { isLoading } = useSelector((state: any) => state?.hcpRegionDetail)
    const isRegionEdited = useSelector((state: any) => state?.editRegion)


    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={"HCP Region Details"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('hcp-region')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/hcp-region']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Region
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("region", { required: hcpRegionDetail?.region == '' ? true : false })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="text"
                                                    placeholder="Region"
                                                    value={hcpRegionDetail?.region}
                                                    onChange={(e) => {
                                                        setHCPRegionDetail((prevUserDetail: any) => ({
                                                            ...prevUserDetail,
                                                            region: e.target.value,

                                                        }))
                                                        setValue("region", e.target.value)
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.region == '' && "Region is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-phone"
                                                >
                                                    API KEY
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("api_key", { required: hcpRegionDetail?.api_key ? false : true })}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="grid-phone"
                                                    type="text"
                                                    placeholder="Enter API Key"
                                                    value={hcpRegionDetail?.api_key}
                                                    onChange={(e) => {
                                                        setHCPRegionDetail((prevUserDetail: any) => ({
                                                            ...prevUserDetail,
                                                            api_key: e.target.value,
                                                        }))
                                                        setValue("api_key", e.target.value)
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.api_key == '' && "API Key is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-phone"
                                                >
                                                    Email
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("sg_email", {
                                                        required: hcpRegionDetail?.sg_email ? false : true,
                                                        // eslint-disable-next-line no-useless-escape
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="grid-phone"
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    value={hcpRegionDetail?.sg_email}
                                                    onChange={(e) => {
                                                        setHCPRegionDetail((prevUserDetail: any) => ({
                                                            ...prevUserDetail,
                                                            sg_email: e.target.value,
                                                        }))
                                                        setValue("sg_email", e.target.value, { shouldValidate: true })
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.sg_email == '' && "Email is required." || errors?.sg_email?.message}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3 shrink">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="Tags"
                                                >
                                                    JOB REQUEST EMAIL
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("request_email", {
                                                        required: hcpRegionDetail?.request_email ? false : true,
                                                    })}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="Tags"
                                                    type="email"
                                                    placeholder="Enter Email"
                                                    value={(newReqEmail && newReqEmail?.length) ? newReqEmail.map((a: any) => a["value"]).toString() : hcpRegionDetail?.request_email}
                                                    onChange={(e: any) => {
                                                        setNewReqEmail(e?.target?.value && JSON?.parse(e?.target?.value));
                                                    }
                                                    }
                                                    name="tags"
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.request_email == '' || newReqEmail == '' && "Email is required."}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3 shrink">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="Tags1"
                                                >
                                                    ESTIMATION EMAIL
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("estimation_email", {
                                                        required: hcpRegionDetail?.estimation_email ? false : true,
                                                    })}
                                                    className="appearance-none ajay border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="Tags1"
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    value={(newEstEmail && newEstEmail?.length) ? newEstEmail.map((a: any) => a["value"]).toString() : hcpRegionDetail?.estimation_email}
                                                    onChange={(e) => {
                                                        setNewEstEmail(e.target.value && JSON.parse(e.target.value))
                                                    }
                                                    }
                                                    name="tags1"
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.estimation_email == '' || newEstEmail == '' && "Email is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3 shrink">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="Tags2"
                                                >
                                                    SIGNUP EMAIL
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("signup_email", {
                                                        required: hcpRegionDetail?.signup_email ? false : true,
                                                    })}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="Tags2"
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    value={(newSignEmail && newSignEmail?.length) ? newSignEmail.map((a: any) => a["value"]).toString() : hcpRegionDetail?.signup_email}
                                                    onChange={(e) => {
                                                        setNewSignEmail(e.target.value && JSON.parse(e.target.value))
                                                    }
                                                    }
                                                    name="tags2"
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.signup_email == '' || newSignEmail == '' && "Email is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-is-active"
                                                >
                                                    Status
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="flex items-center">
                                                    <label className="mr-2">
                                                        <input
                                                            type="radio"
                                                            checked={hcpRegionDetail?.status}
                                                            className="mr-1"
                                                            name="status"
                                                            onChange={(e) =>
                                                                setHCPRegionDetail((prevUserDetail: any) => ({
                                                                    ...prevUserDetail,
                                                                    status: true,
                                                                }))
                                                            }
                                                        />
                                                        Active
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            checked={!hcpRegionDetail?.status}
                                                            className="mr-1"
                                                            name="status"
                                                            onChange={(e) =>
                                                                setHCPRegionDetail((prevUserDetail: any) => ({
                                                                    ...prevUserDetail,
                                                                    status: false,
                                                                }))
                                                            }
                                                        />
                                                        In Active
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w-1/2 px-3 shrink">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="Tags3"
                                                >
                                                    Address Request Email
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("address_request_mail", {
                                                        required: hcpRegionDetail?.address_email ? false : true,
                                                    })}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="Tags3"
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    value={(newAddEmail && newAddEmail?.length) ? newAddEmail.map((a: any) => a["value"]).toString() : hcpRegionDetail?.address_request_mail}
                                                    onChange={(e) => {
                                                        setNewAddEmail(e.target.value && JSON.parse(e.target.value))
                                                    }
                                                    }
                                                    name="tags3"
                                                />
                                                <p className="text-red text-sm">
                                                    {hcpRegionDetail?.address_email == '' || newAddEmail == '' && "Email is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 px-3 mt-5 justify-end">
                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm ml-2"
                                                onClick={() => {
                                                    navigate('/hcp-region')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                disabled={newReqEmail == '' || newEstEmail == '' || newSignEmail == '' || isRegionEdited?.isLoading ? true : false}
                                                type="button"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                                                onClick={(data: any) => onSubmit(data)}
                                            >
                                                {isRegionEdited?.isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isRegionEdited?.isLoading &&
                                                    (
                                                        <>
                                                            Submit
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditHCPRegion;



