import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { estimaterequestlist } from "../../feature/EstimateRequest/EstimateRequestList-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";

let PageSize = 1;

const EstimateRequestList = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [estimaterequestListData, setEstimateRequestListData] = useState([])
    const [searchCount, setSearchCount] = useState(0)

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);


    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            estimateRequest();
        }
    }, [currentPage, navigate, searchResult]);

    const estimateRequest = () => {
        dispatch(estimaterequestlist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult
        })).then((data: any) => { setEstimateRequestListData(data?.payload?.data?.data?.estimationList); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.estimaterequestList)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Estimate Request List`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex items-center justify-start mr-auto w-full" >
                                    <Search
                                        setSearchResult={setSearchResult}
                                        searchResult={searchResult}
                                        currentPage={setCurrentPage}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                    Full name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[319px]">
                                                    Task title
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[618px]">
                                                    Task description
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[207px]">
                                                    Created at
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[125px]">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {estimaterequestListData?.length ? estimaterequestListData.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.first_name?.charAt(0)?.toUpperCase() + data?.first_name?.slice(1)}  {data?.last_name?.charAt(0)?.toUpperCase() + data?.last_name?.slice(1)}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.task_title ? data?.task_title : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data.task_description.length >= 50
                                                                    ? data.task_description.slice(0, 50) + '...'
                                                                    : data.task_description || '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                <span className="flex items-center">
                                                                    <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                        onClick={() => { navigate(`/estimate-requests/${data.id}`, { state: data }) }}
                                                                    >
                                                                        <button className="text-xs font-bold text-black uppercase" >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                <title>View</title>
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-12">
                        {
                            estimaterequestListData && estimaterequestListData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EstimateRequestList;
