
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";


interface IAutoPasswword {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userCred?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const autoPassword: any = createAsyncThunk(
    "AutoPasswordSlice",
    async ({ userCred }: IAutoPasswword) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.masterPassword.autoPassword}`, {
                password: userCred

            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const AutoPasswordSlice = createSlice({
    name: "AutoPasswordSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(autoPassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(autoPassword.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(autoPassword.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const autoPasswordReducer = AutoPasswordSlice.reducer;
