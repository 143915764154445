import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sidebar: false,
}

export const siderbarSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment: (state) => {
            state.sidebar = !state.sidebar
        },

    },
})

// Action creators are generated for each case reducer function
export const { increment } = siderbarSlice.actions

export const sidebarColReducer = siderbarSlice.reducer