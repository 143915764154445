import React from 'react';
import avatar from '../../assets/images/core/one-home-solution-logo.png';

const Footer = () => {
    const d = new Date().getFullYear();

    return (
        <div className="footer mt-auto bg-white  w-full">
            <div className='flex justify-center items-center p-4'>
                <img className="rounded-[50%]" src={avatar} alt="Avatar" width={35} height={35} />
                <p className="text-center pl-3">Copyright @{d} All right reserved.</p>
            </div>
        </div>
    );
}

export default Footer;
