
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";


interface IDeleteAdvertisement {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    id: number;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const deleteService: any = createAsyncThunk(
    "DeleteServiceSlice",
    async ({ id }: IDeleteAdvertisement) => {
        try {
            const response = await http.delete(`${process.env.REACT_APP_BASE_URL}${apiconfig.services.deleteService}/${id}`, {
            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const DeleteServiceSlice = createSlice({
    name: "DeleteServiceSlice",
    initialState,
    reducers: {
        isAdvertisementDeleted: (state) => {
            state.isSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteService.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteService.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(deleteService.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const deleteServiceSliceReducer = DeleteServiceSlice.reducer;
