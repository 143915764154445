import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Loader from "../../components/loader/Loader";
import { servicelist } from "../../feature/Services/ServiceList-Slice";
import { deleteService } from "../../feature/Services/DeleteService-Slice";
import { useRef } from "react";
import { sortService } from "../../feature/Services/SortService-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import { syncServicelist } from "../../feature/Services/SyncService-Slice";

const ServiceListing = () => {
    const [loading, setLoading] = useState(false);
    const [serviceListData, setServiceListData] = useState([])
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const tdRefs: any = useRef([]);
    const location = useLocation();

    const { isSuccess } = useSelector((state: any) => state.sortService)
    const { isLoading } = useSelector((state: any) => state?.serviceList)
    const [regionList, setRegionList] = useState([])

    // In below need to add 'UT' as default in or condition once bi-directional flow is confirmed.
    const [regionName, setRegionName]: any = useState(location?.state ? location?.state : '')

    // In below useEffect regionName is removed from dependency for the bi-directinal flow once the flow is confirmed need to add regionName in below useEffect dependency.

    useEffect(() => {
        ServiceList();
    }, [isSuccess]);

    const ServiceList = () => {
        dispatch(servicelist(regionName)).then((data: any) => setServiceListData(data?.payload?.data?.data))
    }

    const addService = () => {
        navigate('/services/new')
    }



    // const deleteServiceHandler = (id: any) => {
    //     setSelectedServiceId(id)
    //     setShowDeleteConfirmation(true);

    // }

    const confirmDeleteService = (id: any) => {
        dispatch(deleteService({ id })).then((val: any) => {
            if (val?.meta?.requestStatus === "fulfilled") {
                ServiceList();
            }
        });

        // Close the delete confirmation popup
        setShowDeleteConfirmation(false);

    }

    const handleDrop = async (event: any, newIndex: any) => {
        event.preventDefault();
        const sourceIndex = parseInt(event.dataTransfer.getData('text/plain'));
        const draggedItem = serviceListData[sourceIndex];

        // Remove the dragged item from the original position
        const newServiceListData: any = serviceListData.filter((_, index) => index !== sourceIndex);

        // Insert the dragged item at the new position
        newServiceListData.splice(newIndex, 0, draggedItem);

        // Update the serviceListData state and tdRefs
        setServiceListData(newServiceListData);
        tdRefs.current = tdRefs.current.slice();

        const updatedSortOrder = newServiceListData?.map((item: any, index: any) => ({
            id: item?.id,
            display_order: index + 1,
        }));
        dispatch(sortService({ updatedSortOrder }))

    };


    const handleDragStart = (event: any, index: any) => {
        event.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (showDeleteConfirmation) {
            document.body.style.overflow = 'hidden';
        } else if (!showDeleteConfirmation) {
            document.body.style.overflow = 'auto';

        }
    }, [showDeleteConfirmation])

    const syncServiceHandler = () => {
        dispatch(syncServicelist())
    }

    // Below useEffect is commented for the bi-directional flow

    // useEffect(() => {
    //     dispatch(hcpRegionlist()).then((val: any) => {
    //         if (val?.payload.data.data) {
    //             setRegionList(val?.payload.data.data)
    //         }
    //     }
    //     )
    // }, [])


    return (
        <>
            <div className="flex">
                <Sidebar />
                <div className="content w-full">
                    <Header />
                    <div className="fluid p-4">
                        <div className="flex justify-between items-center w-100 pb-5" >
                            <Title title={`Service List`} />
                            <button
                                className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue text-right"
                                onClick={addService}
                            >
                                + Add
                            </button>
                        </div>
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                                <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">



                                    {/* 
                                    
                                        [As of now code for bidirectional flow is commented once confirmed we will un-comment it]


                                    <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center ">
                                        <h3 className="flex">HCP Region</h3>
                                        <div className="w-[200px] relative mr-auto ml-3">
                                            <select
                                                className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                                id="grid-state"
                                                value={regionName ? regionName : null}
                                                onChange={(e) => {
                                                    setRegionName(e.target.value);
                                                }}
                                                placeholder='Select HCP Region'
                                            >
                                                {regionList?.map((val: any, index) => (
                                                    <option key={index} value={val.region}>
                                                        {val.region}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="pointer-events-none  inset-y-0  flex items-center px-2 text-gray-700 absolute right-0 top-0">
                                                <svg
                                                    className="fill-current h-4 w-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div
                                            className="flex items-center sm:mt-4 sm:w-full sm:flex-row-reverse sm:justify-center">
                                            <span className="icon inline-block mx-2 sm:ml-0 sm:hidden" onClick={() => syncServiceHandler()} title='Click here to sync'>
                                                <svg
                                                    className="cursor-pointer hover:animate-spin"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20px"
                                                    viewBox="0 0 24 24"
                                                    id="reload"
                                                >
                                                    <path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div> */}

                                    <div className="flex flex-col w-full">
                                        <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                                            <table className="w-full text-left table-auto">
                                                <thead className="bg-logo text-white">
                                                    <tr className="">
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[248px]">
                                                            Display order
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[565px] ">
                                                            Title
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[198px]">
                                                            {/**
                                                             *  Instead of Services - HCP Region will be tittle for the bi-directional flow. 
                                                             */}
                                                            Services
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[177px]">
                                                            Status
                                                        </th>
                                                        <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[346px]">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {isLoading ? (
                                                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                            <ClipLoader color="#f27366" size={50} />
                                                        </div>
                                                    ) : <>

                                                        {serviceListData?.length ? serviceListData.map((data: any, index: any) => {
                                                            const isGrayRow = index % 2 === 0;
                                                            return (
                                                                <tr
                                                                    className={isGrayRow ? 'bg-gray-table cursor-grab' : 'cursor-grab'}
                                                                    key={index}
                                                                    draggable
                                                                    onDragStart={(event) => handleDragStart(event, index)}
                                                                    onDragOver={handleDragOver}
                                                                    onDrop={(event) => handleDrop(event, index)}
                                                                >
                                                                    <td
                                                                        className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td
                                                                        className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle"
                                                                    >
                                                                        {data?.service_title ? data?.service_title : '-'}
                                                                    </td>
                                                                    <td
                                                                        className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.service_category ? data?.service_category.charAt(0).toUpperCase() + data?.service_category.slice(1) : '-'}
                                                                    </td>
                                                                    <td
                                                                        className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {data?.is_active == true ? 'Active' : 'In Active'}
                                                                    </td>
                                                                    <td
                                                                        className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap">
                                                                        <div className="flex gap-x-2">
                                                                            <span className="flex items-center">
                                                                                <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                    onClick={() => { navigate(`/services/${data.id}`, { state: data }) }}
                                                                                >
                                                                                    <button className="text-xs font-bold text-black uppercase" >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                            <title>Edit</title>
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </span>
                                                                            </span>
                                                                            {/* <span className="flex items-center">
                                                                                <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                                    onClick={() => deleteServiceHandler(data?.id)}
                                                                                >
                                                                                    <button className=" text-xs font-bold text-black uppercase" >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                            <title>Delete</title>
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </span>
                                                                            </span> */}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                                    <div>No Data Found</div>
                                                                </td>
                                                            </tr>}
                                                    </>}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div >
            {showDeleteConfirmation &&
                <>
                    <div className='modal-wrapper fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center h-[1061px]' onClick={() => setShowDeleteConfirmation(false)}>
                        <div className='modal relative flex justify-center items-center bg-white min-w-[400px] min-h-[200px] rounded'>
                            <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg></button>
                            <div className="content p-10 ">
                                <p className="text-center">Are you sure you want to delete this service?</p>
                                <div className="flex justify-center gap-x-5 mt-3">
                                    <button onClick={() => confirmDeleteService(selectedServiceId)} className="text-center bg-green text-white px-5 py-2">Yes</button>
                                    <button onClick={() => setShowDeleteConfirmation(false)} className="text-center bg-red text-white px-5 py-2">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
};

export default ServiceListing;
