import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IUserJobs {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    user_id: string;
    userJobsDetails: [];
    page?: null;
    limit?: null;
    search_text?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    userJobsDetails: []
};

export const userjobsdetails: any = createAsyncThunk(
    "UserJobDetailSlice",
    async ({ user_id, page, limit, search_text }: IUserJobs) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.users.jobdetails}/${user_id}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}`)
        return response
    }
);

export const UserJobdetailSlice = createSlice({
    name: "UserJobDetailSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userjobsdetails.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(userjobsdetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(userjobsdetails.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const userjobDetailSliceReducer = UserJobdetailSlice.reducer;
