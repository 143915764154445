import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IAddressRequestList {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userlist: [];
    page?: null;
    limit?: null;
    search_text?: string;
    status?: string;
    address_type?: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const addressRequestlist: any = createAsyncThunk(
    "AddressRequestSlice",
    async ({ page, limit, search_text, status, address_type }: IAddressRequestList) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.addressRequest?.addressRequestList}/${address_type}${search_text ? `?page=${page}` : `?page=${page}`}&limit=${limit}${search_text ? `&search_text=${search_text}` : ''}${status ? `&status=${status}` : ''}`)
        return response
    }
);

export const AddressRequestListSlice = createSlice({
    name: "AddressRequestSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addressRequestlist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addressRequestlist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(addressRequestlist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const addressRequestListSliceReducer = AddressRequestListSlice.reducer;
