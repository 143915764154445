import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { advertisementlist } from "../../feature/Advertisement/AdvertisementList-Slice";
import { deleteAdvertisement } from "../../feature/Advertisement/DeleteAdvertisement-Slice";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import { sortAdv } from "../../feature/Advertisement/AdvertisementSort-Slice";

const AdvertisementListing = () => {
  const [advertisementList, setAdvertisementList] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedAdvertisementId, setSelectedAdvertisementId] = useState(null);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tdRefs: any = useRef([]);

  const { isLoading } = useSelector((state: any) => state?.advertisementList)
  const { isSuccess } = useSelector((state: any) => state?.sortAdv)

  useEffect(() => {
    advertisemenrList();
  }, [isSuccess]);

  const advertisemenrList = () => {
    dispatch(advertisementlist()).then((val: any) => setAdvertisementList(val?.payload?.data?.data))
  }

  const deleteAdvertisementHandler = (id: any) => {
    setSelectedAdvertisementId(id);
    setShowDeleteConfirmation(true);
  }

  const addTableRow = () => {
    navigate('/advertisement/new')
  };

  const confirmDeleteAdvertisement = (id: any) => {
    // Call the deleteAdvertisement action here
    dispatch(deleteAdvertisement({ id })).then((val: any) => {
      if (val?.meta?.requestStatus === "fulfilled") {
        advertisemenrList();
      }
    });

    // Close the delete confirmation popup
    setShowDeleteConfirmation(false);
  };

  const handleDrop = async (event: any, newIndex: any) => {
    event.preventDefault();
    const sourceIndex = parseInt(event.dataTransfer.getData('text/plain'));
    const draggedItem = advertisementList[sourceIndex];

    // Remove the dragged item from the original position
    const newServiceListData: any = advertisementList.filter((_, index) => index !== sourceIndex);

    // Insert the dragged item at the new position
    newServiceListData.splice(newIndex, 0, draggedItem);

    // Update the serviceListData state and tdRefs
    setAdvertisementList(newServiceListData);
    tdRefs.current = tdRefs.current.slice();

    const updatedSortOrder = newServiceListData?.map((item: any, index: any) => ({
      id: item?.id,
      display_order: index + 1,
    }));
    dispatch(sortAdv({ updatedSortOrder }))

  };


  const handleDragStart = (event: any, index: any) => {
    event.dataTransfer.setData('text/plain', index.toString());
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (showDeleteConfirmation) {
      document.body.style.overflow = 'hidden';
    } else if (!showDeleteConfirmation) {
      document.body.style.overflow = 'auto';

    }
  }, [showDeleteConfirmation])

  const isAdvertisementDeleted = useSelector((state: any) => state.deleteAdvertise)


  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="content w-full">
          <Header />
          <div className="fluid p-4 custom-height-wrapper">
            <div className="flex justify-between items-center w-100 pb-5" >
              <Title title={`Advertisement List`} />
              <button
                className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue text-right"
                onClick={addTableRow}
              >
                + Add
              </button>
            </div>
            <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
              <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                </div>
                <div className="flex flex-col w-full">
                  <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]">
                    <table className="w-full text-left table-auto">
                      <thead className="bg-logo text-white">
                        <tr>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[125px]">
                            Display Order
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[333px]">
                            Banner Title
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray  leading-4 w-[333px]">
                            Request Title
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[111px]">
                            Image
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[105px]">
                            Status
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[333px]">
                            Industry
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[333px]">
                            Taxonomy Display Name
                          </th>
                          <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[205px]">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                          </div>
                        ) : <>
                          {advertisementList?.length ? advertisementList.map((data: any, index: any) => {
                            const isGrayRow = index % 2 === 0;
                            return (
                              <tr
                                className={isGrayRow ? 'bg-gray-table cursor-grab' : 'cursor-grab'}
                                key={index}
                                draggable
                                onDragStart={(event) => handleDragStart(event, index)}
                                onDragOver={handleDragOver}
                                onDrop={(event) => handleDrop(event, index)}
                              >
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                  {index + 1}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                  {data?.banner_title}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                  {data?.request_title ? data?.request_title : '-'}
                                </td>
                                <td className="px-1 py-2 text-sm border-b border-solid border-gray whitespace-nowrap">
                                  <img src={`${process.env.REACT_APP_IMAGE_PATH}${data?.banner_image}`} alt="Banner Image" className="h-[50px] w-[80px]" />
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle capitalize">
                                  {`${data?.is_active == true ? 'Active' : 'In Active'}`}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle capitalize">
                                  {data?.service_industry_data?.industry_name ? data?.service_industry_data?.industry_name : '-'}
                                </td>
                                <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                  {data?.display_name ? data?.display_name : '-'}
                                </td>
                                <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap align-middle">
                                  <div className="flex gap-x-2">
                                    <span className="flex items-center">
                                      <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                        onClick={() => { navigate(`/advertisement/${data.id}`, { state: data }) }}
                                      >
                                        <button className="text-xs font-bold text-black uppercase" >
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <title>Edit</title>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                          </svg>
                                        </button>
                                      </span>
                                    </span>
                                    <span className="flex items-center">
                                      <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                        onClick={() => deleteAdvertisementHandler(data?.id)}
                                      >
                                        <button className=" text-xs font-bold text-black uppercase" >
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <title>Delete</title>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                          </svg>
                                        </button>
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                            :
                            <tr>
                              <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                <div>No Data Found</div>
                              </td>
                            </tr>}
                        </>}
                      </tbody>
                    </table>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteConfirmation &&
        <>
          <div className='modal-wrapper overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full md:h-full justify-center items-center flex' onClick={() => setShowDeleteConfirmation(false)}>
            <div className='modal relative flex justify-center items-center bg-white   min-w-[400px] min-h-[200px] rounded'>
              <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => setShowDeleteConfirmation(false)} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></button>
              <div className="content p-10 ">
                <p className="text-center">Are you sure you want to delete this advertisement?</p>
                <div className="flex justify-center gap-x-5 mt-3">
                  <button onClick={() => setShowDeleteConfirmation(false)} className="text-center bg-red text-white px-5 py-2 w-[68px] h-[40px] inline-block">No</button>
                  <button onClick={() => confirmDeleteAdvertisement(selectedAdvertisementId)} className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block">

                    {isAdvertisementDeleted?.isLoading &&
                      <ClipLoader color="#FFFFFF" size={20} />
                    }
                    {
                      !isAdvertisementDeleted?.isLoading &&
                      (
                        <>
                          Yes
                        </>
                      )
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
};

export default AdvertisementListing;
