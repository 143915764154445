import { useState, useEffect, useContext } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import { Breadcrumb } from "../../components/breadcrumb";
import { adminindustrySubCategorylist } from "../../feature/Taxonomy/AdminIndustrySub-Slice";
import { vendorEdit } from "../../feature/Taxonomy/EditSubCategory-Slice";
import { getLocalStorage, showToastSuccessMessage } from "../../utils/utils";
import { useForm } from "react-hook-form";
import { employeeskill } from "../../feature/Employees/EmployeeSkills-Slice";
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import Switch from "react-switch";
import RegionContext from "../../utils/RegionContext";

let PageSize = 1;

interface IFormInputs {
    displayName?: string;
    vendorName?: string;
    estimateHours?: any
}

const IndustrySubCategoryList = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState(1);
    const query = useParams()
    const [count, setCount] = useState(0)
    const [adminIndustryCategoryData, setAdminIndustryCategoryData]: any = useState([])
    const [searchCount, setSearchCount] = useState(0)
    const [industryName, setIndustryName] = useState('')
    const dispatch = useDispatch()
    const location = useLocation();
    const { contextRegionId, setContextRegionId }: any = useContext(RegionContext)
    const [vendorName, setVendorName]: any = useState('')
    const [estimateHours, setEstimateHours]: any = useState()
    const [displayName, setDisplayName]: any = useState('')
    const [vendorId, setVendorId]: any = useState()
    const [editingData, setEditingData]: any = useState(null);
    const [isEditingField, setIsEditingField] = useState(false)
    const [taxnoStatus, setTaxoStatus]: any = useState('')
    const [employeeSkillList, setEmployeeSkillSet]: any = useState([])
    const [selectedSkills, setSelectedSkills]: any = useState([]);
    const [subCatData, setSubCatData]: any = useState();
    const [subCatName, setSubCatName]: any = useState();
    const { isLoading } = useSelector((state: any) => state?.adminindustrysubcategoryList)
    const [regionUtahId, setRegionUtahId] = useState()
    const [filterStatus, setFilterStatus]: any = useState(contextRegionId ? contextRegionId : regionUtahId)
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [isLoad, setIsLoad] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        if (filterStatus) {
            adminIndustry();
            setIsLoad(true)
        }
    }, [currentPage, navigate, searchResult, filterStatus])

    useEffect(() => {
        if (filterStatus) {
            dispatch(employeeskill({ regionId: filterStatus })).then((val: any) => {
                const dataList = val?.payload?.data?.list;

                if (dataList) {
                    const updatedSkillList = dataList.map((data: any) => {
                        const trimmedData = data.trim();
                        const words = trimmedData.split(/-|\s+/);
                        const capitalizedWords = words.map((word: any) =>
                            word.charAt(0).toUpperCase() + word.slice(1)
                        );

                        const formattedData = capitalizedWords.join(' ');

                        return {
                            label: formattedData,
                            value: data
                        };
                    });

                    setEmployeeSkillSet(updatedSkillList);
                }
            });
        }
    }, [filterStatus]);


    const adminIndustry = () => {
        dispatch(adminindustrySubCategorylist({
            page: currentPage,
            limit: 100,
            search_text: searchResult,
            id: query?.id,
            filterStatus: filterStatus
        })).then((data: any, index: any) => {
            setAdminIndustryCategoryData(data?.payload?.data?.data); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count); setIndustryName(data?.payload?.data?.category_name)
        }
        )
    }

    const handleVendorChange = (newValue: string) => {
        setVendorName(newValue);
    }

    const handleHoursChange = (newValue: any) => {
        setEstimateHours(parseInt(newValue));
    }

    const handleDisplayChange = (e: any) => {

        setVendorId(editingData?.sub_category_id)
        setDisplayName(e?.target?.value)
        // setSelectedSkills(editingData?.skills)
    }

    const debouncedOnChange = () => {
        if (displayName == '') {
            return false
        }
        if (vendorName == 'In House' && selectedSkills && selectedSkills.length === 0) {
            return false
        }
        dispatch(vendorEdit({ id: vendorId, vendorType: vendorName, display_name: displayName, estimated_time: estimateHours, skills: selectedSkills, taxnoStatus: taxnoStatus })).then((val: any) => {
            if (val?.meta?.requestStatus == "fulfilled") {
                showToastSuccessMessage('Details has been changed successfully.')
                setIsEditingField(false)
                setTimeout(() => {
                    adminIndustry()
                }, 100)
            }
        })
    }

    const editConfig = (data: any) => {
        setDisplayName(data.display_name)
        setVendorName(data?.vendor)
        setEstimateHours(data?.estimated_time)
        setVendorId(data?.sub_category_id)
        setEditingData(data);
        setSelectedSkills(data?.skills)
        setIsEditingField(true)
        setSubCatName(data?.sub_category_name)
        setTaxoStatus(data?.is_active)
        setValue('vendorName', '', { shouldValidate: true })
        setValue('displayName', '', { shouldValidate: true })
        setValue('estimateHours', '', { shouldValidate: true })

    };

    const {
        register,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm<IFormInputs>();

    const calculateTimeSlots = (estimatedTime: number): string[] => {
        const startTime = 9; // Starting hour (9 AM)
        const endTime = 17; // Ending hour (5 PM)

        estimatedTime = Math.min(Math.max(estimatedTime, 1), endTime - startTime);

        const timeSlots = [];

        const formatTime = (time: number) => {
            const hours = Math.floor(time);
            const minutes = (time % 1) * 60;
            const meridian = hours >= 12 ? 'PM' : 'AM';
            const displayHours = hours % 12 || 12;
            return `${displayHours}:${minutes.toString().padStart(2, '0')} ${meridian}`;
        };

        for (let currentStartTime = startTime; currentStartTime + estimatedTime <= endTime; currentStartTime++) {
            const endSlot = currentStartTime + estimatedTime;
            timeSlots.push(`${formatTime(currentStartTime)} - ${formatTime(endSlot)}`);
        }

        return timeSlots;
    };

    useEffect(() => {
        adminIndustryCategoryData?.map((val: any) => {
            setSubCatData(val)
            setSelectedSkills(val?.skills)

        })
    }, [adminIndustryCategoryData])

    const handleStatusChange = (e: any) => {
        const selectedStatus = e?.target?.value === 'true'; // Convert string to boolean
        setTaxoStatus(e)
    }


    const handleStateChange = (e: any) => {
        setFilterStatus(e.target.value)
        setCurrentPage(1)
        setContextRegionId(e.target.value)
    };

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => {
            setHCPRegionList(val?.payload?.data?.data);
            if (!contextRegionId) {
                const temp = val?.payload?.data?.data.find((val: any) => val?.region == 'UT').id
                setRegionUtahId(temp)
                setFilterStatus(temp)
            }
        })
    }, [])

    const editVendorLoader = useSelector((state: any) => state?.editVendor)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Sub Category Lists of ${industryName}`} />
                        <Breadcrumb
                            paths={[('dashboard'), ('industries'), (`Category lists of ${location?.state?.industryName}`)]}
                            className="text-sm mb-3"
                            url={`${location?.state?.id ? `${`industry-list/category/${location?.state?.id}`}` : ''}`}
                        />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex items-center justify-start mr-auto w-full" >
                                    <Search
                                        setSearchResult={setSearchResult}
                                        searchResult={searchResult}
                                        currentPage={setCurrentPage}
                                    />
                                </div>
                                <div className="relative w-full max-w-[180px] items-center justify-end ml-[650px]">
                                    <select
                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                        id="grid-state"
                                        value={filterStatus}
                                        onChange={handleStateChange}
                                    >
                                        {hcpRegionList && hcpRegionList.length > 0 && (
                                            hcpRegionList.map((state: any, index) => (
                                                <option key={index} value={state.id} >
                                                    {state.name}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[99px]">
                                                    sub cat id
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[99px]">
                                                    sub cat name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[172px]">
                                                    display name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[99px]">
                                                    vendor
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[50px]">
                                                    service hours
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[127px]">
                                                    Slots available
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[105px]">
                                                    Status
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[125px]">
                                                    skills
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[125px]">
                                                    action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isLoading) || (!contextRegionId && !regionUtahId) ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {adminIndustryCategoryData && !!adminIndustryCategoryData.length ? adminIndustryCategoryData.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    const isEditing = editingData && editingData.sub_category_id === data.sub_category_id;

                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.sub_category_id ? data?.sub_category_id : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.sub_category_name ? data?.sub_category_name?.charAt(0)?.toUpperCase() + data?.sub_category_name?.slice(1) : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <span className="flex items-center">
                                                                    <span>
                                                                        {data?.display_name ? data?.display_name : '-'}
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <div className="flex items-center relative">
                                                                    {data?.vendor ? data?.vendor : '-'}
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.vendor == 'In House' ?
                                                                    <div className="relative">
                                                                        {data?.estimated_time ? data?.estimated_time : '-'}
                                                                    </div>
                                                                    : '-'
                                                                }
                                                            </td>
                                                            <td
                                                                className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap align-middle"
                                                                data-tip
                                                                data-tooltip-html={`serviceHoursTooltip-${index}`}
                                                            >
                                                                {data?.vendor == 'In House' && data?.estimated_time !== null ?
                                                                    <>
                                                                        <a data-tooltip-id={`my-tooltip-${index}`} className="justify-start flex"><svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                            width="18px" height="18px" viewBox="0 0 416.979 416.979"
                                                                            xmlSpace="preserve">
                                                                            <g>
                                                                                <path d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
		c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786
		c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576
		c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765
		c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"/>
                                                                            </g>
                                                                        </svg></a>
                                                                        <Tooltip id={`my-tooltip-${index}`}>
                                                                            <div>
                                                                                <p>Service Hours: {data?.estimated_time}</p>
                                                                                <p className="mb-1">Total Slots: {calculateTimeSlots(data?.estimated_time).length}</p>
                                                                                <ul>
                                                                                    {calculateTimeSlots(data?.estimated_time).map((slot, idx) => (
                                                                                        <li key={idx}><span className="pr-2">{idx + 1}</span> {slot}</li>
                                                                                    ))
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </>
                                                                    : '-'
                                                                }
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <div className="flex items-center relative">
                                                                    {data?.is_active == true ? 'Active' : 'In Active'}
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 border-b border-solid border-gray whitespace-nowrap z-0 align-middle">
                                                                {data && data.vendor === 'In House' && data.skills && data.skills.length > 0 ?
                                                                    <div className="relative">
                                                                        {data.skills.map((skill: any, index: number) => (
                                                                            <span key={index}>
                                                                                {skill.split(' ').map((part: any) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')}
                                                                                {index < data.skills.length - 1 && ','}{' '}
                                                                                {index % 3 === 2 && <br />}
                                                                            </span>
                                                                        ))}
                                                                    </div>
                                                                    : '-'
                                                                }
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <button className="text-xs font-bold text-black uppercase" onClick={() => editConfig(data)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <title>Edit</title>
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>{isLoad ? 'No Data Found' : ''}</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                    {isEditingField &&
                                        <>
                                            <form
                                                onSubmit={handleSubmit(debouncedOnChange)}
                                                className="w-full max-w-full mt-auto"
                                            >
                                                <div className='modal-wrapper fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center' onClick={() => setIsEditingField(false)}>
                                                    <div className='modal modal-industry relative flex justify-center items-center bg-white min-w-[400px] min-h-[200px] rounded' onClick={(e) => e.stopPropagation()}>
                                                        <button className='btnClose absolute top-[8px] right-[8px]' onClick={() => { setIsEditingField(false); setDisplayName(''); setVendorName(''); setValue('vendorName', ''); setEstimateHours(''); setValue('estimateHours', '') }} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-7 h-7">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg></button>
                                                        <div className="content p-10 " style={{}}>
                                                            <p className="text-center">Are you sure you want to change details ?</p>
                                                            <div className="flex items-center relative my-5">
                                                                <label className="w-[160px] whitespace-nowrap">Sub-Category Name:</label>
                                                                <label className="appearance-none block w-full border border-darkGray rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white  justify-center">{subCatName}</label>
                                                            </div>
                                                            <div className="flex justify-center items-start my-5 gap-0">
                                                                <label className="max-w-[140px] w-full pt-2.5">Display Name:</label>
                                                                <div>

                                                                    <input
                                                                        className="appearance-none block w-full border border-darkGray rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white  justify-center"
                                                                        type="text"
                                                                        value={displayName}
                                                                        onChange={(e) => {
                                                                            handleDisplayChange(e);
                                                                            setValue("displayName", e.target.value, { shouldValidate: true })
                                                                        }}
                                                                    />
                                                                    <p className="text-red text-sm">
                                                                        {displayName == '' && "Display name is required."}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center relative ">
                                                                <label className="max-w-[140px] w-full pt-2.5">Select Vendor:</label>
                                                                <select
                                                                    {...register("vendorName", { required: vendorName == '' || vendorName == undefined ? true : false })}
                                                                    value={vendorName ? vendorName : 'Select vendor'}
                                                                    onChange={(e) => { handleVendorChange(e.target.value); setValue("vendorName", e.target.value, { shouldValidate: true }) }}
                                                                    // defaultValue={data?.vendor}
                                                                    className="block border-darkGray w-full border py-3 px-4 outline-r pr-8 rounded leading-tight focus:outline-none bg-white appearance-none"
                                                                >
                                                                    <option value="" hidden>Select vendor</option>
                                                                    <option value="Vendor">Vendor</option>
                                                                    <option value="In House">In House</option>
                                                                </select>
                                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="text-red text-sm ml-[140px]">
                                                                {errors?.vendorName && "Vendor is required."}
                                                            </p>
                                                            <div className="relative flex items-center mt-[20px]">
                                                                {vendorName == 'In House' ? (
                                                                    <label className="max-w-[140px] w-full pt-2.5">Select Hours:</label>
                                                                ) : ''
                                                                }
                                                                {vendorName == 'In House' ? (
                                                                    <>
                                                                        <select
                                                                            {...register("estimateHours", { required: estimateHours == '' || estimateHours == undefined ? true : false })}
                                                                            value={estimateHours}
                                                                            onChange={(e: any) => {
                                                                                handleHoursChange(e.target.value);
                                                                                setValue("estimateHours", e.target.value, { shouldValidate: true })
                                                                            }}
                                                                            // defaultValue={data?.estimated_time}
                                                                            className="block border-darkGray w-full border py-3 px-4 outline-r pr-8 rounded leading-tight focus:outline-none bg-white appearance-none"
                                                                        >
                                                                            <option value="" selected hidden>
                                                                                Select Hours
                                                                            </option>
                                                                            <option value={1}>1</option>
                                                                            <option value={2}>2</option>
                                                                            <option value={3}>3</option>
                                                                            <option value={4}>4</option>
                                                                            <option value={5}>5</option>
                                                                            <option value={6}>6</option>
                                                                            <option value={7}>7</option>
                                                                            <option value={8}>8</option>
                                                                        </select>
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                                            </svg>
                                                                        </div>
                                                                    </>
                                                                )
                                                                    : ''
                                                                }
                                                            </div>
                                                            <p className="text-red text-sm ml-[140px]">
                                                                {errors?.estimateHours && "Hours is required."}
                                                            </p>
                                                            <div className={`flex items-center relative ${vendorName == 'In House' ? 'my-5' : 'my-1'}`}>
                                                                <label className="max-w-[140px] w-full pt-2.5">Status:</label>
                                                                {/* <select
                                                                    value={taxnoStatus}
                                                                    onChange={handleStatusChange}
                                                                    // defaultValue={data?.vendor}
                                                                    className="block border-darkGray w-full border py-3 px-4 outline-r pr-8 rounded leading-tight focus:outline-none bg-white appearance-none"
                                                                >
                                                                    <option value="" hidden>Select status</option>
                                                                    <option value='true'>Active</option>
                                                                    <option value='false'>In Active</option>
                                                                </select>
                                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                                    </svg>
                                                                </div> */}
                                                                <Switch
                                                                    checked={taxnoStatus}
                                                                    onChange={(e: any) => handleStatusChange(e)}
                                                                    onColor="#f27367"
                                                                    onHandleColor="#fff"
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                    height={15}
                                                                    width={48}
                                                                    className="react-switch"
                                                                    id="material-switch"
                                                                />
                                                            </div>
                                                            <div className="flex  my-5 gap-x-[22px]">
                                                                {vendorName == 'In House' ? (
                                                                    <label className="max-w-[117px] w-full pt-2.5">Select Skills:</label>
                                                                ) : ''
                                                                }
                                                                {vendorName == 'In House' ?
                                                                    <div className="relative custom-select w-[calc(100%_-_120px)]">
                                                                        <Select
                                                                            isMulti
                                                                            options={employeeSkillList}
                                                                            value={selectedSkills?.map((skill: any) => ({
                                                                                value: skill,
                                                                                label: skill?.replace(/\b\w/g, (match: any) => match.toUpperCase())
                                                                            }))}
                                                                            onChange={(selectedOptions) => {
                                                                                const updatedSkills = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                                setSelectedSkills(updatedSkills);
                                                                            }}
                                                                            styles={{
                                                                                container: (base) => ({
                                                                                    ...base,
                                                                                    width: "max-content",
                                                                                    minWidth: "100%",
                                                                                    maxWidth: '300px',
                                                                                }), menuPortal: base => ({
                                                                                    ...base, zIndex: 9999,
                                                                                    width: '100%',

                                                                                })
                                                                            }} />
                                                                        <div className="text-red text-sm" style={{ backgroundColor: '#ffff', marginTop: '3px' }}>
                                                                            {selectedSkills && selectedSkills.length === 0 && (
                                                                                <>
                                                                                    Please select at least one skill.
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    : ''
                                                                }
                                                            </div>

                                                            <div className="flex justify-center gap-x-5 mt-5">
                                                                <button onClick={() => { setIsEditingField(false); setDisplayName(''); setSelectedSkills(subCatData?.skills); setVendorName(''); setValue('vendorName', ''); setEstimateHours(''); setValue('estimateHours', '') }} className="text-center bg-red text-white px-5 py-2">No</button>
                                                                <button type="submit" className="text-center bg-green text-white px-5 py-2 w-[68px] h-[40px] inline-block" disabled={editVendorLoader?.isLoading}>
                                                                    {editVendorLoader?.isLoading &&
                                                                        <ClipLoader color="#FFFFFF" size={20} />
                                                                    }
                                                                    {
                                                                        !editVendorLoader?.isLoading &&
                                                                        (
                                                                            <>
                                                                                Yes
                                                                            </>
                                                                        )
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </>}
                                </div>
                            </div>
                        </div >
                    </div >
                    <div className="flex flex-col items-center my-12">
                        {
                            adminIndustryCategoryData && adminIndustryCategoryData.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div >
            </div >
        </div >
    );
};

export default IndustrySubCategoryList;