import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IAdminIndustrySubCategoryRequest {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    regionId?: any
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    employeeList: []
};

export const employeeskill: any = createAsyncThunk(
    "EmployeeSkillSlice",
    async ({ regionId }: IAdminIndustrySubCategoryRequest) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.employees?.employeeSkill}/${regionId}`)
        return response
    }
);

export const EmployeeSkillSlice = createSlice({
    name: "EmployeeSkillSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(employeeskill.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(employeeskill.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(employeeskill.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const employeeSkillSliceReducer = EmployeeSkillSlice.reducer;
