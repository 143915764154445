import { useEffect, useState } from "react";
import { debounce } from 'lodash';

const Search = (props: any) => {
  const [searchData, setsearchData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = setTimeout(() => {
      props.setSearchResult(searchData);
    }, 1000);
    return () => clearTimeout(getData);
  }, [props, searchData]);


  // Wrap handleSearch with debounce
  const debouncedHandleSearch = debounce((value: string) => {
    setsearchData(value);
  }, 50); // Adjust the delay as per requirement (e.g., 300ms)


  function handleSearch(event: any) {
    event.preventDefault();
    props?.currentPage(1)
    const value = event.target.value;
    debouncedHandleSearch(value);
  }
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <form className="w-full max-w-xs mr-2 sm:ml-0" onSubmit={handleSearch}>
      <div className="relative">
        <input
          type="search"
          name="search"
          placeholder="Search..."
          defaultValue={searchData}
          className="w-full h-10 pl-10 pr-5 text-sm rounded-full appearance-none focus:outline-none bg-gray-100 dark:bg-gray-800 border border-gray-100 dark:border-gray-700"
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
        />
        <button type="button" disabled={loading} className="absolute top-0 left-0 mt-3 ml-4">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 h-4 stroke-current"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </button>
      </div>
    </form>
  );
};

export default Search;
