import classnames from "classnames";
import { usePagination, DOTS } from "../../utils/usePagination";
const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const totalItems = Math.ceil(totalCount / parseInt(process.env.REACT_APP_PAGE_ITEM_LIMIT || '10'))
  const paginationRange: any = usePagination({
    currentPage,
    totalItems,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div
      className={classnames("flex text-gray-700", { [className]: className })}
    >
      <div
        className={
          currentPage === 1
            ? "pointer-events-none w-12 h-[42px] mr-1 flex justify-center items-center rounded-[25px] bg-gray hover:bg-logo hover:text-white transition ease-in-out duration-300"
            : "w-12 h-[42px] mr-1 flex justify-center items-center rounded-[25px] bg-gray hover:bg-logo hover:text-white transition ease-in-out duration-300 cursor-pointer"
        }
        onClick={onPrevious}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevron-left w-6 h-6"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </div>
      <div className="flex h-12 font-medium rounded-full bg-gray-200">
        {paginationRange.map((pageNumber: any, index: any) => {
          if (pageNumber === DOTS) {
            return (
              <li
                key={index}
                className="w-12 h-[42px]  flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
              >
                ...
              </li>
            );
          }

          return (
            <li key={index}
              className={`
               w-12 h-[42px]  ml-1 flex justify-center items-center rounded-[25px] bg-gray hover:bg-logo hover:text-white transition ease-in-out duration-300
                ${pageNumber === currentPage
                  ? "pointer-events-none bg-logo text-white"
                  : "cursor-pointer"
                }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
      </div>

      <div
        className={`
         w-12 h-[42px]  ml-1 flex justify-center items-center rounded-[25px] bg-gray hover:bg-logo hover:text-white transition ease-in-out duration-300 
          ${currentPage === lastPage ? "pointer-events-none" : "cursor-pointer"
          }`}
        onClick={onNext}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevron-right w-6 h-6"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </div>
    </div>
  );
};

export default Pagination;
