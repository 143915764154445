import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import { editAppConfig } from "../../feature/AppConfig/EditConfig-Slice";
import { Breadcrumb } from "../../components/breadcrumb";
import Switch from "react-switch";

const MembershipInfoListing = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { isLoading } = useSelector((state: any) => state?.appConfigList);
    const [editingData, setEditingData]: any = useState(null);
    const [updatedListData, setUpdatedListData]: any = useState([]);
    const [membershipId, setMemberShipId] = useState('')
    const [membershipTitle, setMembershipTitle] = useState('')

    const editConfig = (data: any) => {
        setEditingData(data);
    };

    const handleFieldChange = (event: any, index: number, field: string) => {
        const { value } = event.target;
        const updatedData = [...updatedListData];

        updatedData[index][field] = value;
        setUpdatedListData(updatedData);

    };

    const handleSelectedChange = (event: any, index: number, field: string) => {
        const updatedData = [...updatedListData];
        updatedData[index][field] = event;
        setUpdatedListData(updatedData);

    };

    useEffect(() => {
        setUpdatedListData(state?.info);
        setMemberShipId(state?.id);
        setMembershipTitle(state?.title)
    }, []);

    const saveEditConfig = () => {
        dispatch(editAppConfig({ appConfigDetail: updatedListData, configId: membershipId, title: membershipTitle })).then((data: any) => {
            if (data?.meta?.requestStatus == 'fulfilled') {
                navigate('/app-config')

            }
        }
        )
    }
    const isAppInfoEdited = useSelector((state: any) => state?.editConfig)

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={`Membership Info List`} />
                    <Breadcrumb
                        paths={[('dashboard'), ('app-config')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/app-config']}
                    />
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex flex-col">
                                </div>
                                <div className="flex items-center sm:mt-4 sm:w-full sm:flex-row-reverse sm:justify-center">
                                    <div className="flex justify-end">
                                        <button
                                            className="px-4 py-2 text-xs font-bold text-white uppercase bg-blue  w-[63px] h-[32px] inline-block"
                                            onClick={() => saveEditConfig()}
                                        >
                                            {isAppInfoEdited?.isLoading &&
                                                <ClipLoader color="#FFFFFF" size={20} />
                                            }
                                            {
                                                !isAppInfoEdited?.isLoading &&
                                                (
                                                    <>
                                                        Save
                                                    </>
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]">
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[274px]">
                                                    Billing Type
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[584px]">
                                                    Color
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[574px]">
                                                    Description
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[574px]">
                                                    Plan name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[574px]">
                                                    Price
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[574px]">
                                                    Default Selected
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[574px]">
                                                    Year
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[205px]">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : (
                                                <>
                                                    {updatedListData?.length ? (
                                                        updatedListData.map((data: any, index: number) => {
                                                            const isGrayRow = index % 2 === 0;
                                                            const isEditing = editingData && editingData.billingType === data.billingType;

                                                            return (
                                                                <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <input
                                                                                className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                type="text"
                                                                                value={updatedListData[index]?.billingType || ""}
                                                                                onChange={(event) =>
                                                                                    handleFieldChange(event, index, "billingType")
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            data?.billingType || '-'
                                                                        )}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <input
                                                                                className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                type="text"
                                                                                value={updatedListData[index]?.color || ""}
                                                                                onChange={(event) =>
                                                                                    handleFieldChange(event, index, "color")
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            data?.color || '-'
                                                                        )}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <input
                                                                                className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                type="text"
                                                                                value={updatedListData[index]?.description || ""}
                                                                                onChange={(event) =>
                                                                                    handleFieldChange(event, index, "description")
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            data?.description || '-'
                                                                        )}
                                                                    </td> <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <input
                                                                                className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                type="text"
                                                                                value={updatedListData[index]?.key || ""}
                                                                                onChange={(event) =>
                                                                                    handleFieldChange(event, index, "key")
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            data?.key || '-'
                                                                        )}
                                                                    </td>  <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <input
                                                                                className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                type="number"
                                                                                value={updatedListData[index]?.price || ""}
                                                                                onChange={(event) =>
                                                                                    handleFieldChange(event, index, "price")
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            data?.price || '-'
                                                                        )}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <Switch
                                                                                checked={updatedListData[index]?.selected || ""}
                                                                                onChange={(event) =>
                                                                                    handleSelectedChange(event, index, "selected")
                                                                                }
                                                                                onColor="#f27367"
                                                                                onHandleColor="#fff"
                                                                                handleDiameter={22}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                height={15}
                                                                                width={48}
                                                                                className="react-switch"
                                                                                id="material-switch"
                                                                            />
                                                                        ) : (
                                                                            data?.selected == true ? 'Active' : 'In Active' || '-'
                                                                        )}
                                                                    </td>
                                                                    <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                        {isEditing ? (
                                                                            <input
                                                                                className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                                                type="text"
                                                                                value={updatedListData[index]?.year || ""}
                                                                                onChange={(event) =>
                                                                                    handleFieldChange(event, index, "year")
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            data?.year || '-'
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {isEditing ? (
                                                                            ''
                                                                        ) : (
                                                                            <button className="text-xs font-bold text-black uppercase" onClick={() => editConfig(data)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                    <title>Edit</title>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                                                </svg>
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td
                                                                colSpan={8}
                                                                className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center"
                                                            >
                                                                <div>No Data Found</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipInfoListing;
