import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import {
    showToastErrorMessage,
    showToastSuccessMessage,
} from "../../utils/utils";

interface IChangePassword {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    userId: string,
    data: {
        old_password: string;
        new_password: string

    };
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    old_password: "",
    new_password: ""
};

export const changepassword: any = createAsyncThunk(
    "changepasswordSlice",
    async ({ data, userId }: IChangePassword) => {
        try {
            const response = await http.post(
                `${process.env.REACT_APP_BASE_URL}${apiconfig.authentication.changePassword}`,
                {
                    user_id: parseInt(userId),
                    old_password: data?.old_password,
                    new_password: data?.new_password

                }
            );
            if (response.status === 201) {
                showToastSuccessMessage(response.data.message);
                return response.data;
            } else {
                throw new Error("Invalid response status");
            }
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message);
            throw error; // Re-throw the error to be caught by rejected case
        }
    }
);

export const ChangepasswordSlice = createSlice({
    name: "changepasswordSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(changepassword.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(changepassword.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(changepassword.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const changepasswordSliceReducer = ChangepasswordSlice.reducer;
