import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { showToastErrorMessage } from "../../utils/utils";
import { editEmployee } from "../../feature/Employees/EditEmployees-Slice";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";
import { allZipcodelist } from "../../feature/Zipcode/AllZipcode-Slice";
import { addEmployee } from "../../feature/Employees/EmployeeBindAdd-Slice";
import Map from "./Map";

interface IFormInputs {
    first_name?: string;
    last_name?: string;
    zip?: string;
    city?: string;
    street?: any;
    street_line_2?: string;
    role?: string;
    email?: string;
    state?: string;
    lat?: any;
    long?: any;
    tags?: any;
    customAdd?: string;
    hcp_region_config_id?: any;
    region?: string;
    radius: any;
    employees: [];
    id: any;
}

const EditEmployee = () => {
    const [userId, setUserId] = useState("");
    const [userDetail, setUserDetail] = useState<IFormInputs>({
        first_name: "",
        last_name: "",
        zip: "",
        city: "",
        street: "",
        street_line_2: "",
        role: "",
        email: "",
        lat: "",
        long: "",
        tags: [],
        state: "",
        hcp_region_config_id: "",
        region: "",
        radius: 10,
        employees: [],
        id: null,
    });
    const [stateValue, setStateValue] = useState("");
    const [zipcodeList, setZipcodeList]: any = useState([]);
    const [selectedCoordLat, setSelectedCoordLat]: any = useState("");
    const [selectedCoordLong, setSelectedCoordLong]: any = useState("");
    const [customLat, setCustomLat] = useState("");
    const [customLng, setCustomLng] = useState("");
    const latStrng = selectedCoordLat?.toString();
    const longStrng = selectedCoordLong?.toString();
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [isCustomAddress, setIsCustomAddress] = useState(false);
    const [selectionMade, setSelectionMade] = useState(false);
    const [selectedAddressZipcode, setSelectedAddressZipcode] = useState();
    const [defaultRegionLatLng, setDefaultRegionLatLng]: any = useState({
        lat: '',
        lng: ''
    })

    const [isSetFocusOnAutocomplete, setIsSetFocusOnAutocomplete] =
        useState(false);
    const inputRef: any = useRef(null);
    const blurTimeoutRef: any = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<IFormInputs>();

    const location = useLocation()
    // To get the data of the user from the api.
    useEffect(() => {
        const path = window.location.pathname;
        const id = path?.split("/")[2];
        setUserId(id);
        Promise.all([
            dispatch(editEmployee({ id })),
            dispatch(hcpRegionlist()),
        ]).then((data) => {
            const userData = data[0]?.payload?.data?.data;
            const regionList = data[1]?.payload?.data?.data;
            setUserDetail(prevDetail => ({
                ...prevDetail,
                ...userData,
                radius: userData?.radius ?? prevDetail.radius,
            }));
            setHCPRegionList(regionList);
        });
    }, [window.location.pathname]);

    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        if (userDetail.street == "") {
            return false;
        }

        if (!googleAuto.current && !isCustomAddress) {
            showToastErrorMessage(`Address must be of ${userDetail?.state} region.`);
            return false;
        }

        if (selectedAddressZipcode) {
            if (selectedAddressZipcode !== userDetail.zip && !isCustomAddress) {
                showToastErrorMessage(
                    `Selected address zipcode (${userDetail.zip}) doesn't match.`
                );
                return false;
            }
        }
        dispatch(
            addEmployee({
                userId,
                userDetail,
                stateValue,
                selectedCoordLat: isCustomAddress
                    ? customLat
                    : latStrng
                        ? latStrng
                        : userDetail.lat,
                selectedCoordLong: isCustomAddress
                    ? customLng
                    : longStrng
                        ? longStrng
                        : userDetail.long,
            })
        ).then((data: any) => {
            if (data?.meta?.requestStatus == "fulfilled") {
                const path = window.location.pathname;
                const id = path?.split("/")[2];
                setUserId(id);
                Promise.all([
                    dispatch(editEmployee({ id })),
                    dispatch(hcpRegionlist()),
                ]).then((data) => {
                    setUserDetail(data[0]?.payload?.data?.data);
                    // setStateValue(data[0]?.payload?.data?.data?.state);
                    setHCPRegionList(data[1]?.payload?.data?.data);
                });
            }
        });
    };

    const { isLoading } = useSelector((state: any) => state?.editEmployeeSlice);
    const addEmployeeLoader = useSelector(
        (state: any) => state?.addEmployeeSlice
    );
    const isUserEdited = useSelector((state: any) => state?.editUser);

    const googleAuto = useRef<boolean>(true);

    const handlePlaceSelect = async (place: any) => {
        const street = place.label;
        setUserDetail((prevUserDetail) => ({
            ...prevUserDetail,
            street,
        }));
        setValue("street", street, { shouldValidate: true });
        setSelectionMade(true);
        const terms = place?.value?.terms;

        for (let i = 0; i < terms?.length; i++) {
            if (terms[i]?.value == stateValue) {
                googleAuto.current = true;
                break;
            }
        }
        const temp = await geocodeByPlaceId(place?.value?.place_id);
        setSelectedCoordLat(temp?.[0]?.geometry?.location?.lat());
        setSelectedCoordLong(temp?.[0]?.geometry?.location?.lng());

        const lat = temp?.[0]?.geometry?.location?.lat();
        const lng = temp?.[0]?.geometry?.location?.lng();

        setValue("lat", lat, { shouldValidate: true });
        setValue("long", lng, { shouldValidate: true });

        // After setting new values, manually trigger validation for these fields
        await trigger(["lat", "long"]);
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${temp?.[0]?.geometry?.location?.lat()},${temp?.[0]?.geometry?.location?.lng()}&key=${process.env.REACT_APP_GOOGLE_KEY
                }`
            );

            if (!response.ok) {
                throw new Error("Failed to retrieve address details");
            }

            const data = await response.json();

            // Extracting the zip code from the results
            const zipCode: any = data.results[0]?.address_components.find(
                (component: any) => component.types.includes("postal_code")
            )?.short_name;

            setSelectedAddressZipcode(zipCode);

            const city = data.results[0]?.address_components.find((component: any) =>
                component.types.some(
                    (type: any) =>
                        type === "locality" || type === "administrative_area_level_3"
                )
            )?.short_name;

            // Here you should add your method to set the city value in your form or application state
            // Example:
            setUserDetail((prevUserDetail) => ({
                ...prevUserDetail,
                city,
            }));
            setValue("city", city, { shouldValidate: true });

            return { zipCode, city };
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        if (userDetail?.region) {
            ZipcodeList();
        }
    }, [userDetail?.region]);

    const ZipcodeList = () => {
        if (userDetail?.region) {
            dispatch(allZipcodelist({ stateCode: userDetail.region })).then(
                (data: any) => {
                    setZipcodeList(data?.payload?.data?.data);
                }
            );
        }
    };

    const handleBlur = () => {
        blurTimeoutRef.current = setTimeout(() => {
            if (!selectionMade) {
                setIsCustomAddress(true);
                setValue("street", userDetail.street, { shouldValidate: true });
            }
        }, 300);
    };

    useEffect(() => {
        return () => {
            clearTimeout(blurTimeoutRef.current);
        };
    }, []);

    useEffect(() => {
        if (hcpRegionList) {
            const stateName: any = hcpRegionList?.find(
                (val: any) => val?.id == userDetail?.hcp_region_config_id
            );
            setUserDetail((prevUserDetail: any) => ({
                ...prevUserDetail,
                state: stateName?.name,
                region: stateName?.region,
            }));
            setStateValue(stateName?.region);
        }
    }, [hcpRegionList]);

    const switchToCustomAddress = () => {
        setIsCustomAddress(true);
        setIsSetFocusOnAutocomplete(false);
        setUserDetail((prevUserDetail) => ({
            ...prevUserDetail,
            street: userDetail.street,
        }));
        setSelectedCoordLat("");
        setSelectedCoordLong("");
        setValue("street", "", { shouldValidate: true });
    };

    useEffect(() => {
        if (zipcodeList) {
            const temp = zipcodeList?.find(
                (data: any) => data?.zipcode == selectedAddressZipcode
            );
            if (temp) {
                setUserDetail((prevDetail: any) => ({
                    ...prevDetail,
                    zip: temp?.zipcode,
                }));
            } else {
                setUserDetail((prevDetail: any) => ({
                    ...prevDetail,
                    zip: "",
                }));
            }
        }
    }, [selectedAddressZipcode]);

    const handleCancel = () => {
        navigate("/hcp-employee");
    };


    useEffect(() => {
        if (userDetail.region) {
            const latlng: any = hcpRegionList.find((val: any) => val?.region == userDetail.region)
            setDefaultRegionLatLng((prevDetail: any) => ({
                ...prevDetail,
                lat: latlng.lat,
                lng: latlng.long

            }))
        }

    }, [hcpRegionList, userDetail.region])

    useEffect(() => {
        if (userDetail.radius != null) {
            setValue("radius", userDetail.radius, { shouldValidate: true });
        }
    }, [userDetail.radius, setValue]);

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="flex justify-between items-center">
                        <div>
                            <Title title={"HCP Employee Address Details"} />
                            <Breadcrumb
                                paths={["dashboard", "hcp-employee"]}
                                className="text-sm mb-3"
                                url={["/dashboard", "/hcp-employee"]}
                            />
                        </div>
                    </div>
                    {isLoading || addEmployeeLoader?.isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4 flex">
                            <div className="w-1/2 p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3">
                                            <div className="px-3 flex items-center">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-0 whitespace-nowrap"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Name :
                                                </label>
                                                <label
                                                    className="appearance-none border-transparent bg-transparent block w-full border rounded py-1 px-2 mb-0 leading-tight focus:outline-none focus:bg-tranparent"
                                                    id="grid-first-name"
                                                >
                                                    {`${userDetail?.first_name} ${userDetail?.last_name} (${userDetail?.email})`}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3  mb-4">
                                            <div className="w-1/2 px-3 flex items-center">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-0 whitespace-nowrap"
                                                    htmlFor="grid-state"
                                                >
                                                    Region :
                                                </label>
                                                <div className="relative">
                                                    <label
                                                        className="appearance-none border-transparent bg-transparent block w-full border rounded py-1 px-2 mb-0 leading-tight focus:outline-none focus:bg-tranparent"
                                                        id="grid-state"
                                                    >
                                                        {userDetail.state ? userDetail.state : "-"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="flex -mx-3 mb-2"> */}
                                        <div className="w-full address-select-wrapper mb-4">
                                            <label
                                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-address-1"
                                            >
                                                Address Line 1
                                                <span className="pl-[4px] text-red">*</span>
                                            </label>
                                            {isCustomAddress ? (
                                                <input
                                                    autoComplete="off"
                                                    value={userDetail?.street || ""}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    {...register("street", {
                                                        required: !userDetail.street?.trim() ? true : false,
                                                    })}
                                                    placeholder="Enter address"
                                                    onChange={(e) => {
                                                        clearTimeout(blurTimeoutRef.current);
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            street: "",
                                                        }));
                                                        const value = e?.target?.value;
                                                        if (value) {
                                                            setIsCustomAddress(false);
                                                            setIsSetFocusOnAutocomplete(true);
                                                            setSelectedCoordLat("");
                                                            setSelectedCoordLong("");
                                                        }

                                                        setValue("street", value, {
                                                            shouldValidate: true,
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <GooglePlacesAutocomplete
                                                    {...register("street", {
                                                        required: !userDetail.street?.trim() ? true : false,
                                                    })}
                                                    ref={inputRef}
                                                    apiKey={process.env.REACT_APP_GOOGLE_KEY as any}
                                                    selectProps={{
                                                        placeholder: userDetail?.street
                                                            ? userDetail?.street
                                                            : "Select...",
                                                        onChange: async (value: any) => {
                                                            const temp = value;
                                                            clearTimeout(blurTimeoutRef.current);
                                                            googleAuto.current = false;
                                                            handlePlaceSelect(temp);
                                                            setSelectedCoordLat("");
                                                            setSelectedCoordLong("");
                                                            setValue("street", value, {
                                                                shouldValidate: true,
                                                            });
                                                        },
                                                        autoFocus: isSetFocusOnAutocomplete,
                                                        onBlur: handleBlur,
                                                        onInputChange: (value) => {
                                                            if (value !== "") {
                                                                setUserDetail((prevUserDetail) => ({
                                                                    ...prevUserDetail,
                                                                    street: value,
                                                                    lat: "",
                                                                    long: "",
                                                                }));
                                                            }
                                                            // setValue("street", value, { shouldValidate: true });
                                                        },
                                                        noOptionsMessage: () => (
                                                            <div
                                                                style={{
                                                                    cursor: "pointer",
                                                                    marginRight: "0px",
                                                                    textAlign: "left",
                                                                    color: "black",
                                                                }}
                                                                onClick={() => {
                                                                    switchToCustomAddress();
                                                                }}
                                                            >
                                                                {userDetail.street
                                                                    ? userDetail.street
                                                                    : "No options"}
                                                            </div>
                                                        ),
                                                    }}
                                                    autocompletionRequest={{
                                                        bounds: [
                                                            { lat: 34.0489, lng: 111.0937 }, // Example bounds
                                                            { lat: 39.321, lng: 111.0937 },
                                                        ],
                                                        componentRestrictions: {
                                                            country: ["us"],
                                                        },
                                                        types: ["geocode"],
                                                        radius: 10,
                                                    }}
                                                    debounce={300}
                                                />
                                            )}
                                            {(errors.street ||
                                                (isCustomAddress && !userDetail.street?.trim())) && (
                                                    <p className="text-red text-sm">
                                                        {"Address Line 1 is required"}
                                                    </p>
                                                )}
                                        </div>

                                        <div className="mb-4 pt-[1px]">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 mt-2"
                                                htmlFor="grid-address-2"
                                            >
                                                Address Line 2
                                            </label>
                                            <input
                                                {...register("street_line_2", {})}
                                                className="appearance-none border-darkGray block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-address-2"
                                                type="text"
                                                placeholder="Address Line 2"
                                                value={userDetail?.street_line_2}
                                                onChange={(e) =>
                                                    setUserDetail((prevUserDetail) => ({
                                                        ...prevUserDetail,
                                                        street_line_2: e.target.value,
                                                    }))
                                                }
                                            />
                                        </div>
                                        {/* </div> */}
                                        <div className="flex  gap-x-2 mb-4">
                                            <div className="w-1/2">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2 mt-2"
                                                    htmlFor="grid-city"
                                                >
                                                    City
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        {...register("city", {
                                                            required:
                                                                userDetail.city == "" || userDetail.city == null
                                                                    ? true
                                                                    : false,
                                                        })}
                                                        className="appearance-none border-darkGray block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                        id="grid-city"
                                                        type="text"
                                                        placeholder="Enter city"
                                                        value={userDetail?.city}
                                                        onChange={(e) => {
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                city: e.target.value,
                                                            }));
                                                            setValue("city", e.target.value, {
                                                                shouldValidate: true,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <p className="text-red text-sm">
                                                    {errors?.city && "City is required"}
                                                </p>
                                            </div>
                                            <div className="w-1/2">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2 mt-2"
                                                    htmlFor="grid-zipcode"
                                                >
                                                    Zipcode
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("zip", {
                                                            required:
                                                                userDetail.zip == "" || userDetail.zip == null
                                                                    ? true
                                                                    : false,
                                                        })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-zipcode"
                                                        value={userDetail?.zip}
                                                        onChange={(e) => {
                                                            // handlePlaceSelect(userDetail?.street)
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                zip: selectedAddressZipcode,
                                                                city: "",
                                                                street: "",
                                                                lat: "",
                                                                long: "",
                                                            }));
                                                            setSelectedCoordLat("");
                                                            setCustomLat("");
                                                            setSelectedCoordLong("");
                                                            setCustomLng("");
                                                            setIsCustomAddress(true);
                                                            setValue("zip", e.target.value, {
                                                                shouldValidate: true,
                                                            });
                                                        }}
                                                    >
                                                        {zipcodeList == " " && (
                                                            <option value="">
                                                                {`Zipcode is not available for this state.`}
                                                            </option>
                                                        )}
                                                        {zipcodeList &&
                                                            zipcodeList?.map((state: any, index: any) => {
                                                                return (
                                                                    <option key={index} value={state?.zipcode}>
                                                                        {state?.zipcode}
                                                                    </option>
                                                                );
                                                            })}
                                                        <option value="" selected hidden>
                                                            Select zipcode
                                                        </option>
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.zip && (
                                                    <p className="text-red text-sm">
                                                        {errors.zip.type === "required" &&
                                                            "Zipcode is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex mb-4 gap-x-2">
                                            <div className="w-1/2">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2 mt-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Latitude
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    disabled={!isCustomAddress}
                                                    {...register("lat", {
                                                        required:
                                                            userDetail.lat == "" || userDetail.lat == null
                                                                ? true
                                                                : false,
                                                    })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="number"
                                                    step="any"
                                                    placeholder="Latitude"
                                                    value={
                                                        userDetail.lat
                                                            ? userDetail.lat
                                                            : selectedCoordLat
                                                                ? selectedCoordLat
                                                                : customLat
                                                    }
                                                    // value={isCustomAddress ? customLat : selectedCoordLat ? selectedCoordLat : userDetail.lat}
                                                    onChange={(e: any) => {
                                                        setCustomLat(e?.target?.value);
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            lat: e.target.value,
                                                        }));
                                                        setValue("lat", e.target.value, {
                                                            shouldValidate: true,
                                                        });
                                                    }}
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.lat && "Latitude is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2 mt-2"
                                                    htmlFor="grid-last-name"
                                                >
                                                    Longitude
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    disabled={!isCustomAddress}
                                                    {...register("long", {
                                                        required:
                                                            userDetail.long == "" || userDetail.long == null
                                                                ? true
                                                                : false,
                                                    })}
                                                    className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-last-name"
                                                    type="number"
                                                    step="any"
                                                    placeholder="Longitude"
                                                    value={
                                                        userDetail.long
                                                            ? userDetail.long
                                                            : selectedCoordLong
                                                                ? selectedCoordLong
                                                                : customLng
                                                    }
                                                    // value={isCustomAddress ? customLng : selectedCoordLong ? selectedCoordLong : userDetail.long}
                                                    onChange={(e: any) => {
                                                        setCustomLng(e?.target?.value);
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            long: e.target.value,
                                                        }));
                                                        setValue("long", e.target.value, {
                                                            shouldValidate: true,
                                                        });
                                                    }}
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.long && "Longitude is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 mt-2"
                                                htmlFor="grid-last-name"
                                            >
                                                Radius
                                                <span className="pl-[4px] text-red">*</span>
                                            </label>

                                            <input
                                                // disabled={!isCustomAddress}
                                                {...register("radius", {
                                                    required:
                                                        userDetail.radius == null ||
                                                            userDetail.radius === ""
                                                            ? "Radius is required"
                                                            : false,
                                                    validate: (value) => {
                                                        if (value === "" || value === null) {
                                                            return "Radius is required";
                                                        }
                                                        if (value === "0" || Number(value) <= 0) {
                                                            return "Radius must be greater than 0";
                                                        }
                                                        return true;
                                                    },
                                                })}
                                                className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-last-name"
                                                type="number"
                                                step="any"
                                                min="0"
                                                placeholder="Radius"
                                                value={userDetail.radius}
                                                onChange={(e: any) => {
                                                    setUserDetail((prevUserDetail) => ({
                                                        ...prevUserDetail,
                                                        radius: e.target.value,
                                                    }));
                                                    setValue("radius", e.target.value, {
                                                        shouldValidate: true,
                                                    });
                                                }}
                                            />
                                            <p className="text-red text-sm">
                                                {(userDetail.radius == "0" &&
                                                    "Radius should be greater than 0.") ||
                                                    (errors?.radius?.message && "Radius is required.")}
                                            </p>
                                        </div>
                                        <div className="flex -mx-3  mt-5 justify-end pr-4">
                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm"
                                                onClick={() => {
                                                    handleCancel();
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm  ml-2 w-[81px] h-[36px] inline-block"
                                                disabled={isUserEdited?.isLoading}
                                            >
                                                {isUserEdited?.isLoading && (
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                )}
                                                {!isUserEdited?.isLoading && <>Submit</>}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <Map
                                    UserLatitude={
                                        userDetail.lat
                                            ? userDetail.lat
                                            : selectedCoordLat
                                                ? selectedCoordLat
                                                : customLat
                                                    ? customLat
                                                    : defaultRegionLatLng?.lat
                                    }
                                    UserLongitude={
                                        userDetail.long
                                            ? userDetail.long
                                            : selectedCoordLat
                                                ? selectedCoordLong
                                                : customLng
                                                    ? customLng
                                                    : defaultRegionLatLng?.lng
                                    }
                                    UserRadius={userDetail?.radius}
                                    otherCircleData={userDetail?.employees}
                                    userID={userDetail?.id}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditEmployee;

