
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";


interface IDeleteAdvertisement {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  id: number;
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const deleteAdvertisement: any = createAsyncThunk(
  "DeleteAdvertiseSlice",
  async ({ id }: IDeleteAdvertisement) => {
    try {
      const response = await http.delete(`${process.env.REACT_APP_BASE_URL}${apiconfig.advertisement.deleteAdvertisement}/${id}`, {
      }).then((data: any) => {
        showToastSuccessMessage(data?.data?.message)
      })
      return response
    } catch (error: any) {
      showToastErrorMessage(error?.response?.data?.message)
      throw error
    }
  }
);

export const DeleteAdvertiseSlice = createSlice({
  name: "DeleteAdvertiseSlice",
  initialState,
  reducers: {
    isAdvertisementDeleted: (state) => {
      state.isSuccess = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteAdvertisement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAdvertisement.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(deleteAdvertisement.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
});

export const deleteAdvertiseSliceReducer = DeleteAdvertiseSlice.reducer;
