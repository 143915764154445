import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import { userpropertylist } from "../../feature/Property/UserPropeties-Slice";
import { syncCustomerAddress } from "../../feature/AddressRequest/CustomerAddressSync-Slice";

let PageSize = 1;

const UserPropertiesList = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [userPropertyListData, setUserPropertyListData] = useState([])
    const [searchCount, setSearchCount] = useState(0)

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const temp = 'all-prop'

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            userPropertyList();
        }
    }, [currentPage, navigate, searchResult]);

    const userPropertyList = () => {
        dispatch(userpropertylist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult
        })).then((data: any) => { setUserPropertyListData(data?.payload?.data?.data?.list); setCount(data?.payload?.data?.data?.totalRecords); setSearchCount(data?.payload?.data?.data?.count) }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.userProperty)
    const property: any = 'ALL-PROP'
    const isCustomerAddressSync = useSelector((state: any) => state?.syncCustomerAddress);

    const syncCustomerAddresshandler = () => {
        dispatch(syncCustomerAddress()).then((val: any) => {
            if (val?.meta?.requestStatus == "fulfilled") {
                setTimeout(() => {
                    userPropertyList();
                }, 4000)
            }
        });
    }

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5">
                        <Title title={`Property Lists`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex items-center justify-between w-full" >
                                    <Search
                                        setSearchResult={setSearchResult}
                                        searchResult={searchResult}
                                        currentPage={setCurrentPage}
                                    />
                                    <span className="icon inline-block mx-2 sm:ml-0" onClick={() => syncCustomerAddresshandler()} title='Click here to sync'>
                                        <svg
                                            className="cursor-pointer hover:animate-spin"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            id="reload"
                                        >
                                            <path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden w-[full]" >
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                    Full name
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[582px]">
                                                    Email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[719px]">
                                                    Address Line 1
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[350px]">
                                                    Address Line 2
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                    City
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                    State
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                    Zipcode
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[299px]">
                                                    Latitude <br />
                                                    Longitude
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[199px]">
                                                    Created from
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[194px]">
                                                    Job List
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[194px]">
                                                    Estimate List
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[207px]">
                                                    Created at
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading || isCustomerAddressSync?.isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {userPropertyListData?.length ? userPropertyListData.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.first_name?.charAt(0)?.toUpperCase() + data?.first_name?.slice(1)}  {data?.last_name?.charAt(0)?.toUpperCase() + data?.last_name?.slice(1)}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.email ? data?.email : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray align-middle whitespace-pre-line">
                                                                {data?.street ? data?.street : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-pre-line align-middle">
                                                                {data?.street_line_2 ? data?.street_line_2 : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.city ? data?.city : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.state ? data?.state : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.zip ? data?.zip : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.lat && data?.long ? <>{data.lat},<br />{data.long}</> : data?.lat == 'undefined' && data?.long == 'undefined' ? '-' : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.created_from ? data?.created_from : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <span className="flex items-center">
                                                                    <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                        onClick={() => { navigate(`/users/jobs/${data.id}`, { state: { data, property } }) }}
                                                                    >
                                                                        <button className=" text-xs font-bold text-black uppercas" >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                                <title>Jobs list</title>
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                <span className="flex items-center">
                                                                    <span className="inline-block hover:fill-green transition ease-in-out duration-300 cursor-pointer"
                                                                        onClick={() => { navigate(`/estimate-address/${data.id}`, { state: { data, temp } }) }}
                                                                    >
                                                                        <button className=" text-xs font-bold text-black uppercas" >
                                                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline>
                                                                                <title>Estimate list</title></svg>
                                                                        </button>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-14">
                        {
                            userPropertyListData && userPropertyListData?.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserPropertiesList;
