import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useContext, useEffect } from "react";
import { clearLocalStorage, getLocalStorage } from "../utils/utils";
import RegionContext from "../utils/RegionContext";
const ProtectedRoutes = () => {

  const checkLoginStatus = () => {
    const isLoggedIn = Cookies.get('loggedIn');
    if (!isLoggedIn) {
      clearLocalStorage();
      // window.location.href = '/login';
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const token: string | null = localStorage.getItem("accessToken");
  return token ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
