import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    serviceList: []
};

export const industrylist: any = createAsyncThunk(
    "IndustryListSlice",
    async () => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.taxonomy?.industryList}`)
        return response
    }
);

export const IndustryListSlice = createSlice({
    name: "IndustryListSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(industrylist.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(industrylist.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(industrylist.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const industryListSliceReducer = IndustryListSlice.reducer;
