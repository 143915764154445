import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IAdvertisementDetails {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  islogedIn: boolean;
  id: string;
}

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const advertisedetails: any = createAsyncThunk(
  "AdvertiseDetailSlice",
  async ({ id }: IAdvertisementDetails) => {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.advertisement?.advertisementdetails}/${id}`)
    return response
  }
);

export const AdvertiseDetailSlice = createSlice({
  name: "AdvertiseDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(advertisedetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(advertisedetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(advertisedetails.rejected, (state) => {
      state.isError = true;
    });
  },
});

export const advertiseDetailSliceReducer = AdvertiseDetailSlice.reducer;
