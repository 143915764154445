import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "../../components/breadcrumb";
import { ClipLoader } from "react-spinners";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import { estimatelistAddress } from "../../feature/Estimate/EstimateListAddress-Slice";
interface IFormInputs {
    task_description?: string;
    status?: any;
    created_at?: string;
    updated_at?: string;
    id?: string;
    task_title?: string;
    attachments?: [];
    customer_id?: string;
    address_id?: string;
    department?: string

}

const ViewEstimate = () => {
    const [addressEstimateView, setAddressEstimateView] = useState<IFormInputs>({
        task_description: "",
        status: "",
        task_title: '',
        created_at: "",
        updated_at: "",
        id: '',
        attachments: [],
        customer_id: '',
        address_id: '',
        department: ''
    })
    const [EstimateView, setEstimateView] = useState([])
    const [EstimateLineItems, setEstimateLineItems]: any = useState({})
    const [address, setAddress]: any = useState({})
    const params = useParams()
    const [fullName, setFullName]: any = useState({
        firstName: '',
        lastName: ''
    })
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state: any) => state?.estimateAddressList)
    const location = useLocation()

    // To get the data of the user from the api.
    useEffect(() => {
        dispatch(estimatelistAddress({ id: params?.id, page: 1, limit: 10 })).then((data: any) => {
            setEstimateView(data?.payload?.data?.data?.estimationList); setFullName((preValue: any) => {
                return {
                    ...preValue,
                    firstName: data?.payload?.data?.data?.estimationList[0]?.first_name,
                    lastName: data?.payload?.data?.data?.estimationList[0]?.last_name
                }
            });
            setAddress(data?.payload?.data?.data?.address)

        })
    }, [window.location.pathname]);


    useEffect(() => {
        EstimateView?.map((data: any) => {
            setAddressEstimateView(data)
            data?.options?.line_items?.map((val: any) => {
                setEstimateLineItems(val)
            })

        })
    }, [EstimateView])


    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={`Estimate Details of ${address?.street ? address?.street : ''}  (${fullName?.firstName ? fullName?.firstName?.charAt(0)?.toUpperCase() + fullName?.firstName?.slice(1) : ''}  ${fullName?.lastName ? fullName?.lastName?.charAt(0)?.toUpperCase() + fullName?.lastName?.slice(1) : ''})`} />
                    <Breadcrumb
                        paths={[('dashboard'), location?.state?.tem == 'users' && location?.state?.id !== undefined ? ('users') : ('users-properties'), location?.state?.id ? (`${`Property list of ${fullName?.firstName} ${fullName?.lastName}`}`) : (''), (`estimate-list of ${address?.street ? address?.street : ''} (${fullName?.firstName ? fullName?.firstName?.charAt(0)?.toUpperCase() + fullName?.firstName?.slice(1) : ''}  ${fullName?.lastName ? fullName?.lastName?.charAt(0)?.toUpperCase() + fullName?.lastName?.slice(1) : ''})`)]}
                        className="text-sm mb-3"
                        url={`${params?.id ? `${`estimate-address/${params?.id}`}` : ''}`}
                        url2={`${location?.state?.id ? `${`users/address-list/${location?.state?.id}`}` : ''}`}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4 ">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray mb-[20px]">
                                <div className="flex flex-col w-full">
                                    <div className="w-full max-w-full relative">
                                        <div className="px-3 flex items-center gap-x-10">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px] "
                                                htmlFor="grid-first-name"
                                            >
                                                Id
                                            </label>
                                            <h1 className='appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white'>{addressEstimateView?.id}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                customer_id
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{addressEstimateView?.customer_id ? addressEstimateView?.customer_id : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                address_id
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{addressEstimateView?.address_id ? addressEstimateView?.address_id : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                title
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{addressEstimateView?.task_title ? addressEstimateView?.task_title?.charAt(0)?.toUpperCase() + addressEstimateView?.task_title?.slice(1) : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                Description
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{addressEstimateView?.task_description ? addressEstimateView?.task_description?.charAt(0)?.toUpperCase() + addressEstimateView?.task_description?.slice(1) : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                department
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">
                                                {addressEstimateView?.department ? addressEstimateView?.department?.charAt(0)?.toUpperCase() + addressEstimateView?.department?.slice(1) : '-'}
                                            </h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                status
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{`${addressEstimateView?.status === true ? 'Active' : addressEstimateView?.status == false ? 'In Activr' : '-'}`}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                created at
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{addressEstimateView?.created_at ? moment(addressEstimateView?.created_at).format('YYYY-MM-DD HH:mm') : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                updated at
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{addressEstimateView?.updated_at ? moment(addressEstimateView?.updated_at).format('YYYY-MM-DD HH:mm') : '-'}</h1>
                                        </div>
                                        {/* <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                Attachment
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">
                                                {addressEstimateView?.attachments?.length ? (
                                                    <>
                                                        {addressEstimateView?.attachments?.map((note: any) => note?.content).join(', ')}
                                                    </>
                                                ) : '-'}
                                            </h1>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <Title title={"Estimate Line Items"} />
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <div className="overflow-x-auto overflow-custom-scroll overflow-hidden">
                                        <table className="w-full text-left table-auto mt-[10px]">
                                            <thead className="bg-logo text-white">
                                                <tr>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                        Name
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                        Quantity
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                        Unit cost
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4">
                                                        Unit price ($)
                                                    </th>
                                                    <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[300px]">
                                                        Description
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {EstimateLineItems ?

                                                    <tr>
                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                                            {EstimateLineItems?.name ? EstimateLineItems?.name : "-"}
                                                        </td>
                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                                            {EstimateLineItems?.quantity || EstimateLineItems?.quantity == 0 ? EstimateLineItems?.quantity : '-'}
                                                        </td>
                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                                            {EstimateLineItems?.unit_cost || EstimateLineItems?.unit_cost == 0 ? EstimateLineItems?.unit_cost : '-'}
                                                        </td>
                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray ">
                                                            {EstimateLineItems?.unit_price || EstimateLineItems?.unit_price == 0 ? EstimateLineItems?.unit_price / 100 : '-'}
                                                        </td>
                                                        <td className="px-3 py-2 text-sm border-b border-solid border-gray  w-[300px]">
                                                            {EstimateLineItems?.description ? EstimateLineItems?.description : '-'}
                                                        </td>
                                                    </tr>

                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewEstimate;

