
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const masterPasswordList: any = createAsyncThunk(
    "MasterPasswordList",
    async () => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig.masterPassword.masterPasswordList}`)
        return response
    }
);

export const MasterPasswordListSlice = createSlice({
    name: "MasterPasswordList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(masterPasswordList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(masterPasswordList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(masterPasswordList.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const masterPasswordReducer = MasterPasswordListSlice.reducer;
