import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/header/Header";
import Title from "../../components/title/Title";
import Search from "../../components/search/Search";
import Pagination from "../../components/common/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { weblist } from "../../feature/WebAnalytics/WebAnalyticsListing-Slice";

let PageSize = 1;

const WebAnalytics = () => {
    const [searchResult, setSearchResult] = useState("");
    const [currentPage, setCurrentPage]: any = useState();
    const [count, setCount] = useState(0)
    const [webListData, setWebListData] = useState([])
    const [searchCount, setSearchCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState('')
    const [dateRange, setDateRange] = useState([null, null]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [startDate, endDate]: any = dateRange;
    const startFormatDate = moment(startDate).format('YYYY-MM-DD')
    const endFormatDate = moment(endDate).format('YYYY-MM-DD')

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const pageParam: any = queryParams.get("page");
        const page = parseInt(pageParam, 10) || 1;
        setCurrentPage(page);
    }, []);

    useEffect(() => {
        if (typeof currentPage !== 'undefined') {
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("page", String(currentPage));
            window.history.pushState('', '', `${window.location.pathname}?${queryParams.toString()}`)
            navigate(`?${queryParams.toString()}`);

            WenAnalyList();
        }
    }, [currentPage, navigate, searchResult, filterStatus]);

    const WenAnalyList = () => {
        dispatch(weblist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            status: filterStatus,
            start_date: startFormatDate ? startFormatDate : '',
            end_date: endFormatDate ? endFormatDate : ''
        })).then((data: any) => {
            setWebListData(data?.payload?.data?.data); setCount(data?.payload?.data?.totalRecords); setSearchCount(data?.payload?.data?.count)
        }
        )
    }

    const { isLoading } = useSelector((state: any) => state?.webAnalyticsList)

    const filterHandler = () => {

        if (!startDate || !endDate) {
            // Show an error message or handle the validation as needed
            return;
        }
        setCurrentPage(1)

        dispatch(weblist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            status: filterStatus,
            start_date: startFormatDate,
            end_date: endFormatDate
        })).then((data: any) => {
            setWebListData(data?.payload?.data?.data); setCount(data?.payload?.data?.totalRecords); setSearchCount(data?.payload?.data?.count)
        }
        )
    }

    const closeButton = () => {
        dispatch(weblist({
            page: currentPage,
            limit: process.env.REACT_APP_PAGE_ITEM_LIMIT,
            search_text: searchResult,
            status: filterStatus,
        })).then((data: any) => { setWebListData(data?.payload?.data?.data); setCount(data?.payload?.data?.totalRecords); setSearchCount(data?.payload?.data?.count) }
        )
        setCurrentPage(1)
    }

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <div className="pb-5 flex">
                        <Title title={`Web Analytics: Search Data`} />
                    </div>
                    <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                        <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                            <div className="flex flex-row items-center justify-between mb-6 sm:flex-wrap sm:justify-center">
                                <div className="flex items-center justify-between w-full" >
                                    <Search
                                        setSearchResult={setSearchResult}
                                        searchResult={searchResult}
                                        currentPage={setCurrentPage}
                                    />
                                    <div date-rangepicker className="flex items-center">
                                        <div className="relative  mr-[10px]">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                </svg>
                                            </div>
                                            <DatePicker
                                                showIcon
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update: any) => {
                                                    setDateRange(update);
                                                    if (!update || (update[0] === null && update[1] === null)) {
                                                        // setFilterStatus('')
                                                        // setDateRange([null, null])
                                                        closeButton(); // Trigger the JobList function
                                                    }
                                                }}
                                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[240px] ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                                placeholderText="Select date range"
                                                dateFormat="yyyy /MM/dd"
                                                selectsRange={true}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type='button'
                                        className="px-4 py-2 text-xs font-bold text-white uppercase bg-logo text-right rounded-lg"
                                        onClick={filterHandler}
                                    >
                                        Submit
                                    </button>

                                    <div className="relative w-[13%] items-center justify-end ml-[650px]">
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full">
                                <div className="overflow-x-auto overflow-custom-scroll overflow-hidden" >
                                    <table className="w-full text-left table-auto">
                                        <thead className="bg-logo text-white">
                                            <tr>
                                                <th className="px-4 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[203px]">
                                                    Search Value
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[167px]">
                                                    Email
                                                </th>
                                                <th className="px-3 py-2 text-xs font-medium tracking-wider uppercase border-b border-solid border-gray leading-4 w-[183px]">
                                                    Created at
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    <ClipLoader color="#f27366" size={50} />
                                                </div>
                                            ) : <>
                                                {webListData?.length ? webListData.map((data: any, index: any) => {
                                                    const isGrayRow = index % 2 === 0;
                                                    return (
                                                        <tr key={index} className={isGrayRow ? 'bg-gray-table' : ''}>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data.search_data ? data.search_data : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.email ? data?.email : '-'}
                                                            </td>
                                                            <td className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap align-middle">
                                                                {data?.created_at ? moment(data?.created_at).format('YYYY-MM-DD HH:mm') : '-'}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={8} className="px-3 py-2 text-sm border-b border-solid border-gray whitespace-nowrap text-center">
                                                            <div>No Data Found</div>
                                                        </td>
                                                    </tr>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center my-12">
                        {
                            webListData && webListData?.length > 0 && isLoading == false && (searchCount >= 10 || currentPage >= 2) &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={count}
                                pageSize={PageSize}
                                onPageChange={(page: React.SetStateAction<number>) =>
                                    setCurrentPage(page)
                                }
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebAnalytics;