import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Breadcrumb } from "../../components/breadcrumb";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import Header from "../../components/header/Header";
import { addUser } from "../../feature/Users/AddUser-Slice";
import { appConfiglist } from "../../feature/AppConfig/AppConfigList-Slice";
import moment from "moment";
import { allZipcodelist } from "../../feature/Zipcode/AllZipcode-Slice";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { showToastErrorMessage } from "../../utils/utils";
import { hcpRegionlist } from "../../feature/HCP Region/HCPRegionListing-Slice";

interface IFormInputs {
    first_name?: string;
    last_name?: string;
    membership_plan?: any;
    mobile?: any;
    zipcode?: string;
    city?: string;
    address?: any;
    address_line_1?: string;
    state?: string;
    email?: string;
    latitude?: any;
    longitude?: any;
}

const AddUser = () => {
    const [userDetail, setUserDetail] = useState<IFormInputs>({
        first_name: '',
        last_name: '',
        membership_plan: '',
        mobile: '',
        zipcode: '',
        city: '',
        address: '',
        address_line_1: '',
        state: '',
        email: '',
        latitude: '',
        longitude: '',
    })
    const [appConfigListData, setAppConfigListData]: any = useState([])
    const [zipcodeList, setZipcodeList]: any = useState([])
    const [stateValue, setStateValue] = useState('')
    const [selectedCoordLat, setSelectedCoordLat]: any = useState('');
    const [selectedCoordLong, setSelectedCoordLong]: any = useState('');
    const inputRef: any = useRef(null)
    const latStrng = selectedCoordLat?.toString()
    const longStrng = selectedCoordLong?.toString()
    const [selectedAddressZipcode, setSelectedAddressZipcode] = useState()
    const [temp, setTemp]: any = useState()
    const [hcpRegionList, setHCPRegionList] = useState([]);
    const [isCustomAddress, setIsCustomAddress] = useState(false);
    const [selectionMade, setSelectionMade] = useState(false);
    const [isSetFocusOnAutocomplete, setIsSetFocusOnAutocomplete] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger
    } = useForm<IFormInputs>();

    //Submit event for edit user.
    const onSubmit: SubmitHandler<IFormInputs> = async () => {
        if (userDetail.address == '') {
            return false
        }

        if (!googleAuto.current) {
            showToastErrorMessage('Address Line 1 is not exist for selected state.')
            return false
        }

        if (selectedAddressZipcode !== userDetail.zipcode) {
            showToastErrorMessage(`Selected address zipcode (${userDetail.zipcode}) doesn't match.`)
            return false
        }
        dispatch(addUser({ userDetail, membership_data, stateValue, selectedCoordLat: isCustomAddress ? userDetail.latitude : latStrng, selectedCoordLong: isCustomAddress ? userDetail.longitude : longStrng })).then((data: any) => {
            if (data?.meta?.requestStatus == 'fulfilled') {
                navigate('/users')
            }
        }
        )
    };

    const { isLoading } = useSelector((state: any) => state?.addUser)
    const membershipInfo = appConfigListData?.find((el: any) => el.title === 'MembershipInfo');

    if (membershipInfo && membershipInfo?.info?.length) {
        const desiredMembership = membershipInfo?.info?.find((item: any) => item?.key?.charAt(0)?.toLowerCase() + item?.key?.slice(1) === userDetail?.membership_plan);
        if (desiredMembership && desiredMembership.price) {
            var price = desiredMembership?.price;
        }
    }

    const getExpirationDate = (key: any) => {
        return key !== 'yearly'
            ? moment()
                .month(moment().get('month') + 1)
                .format('YYYY/MM/DD')
            : moment()
                .year(moment().get('year') + 1)
                .format('YYYY/MM/DD');
    };

    const membership_data = {
        type: userDetail?.membership_plan,
        name: userDetail?.membership_plan,
        price: price,
        purchase_date: moment().format('YYYY/MM/DD'),
        expiration_date: getExpirationDate(userDetail?.membership_plan),
    };

    useEffect(() => {
        AppConfigList();
    }, []);

    const AppConfigList = () => {
        dispatch(appConfiglist()).then((data: any) => { setAppConfigListData(data?.payload?.data?.data) }
        )
    }

    useEffect(() => {
        if (stateValue) {
            ZipcodeList();
        }
    }, [userDetail?.state]);

    const ZipcodeList = () => {
        dispatch(allZipcodelist({ stateCode: stateValue })).then((data: any) => {
            setZipcodeList(data?.payload?.data?.data)

        })
    }
    const googleAuto = useRef<boolean>(false)

    const handlePlaceSelect = async (place: any) => {
        const address = place.label;
        setUserDetail((prevUserDetail) => ({
            ...prevUserDetail,
            address
        }))
        setValue("address", address, { shouldValidate: true });
        setSelectionMade(true);
        const terms = place?.value?.terms
        for (let i = 0; i < terms?.length; i++) {
            if (terms[i]?.value == stateValue) { googleAuto.current = true; break };
        }
        const temp = await geocodeByPlaceId(place?.value?.place_id);
        setSelectedCoordLat(temp?.[0]?.geometry?.location?.lat())
        setSelectedCoordLong(temp?.[0]?.geometry?.location?.lng())

        const lat = temp?.[0]?.geometry?.location?.lat();
        const lng = temp?.[0]?.geometry?.location?.lng();

        setValue("latitude", lat, { shouldValidate: true });
        setValue("longitude", lng, { shouldValidate: true });

        // After setting new values, manually trigger validation for these fields
        await trigger(["latitude", "longitude"]);


        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${temp?.[0]?.geometry?.location?.lat()},${temp?.[0]?.geometry?.location?.lng()}&key=${process.env.REACT_APP_GOOGLE_KEY}`
            );

            if (!response.ok) {
                throw new Error('Failed to retrieve address details');
            }

            const data = await response.json();

            // Extracting the zip code from the results
            const zipCode: any = data.results[0]?.address_components.find(
                (component: any) => component.types.includes('postal_code')
            )?.short_name;
            setSelectedAddressZipcode(zipCode)
            return zipCode;
        } catch (error) {
            return null;
        }

    };

    const handleStateSelect = async (place: any) => {
        const terms = place?.value?.terms
        for (let i = 0; i < terms?.length; i++) {
            if (terms[i]?.value == userDetail.state) { googleAuto.current = true; break };
        }
    };

    useEffect(() => {
        handleStateSelect(temp);
    }, [userDetail.state, temp]);

    useEffect(() => {
        dispatch(hcpRegionlist()).then((val: any) => setHCPRegionList(val?.payload?.data?.data))
    }, [])

    const handleBlur = () => {
        if (!selectionMade && userDetail.address == '') {
            setUserDetail((prevUserDetail) => ({
                ...prevUserDetail,
                address: ''
            }));
        }
        setSelectionMade(false);
    };

    useEffect(() => {
        if (isCustomAddress) {
            setValue("address", userDetail.address || '', { shouldValidate: true });
        }
    }, [isCustomAddress, setValue, userDetail.address]);

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={"Add User"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('users')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/users']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                        className="w-full max-w-full"
                                    >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    First Name
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("first_name", { required: true })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="text"
                                                    placeholder="First Name"
                                                    value={userDetail?.first_name}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        const alphaOnly = inputValue.replace(/[^a-zA-Z\s]/g, '');
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            first_name: alphaOnly,
                                                        }));
                                                        setValue("first_name", alphaOnly, { shouldValidate: true });
                                                    }}
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.first_name && "First name is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-last-name"
                                                >
                                                    Last Name
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("last_name", { required: true })}
                                                    className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-last-name"
                                                    type="text"
                                                    placeholder="Last Name"
                                                    value={userDetail?.last_name}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        const alphaOnly = inputValue.replace(/[^a-zA-Z\s]/g, '');
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            last_name: alphaOnly,
                                                        }));
                                                        setValue("last_name", alphaOnly, { shouldValidate: true });
                                                    }}
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.last_name && "Last name is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Email
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("email", {
                                                        required: 'Email is required',
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                    className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-email"
                                                    type="email"
                                                    placeholder="Email"
                                                    value={userDetail?.email}
                                                    onChange={(e) => {

                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            email: e.target.value,
                                                        }))
                                                        setValue("email", e.target.value, { shouldValidate: true })

                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.email?.message}
                                                </p>
                                            </div>

                                            <div className="w-1/2 px-3">
                                                <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="grid-state">
                                                    Membership Plan
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("membership_plan", { required: true })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white"
                                                        id="grid-state"
                                                        value={userDetail?.membership_plan}
                                                        onChange={(e) => {
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                membership_plan: e.target.value,
                                                            }));
                                                            setValue("membership_plan", e.target.value, { shouldValidate: true });
                                                        }}
                                                    >
                                                        <option value="" hidden>
                                                            Select plan
                                                        </option>
                                                        <option value="yearly">Yearly</option>
                                                        <option value="monthly">Monthly</option>
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.membership_plan && (
                                                    <p className="text-red text-sm">
                                                        {errors.membership_plan.type === "required" && "Membership plan is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-state"
                                                >
                                                    State
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("state", { required: true })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-state"
                                                        value={userDetail?.state}
                                                        onChange={(event: any) => {
                                                            googleAuto.current = false;
                                                            const stateName: any = hcpRegionList.find((state: any) => state?.name === event.target.value);
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                state: event?.target?.value,

                                                            }));
                                                            setValue("state", event?.target?.value, { shouldValidate: true })
                                                            setStateValue(stateName?.region)
                                                        }}
                                                    >
                                                        <option value="" selected hidden>
                                                            Select state
                                                        </option>
                                                        {hcpRegionList && hcpRegionList.length > 0 ? (
                                                            hcpRegionList.map((state: any, index) => (
                                                                <option key={index} value={state.name} >
                                                                    {state.name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option value="" disabled>
                                                                -
                                                            </option>
                                                        )}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.state && (
                                                    <p className="text-red text-sm">
                                                        {errors.state.type === "required" && "State is required."}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-zipcode"
                                                >
                                                    Zipcode
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <select
                                                        {...register("zipcode", {
                                                            required: true,
                                                        })}
                                                        className="block border-darkGray appearance-none w-full border py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white "
                                                        id="grid-zipcode"
                                                        // placeholder="Select Zipcode"
                                                        value={userDetail?.zipcode}
                                                        onChange={(e) => {
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                zipcode: e.target.value,
                                                            }))
                                                            setValue("zipcode", e.target.value, { shouldValidate: true })

                                                        }
                                                        }
                                                    >
                                                        <option value="" selected hidden>
                                                            Select zipcode
                                                        </option>
                                                        {stateValue == '' &&
                                                            <option value="" disabled >
                                                                Please select state
                                                            </option>
                                                        }
                                                        {zipcodeList == '' && stateValue !== '' &&
                                                            <option value="" disabled >
                                                                {`Zipcode is not available for this state.`}
                                                            </option>
                                                        }
                                                        {zipcodeList?.map((state: any, index: any) => {
                                                            return (
                                                                <option key={index} value={state?.zipcode}>
                                                                    {state?.zipcode}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg
                                                            className="fill-current h-4 w-4"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                {errors.zipcode && (
                                                    <p className="text-red text-sm">
                                                        {errors.zipcode.type === "required" && "Zipcode is required."}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-phone"
                                                >
                                                    Phone
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    {...register("mobile", {
                                                        required: 'Phone is required.',
                                                        minLength: 10,
                                                        maxLength: 10,
                                                        pattern: /^[0-9]*$/,
                                                    })}
                                                    onKeyDown={(evt) => (evt.key === "e" && evt.preventDefault())}
                                                    className="appearance-none border-darkGray block w-full border border-red-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white"
                                                    id="grid-phone"
                                                    type="text"
                                                    placeholder="Enter 10-digit phone number"
                                                    value={userDetail?.mobile}
                                                    onChange={(e) => {
                                                        const formattedValue = e.target.value.replace(/\D/g, '');
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            mobile: parseInt(formattedValue),
                                                        }));
                                                        setValue("mobile", formattedValue, { shouldValidate: true });
                                                    }}
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.mobile?.message == '' && "Phone number should be exact 10 digits." || errors?.mobile && "Phone is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-city"
                                                >
                                                    City
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <div className="relative">
                                                    <input
                                                        {...register("city", { required: true })}
                                                        className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                        id="grid-city"
                                                        type="text"
                                                        placeholder="Enter city"
                                                        value={userDetail?.city}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const alphaOnly = inputValue.replace(/[^a-zA-Z]/g, '');
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                city: alphaOnly,
                                                            }));
                                                            setValue("city", alphaOnly, { shouldValidate: true });
                                                        }}
                                                    />
                                                </div>
                                                <p className="text-red text-sm">
                                                    {errors?.city && 'City is required'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3 address-select-wrapper">
                                                <label
                                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-address-1"
                                                >
                                                    Address Line 1
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                {isCustomAddress ? (
                                                    <input
                                                        value={userDetail?.address}
                                                        className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                        {...register("address", { required: true })}
                                                        placeholder='Enter address'
                                                        onChange={(e) => {

                                                            const value = e.target.value;
                                                            if (value) {
                                                                setIsCustomAddress(false)
                                                                setIsSetFocusOnAutocomplete(true)
                                                                setSelectedCoordLat(null)
                                                                setSelectedCoordLong('')
                                                            }
                                                            setUserDetail((prevUserDetail) => ({
                                                                ...prevUserDetail,
                                                                address: value
                                                            }));
                                                            setValue("address", value, { shouldValidate: true });
                                                        }}
                                                    />
                                                ) : (
                                                    <GooglePlacesAutocomplete
                                                        {...register("address", { required: true })}
                                                        ref={inputRef}
                                                        apiKey={process.env.REACT_APP_GOOGLE_KEY}
                                                        selectProps={{
                                                            placeholder: userDetail?.address ? userDetail?.address : 'Select...',
                                                            onChange: async (value) => {
                                                                googleAuto.current = false;
                                                                setTemp(value);
                                                                handlePlaceSelect(value);
                                                                setSelectedCoordLat('')
                                                                setSelectedCoordLong('')
                                                            },
                                                            autoFocus: isSetFocusOnAutocomplete,
                                                            onBlur: handleBlur,
                                                            onInputChange: (value) => {
                                                                if (value !== '') {
                                                                    setUserDetail((prevUserDetail) => ({
                                                                        ...prevUserDetail,
                                                                        address: value
                                                                    }));
                                                                }
                                                            },
                                                            noOptionsMessage: () => (
                                                                <div
                                                                    style={{ cursor: 'pointer', marginRight: '0px', textAlign: 'left', color: "black" }}
                                                                    onClick={() => { setIsCustomAddress(true); setIsSetFocusOnAutocomplete(false) }}
                                                                >
                                                                    {userDetail.address ? userDetail.address : 'No options'}
                                                                </div>
                                                            ),
                                                        }}
                                                        autocompletionRequest={{
                                                            bounds: [
                                                                { lat: 34.0489, lng: 111.0937 }, // Example bounds
                                                                { lat: 39.3210, lng: 111.0937 },
                                                            ],
                                                            componentRestrictions: {
                                                                country: ['us'],
                                                            },
                                                            types: ['geocode'],
                                                            radius: 100000,
                                                        }}
                                                        debounce={300}

                                                    />
                                                )}
                                                {
                                                    errors.address && (
                                                        <p className="text-red text-sm">
                                                            {'Address Line 1 is required'}
                                                        </p>
                                                    )
                                                }
                                            </div >
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-address-2"
                                                >
                                                    Address Line 2
                                                </label>
                                                <input
                                                    {...register("address_line_1", {})}
                                                    className="appearance-none border-darkGray block w-full border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-address-2"
                                                    type="text"
                                                    placeholder="Address Line 2"
                                                    value={userDetail?.address_line_1}
                                                    onChange={(e) =>
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            address_line_1: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </div>
                                        </div >
                                        <div className="flex -mx-3 mb-2">
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-first-name"
                                                >
                                                    Latitude
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    disabled={!isCustomAddress}
                                                    {...register("latitude", {
                                                        required: userDetail.latitude == '' || selectedCoordLat == '' ? true : false,
                                                    })}
                                                    className="appearance-none block w-full border border-darkGray rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-first-name"
                                                    type="number"
                                                    step="any"
                                                    placeholder="Latitude"
                                                    value={isCustomAddress ? userDetail.latitude : selectedCoordLat}
                                                    onChange={(e) => {
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            latitude: e.target.value,
                                                        }))
                                                        setValue("latitude", e.target.value, { shouldValidate: true })
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.latitude && "Latitude is required."}
                                                </p>
                                            </div>
                                            <div className="w-1/2 px-3">
                                                <label
                                                    className="block uppercase tracking-wide text-xs font-bold mb-2"
                                                    htmlFor="grid-last-name"
                                                >
                                                    Longitude
                                                    <span className="pl-[4px] text-red">*</span>
                                                </label>
                                                <input
                                                    disabled={!isCustomAddress}
                                                    {...register("longitude", {
                                                        required: userDetail.longitude == '' || selectedCoordLong == '' ? true : false,
                                                    })}
                                                    className="appearance-none border-darkGray mb-1 block w-full border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                                    id="grid-last-name"
                                                    type="number"
                                                    step="any"
                                                    placeholder="Longitude"
                                                    value={isCustomAddress ? userDetail.longitude : selectedCoordLong}
                                                    onChange={(e) => {
                                                        setUserDetail((prevUserDetail) => ({
                                                            ...prevUserDetail,
                                                            longitude: e.target.value,
                                                        }))
                                                        setValue("longitude", e.target.value, { shouldValidate: true })
                                                    }
                                                    }
                                                />
                                                <p className="text-red text-sm">
                                                    {errors?.longitude && "Longitude is required."}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-end mx-3 px-3 mt-5">
                                            <button
                                                type="reset"
                                                className="px-4 py-2 bg-red text-white rounded-md text-sm "
                                                onClick={() => {
                                                    navigate('/users')
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="px-4 py-2 bg-green text-white rounded-md text-sm ml-2 w-[81px] h-[36px] inline-block"
                                                disabled={isLoading}
                                            >
                                                {isLoading &&
                                                    <ClipLoader color="#FFFFFF" size={20} />
                                                }
                                                {
                                                    !isLoading &&
                                                    (
                                                        <>
                                                            Submit
                                                        </>
                                                    )
                                                }
                                            </button>
                                        </div>
                                    </form >
                                </div >
                            </div >
                        </div >
                    )}
                </div >
            </div >
        </div >
    );
};

export default AddUser;

