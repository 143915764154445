
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";
import { showToastErrorMessage, showToastSuccessMessage } from "../../utils/utils";

interface IData {
    zipcode?: number;
    timezone?: string;
    state_code?: string;
    state?: string
}

interface IAddZipcode {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    zipcodeDetail: IData;
    selectedStateName: string
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const addZipcode: any = createAsyncThunk(
    "AddZipcodeSlice",
    async ({ zipcodeDetail, selectedStateName }: IAddZipcode) => {
        try {
            const response = await http.post(`${process.env.REACT_APP_BASE_URL}${apiconfig.zipcode.addZipcode}`, {
                zipcode: zipcodeDetail?.zipcode,
                timezone: zipcodeDetail?.timezone,
                state_code: zipcodeDetail?.state,
                state: selectedStateName

            }).then((data: any) => {
                showToastSuccessMessage(data?.data?.message)
            })
            return response
        } catch (error: any) {
            showToastErrorMessage(error?.response?.data?.message)
            throw error
        }
    }
);

export const AddZipcodeSlice = createSlice({
    name: "AddZipcodeSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addZipcode.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(addZipcode.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(addZipcode.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
    },
});

export const addZipcodeSliceReducer = AddZipcodeSlice.reducer;
