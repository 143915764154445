import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiconfig } from "../../api-config/api-config";
import { http } from "../../config/http";

interface IServiceDetails {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    islogedIn: boolean;
    id: string;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const servicedetails: any = createAsyncThunk(
    "ServiceDetailSlice",
    async ({ id }: IServiceDetails) => {
        const response = await http.get(`${process.env.REACT_APP_BASE_URL}${apiconfig?.services?.servicedetails}/${id}`)
        return response
    }
);

export const ServiceDetailSlice = createSlice({
    name: "ServiceDetailSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(servicedetails.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(servicedetails.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
        });
        builder.addCase(servicedetails.rejected, (state) => {
            state.isError = true;
        });
    },
});

export const serviceDetailSliceReducer = ServiceDetailSlice.reducer;
