import { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './reset.css';
import Templete from './template/Template';
import RegionContext from './utils/RegionContext';

function App() {
  const [contextRegionId, setContextRegionId] = useState('')

  return (
    <>
      <RegionContext.Provider value={{ contextRegionId, setContextRegionId }}>
        <BrowserRouter>
          <Templete />
        </BrowserRouter>
      </RegionContext.Provider>
    </>
  );
}

export default App;
