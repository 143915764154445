import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "../../components/breadcrumb";
import { ClipLoader } from "react-spinners";
import { viewestimaterequest } from "../../feature/EstimateRequest/ViewEstimateRequest-Slice";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import Title from "../../components/title/Title";
import moment from "moment";

interface IFormInputs {
    task_description?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    task_title?: string;
    created_at?: string;
    street?: string;
    street_line_2?: string
}

const ViewEstimateRequest = () => {
    const [estimaterequestiew, setEstimateRequestView] = useState<IFormInputs>({
        task_description: "",
        first_name: '',
        last_name: '',
        email: '',
        task_title: '',
        created_at: ''

    })

    const [addDetails, setAddDetails] = useState<IFormInputs>({
        street: '',
        street_line_2: ''

    })

    const dispatch = useDispatch()

    const { isLoading } = useSelector((state: any) => state?.viewEstimateRequest)

    // To get the data of the user from the api.
    useEffect(() => {
        const path = window.location.pathname
        const id = path?.split('/')[2]
        dispatch(viewestimaterequest(id)).then((data: any) => { setEstimateRequestView(data?.payload?.data?.data?.estimation); setAddDetails(data?.payload?.data?.data?.address) })
    }, [window.location.pathname]);


    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full">
                <Header />
                <div className="fluid p-4 custom-height-wrapper">
                    <Title title={"Estimate Request Details"} />
                    <Breadcrumb
                        paths={[('dashboard'), ('estimate-requests')]}
                        className="text-sm mb-3"
                        url={['/dashboard', '/estimate-requests']}
                    />
                    {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <ClipLoader color="#f27366" size={50} />
                        </div>
                    ) : (
                        <div className="w-full mb-2 lg:space-x-2 space-y-2 lg:space-y-0 lg:mb-4">
                            <div className="w-full p-4 rounded-lg bg-white border border-solid border-gray">
                                <div className="flex flex-col w-full">
                                    <div className="w-full max-w-full">
                                        <div className="px-3 flex items-center gap-x-10">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                Property Address
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{addDetails?.street ? `${addDetails?.street}, ${addDetails?.street_line_2}` : '-'}</h1>
                                        </div>
                                        <div className=" px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px] "
                                                htmlFor="grid-first-name"
                                            >
                                                Full name
                                            </label>
                                            <h1 className='appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white'>{estimaterequestiew?.first_name} {estimaterequestiew?.last_name}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                Email
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{estimaterequestiew?.email ? estimaterequestiew?.email : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                Task title
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">
                                                {estimaterequestiew?.task_title ? estimaterequestiew?.task_title : '-'}
                                            </h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-first-name"
                                            >
                                                Task description
                                            </label>
                                            <h1 className="appearance-none block w-full px-4 mb-1 leading-tight focus:outline-none focus:bg-white">{estimaterequestiew?.task_description ? estimaterequestiew?.task_description : '-'}</h1>
                                        </div>
                                        <div className="px-3 flex items-center gap-x-10 mt-3">
                                            <label
                                                className="block uppercase tracking-wide text-xs font-bold mb-2 w-[190px]"
                                                htmlFor="grid-last-name"
                                            >
                                                Created at
                                            </label>
                                            <h1 className="appearance-none border-darkGray mb-1 block w-full px-4 leading-tight focus:outline-none focus:bg-white">{estimaterequestiew?.created_at ? moment(estimaterequestiew?.created_at).format('YYYY-MM-DD HH:mm') : '-'}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewEstimateRequest;

